import { Navigate, useRoutes, Outlet, useNavigate } from "react-router-dom";
// layouts
import { useDispatch } from "react-redux";
import { useIdleTimer } from "react-idle-timer";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
// pages
import UserDetails from "./pages/Users/UserDetails";
import AddOrderToUser from "./pages/Users/AddOrderToUser";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import User from "./pages/Users/User";
import NotFound from "./pages/Page404/Page404";
import ServicesList from "./pages/Services/ServicesList";
import ServiceDetails from "./pages/Services/ServiceDetails";
import SamplesList from "./pages/Samples/SamplesList";
import SampleDetails from "./pages/Samples/SampleDetails";
import Cart from "./pages/Cart/Cart";
import RegisterUser from "./pages/RegisterUser/RegisterUser";
import Orders from "./pages/Orders/Orders";
import OrderDetails from "./pages/Orders/OrderDetails";
import RecoverPassword from "./pages/RecoverPassword/RecoverPassword";
import RequestsList from "./pages/Requests/RequestsList";
import RequestDetails from "./pages/Requests/RequestDetails";
import { useAppSelector } from "./app/hooks";
import { clearUser, selectUserType } from "./features/user/userSlice";
import ContactPage from "./pages/Contacts/Contacts";
import AccountActivation from "./pages/AccountActivation/AccountActivation";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AuthLayout from "./layouts/AuthLayout";
import ResendActivationAccountLink from "./pages/ResendActivationAccountLink/ResendActivationAccountLink";

// ----------------------------------------------------------------------

export default function Router() {
  const { REACT_APP_USER_INACTIVITY_LOGOUT } = process.env;
  const userType = useAppSelector(selectUserType);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn =
    sessionStorage.getItem("email") && sessionStorage.getItem("password");

  useIdleTimer({
    // eslint-disable-next-line no-eval
    timeout: eval(REACT_APP_USER_INACTIVITY_LOGOUT!),
    onIdle: () => {
      dispatch(clearUser());
      navigate("/auth/login", {
        replace: true,
      });
    },
    debounce: 500,
  });

  return useRoutes([
    {
      path: "/",
      element: isLoggedIn ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/auth/register" replace />
      ),
      children: [
        {
          index: true,
          // eslint-disable-next-line no-nested-ternary
          element: userType ? (
            ["medico", "infermiere"].includes(userType.toLowerCase()) ? (
              <Navigate to="/requests" replace />
            ) : (
              <Navigate to="/services" replace />
            )
          ) : (
            <Navigate to="/services" replace />
          ),
        },
        { path: "me", element: <UserDetails /> },
        {
          path: "services",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <ServicesList />,
            },
            {
              path: ":id",
              element: <ServiceDetails />,
            },
            {
              path: "new",
              element: <ServiceDetails />,
            },
          ],
        },
        {
          path: "samples",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <SamplesList />,
            },
            {
              path: ":id",
              element: <SampleDetails />,
            },
            {
              path: "new",
              element: <SampleDetails />,
            },
          ],
        },
        {
          path: "orders",
          element: <Outlet />,
          children: [
            { index: true, element: <Orders /> },
            { path: ":id", element: <OrderDetails /> },
            { path: "new", element: <OrderDetails /> },
          ],
        },
        {
          path: "cart",
          element: <Cart />,
        },
        {
          path: "users",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <User />,
            },
            {
              path: ":id",
              element: <UserDetails />,
            },
            {
              path: ":id/add-order",
              element: <AddOrderToUser />,
            },
            {
              path: "new",
              element: <RegisterUser />,
            },
          ],
        },
        {
          path: "requests",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <RequestsList />,
            },
            {
              path: ":id",
              element: <RequestDetails />,
            },
          ],
        },
        { path: "404", element: <NotFound /> },
      ],
    },
    {
      path: "/auth",
      element: !isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/" replace />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "recover-password", element: <RecoverPassword /> },
        {
          path: "resend-account-activation-link",
          element: <ResendActivationAccountLink />,
        },
        { path: "account-activation", element: <AccountActivation /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/contacts",
      element: isLoggedIn ? (
        <DashboardLayout />
      ) : (
        <LogoOnlyLayout>
          <AuthLayout />
        </LogoOnlyLayout>
      ),
      children: [
        {
          index: true,
          element: <ContactPage />,
        },
      ],
    },
    {
      path: "/privacy-policy",
      element: isLoggedIn ? (
        <DashboardLayout />
      ) : (
        <LogoOnlyLayout>
          <AuthLayout />
        </LogoOnlyLayout>
      ),
      children: [
        {
          index: true,
          element: <PrivacyPolicy />,
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
