import React from "react";
import { useAsync, useDebounce } from "react-use";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import type {
  Service,
  GetServicesResponse,
} from "../api/assistenza-domiciliare";

import { ServicesService } from "../api/assistenza-domiciliare";

interface ISamplesAutocompleteProperties {
  label: string;
  onServiceSelected: (service?: Service | null) => void;
  value?: Service | null;
  disabled: boolean;
}

const SamplesAutocomplete: React.FC<ISamplesAutocompleteProperties> = ({
  label,
  onServiceSelected: onDoctorSelected,
  value: initialValue,
  disabled,
}) => {
  const [value, setValue] = React.useState<Service | null | undefined>(
    initialValue
  );
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [services, setServices] = React.useState<GetServicesResponse>();
  const [loadingServices, setLoadingServices] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value: currentSearchTermValue },
  }) => {
    setSearchTerm(currentSearchTermValue);
  };

  useAsync(async () => {
    try {
      setLoadingServices(true);
      setServices(
        await ServicesService.getServices({
          advancedDescription: debouncedSearchTerm,
          description: debouncedSearchTerm,
        })
      );
    } finally {
      setLoadingServices(false);
    }
  }, [debouncedSearchTerm]);

  return (
    <Autocomplete
      autoComplete
      open={open}
      onOpen={async () => {
        setOpen(true);
        setServices(
          await ServicesService.getServices1({
            description: debouncedSearchTerm,
            advancedDescription: debouncedSearchTerm,
          })
        );
      }}
      onClose={() => {
        setOpen(false);
      }}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isOptionEqualToValue={(option, value) =>
        option.description === value.description
      }
      getOptionLabel={(option) =>
        option.advancedDescription
          ? option.advancedDescription
          : option.description
      }
      options={services?.data || []}
      loading={loadingServices}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          label={label}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {loadingServices ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
          value={searchTerm}
          onChange={handleSearchTerm}
        />
      )}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onChange={(_, value) => {
        setValue(value);
        onDoctorSelected(value);
      }}
      value={value}
      filterOptions={(x) => x}
      disabled={disabled}
      sx={{
        display: "flex",
        width: "100%",
      }}
    />
  );
};

export default SamplesAutocomplete;
