/* eslint-disable sonarjs/no-nested-switch */
import React from "react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";

import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../app/hooks";

// ----------------------------------------------------------------------

const ListItemStyle = styled((properties) => (
  <ListItemButton disableGutters {...properties} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
//   active: PropTypes.func,
// };

const NavItem: React.FC<{
  item: {
    title: string;
    path: string;
    icon?: React.ReactNode;
    info?: string;
    children?: any;
  };
  active: (...arguments_: any) => boolean;
}> = ({ item, active }) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = React.useState<boolean>(isActiveRoot);

  const handleOpen = () => {
    setOpen((previous) => !previous);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          // @ts-ignore
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info}
          <Box
            component={
              open ? ArrowForwardOutlinedIcon : ArrowDownwardOutlinedIcon
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child: any) => {
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={child.title}
                  // @ts-ignore
                  component={RouterLink}
                  to={child.path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={child.title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      // @ts-ignore
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info}
    </ListItemStyle>
  );
};

const NavSection: React.FC<{
  navConfig: {
    title: string;
    path: string;
    icon?: React.ReactNode;
    info?: string;
    children?: any;
  }[];
}> = ({ navConfig, ...other }) => {
  const { pathname } = useLocation();
  const user = useAppSelector(selectUser);
  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig
          .filter(({ title }) => {
            const userType = user?.type.description.toLowerCase();

            switch (userType) {
              case "paziente": {
                switch (title) {
                  case "utenti":
                  case "richieste":
                    return false;

                  default:
                    return true;
                }
              }
              case "amministratore":
              case "amministrativo": {
                switch (title) {
                  case "carrello":
                  case "richieste":
                    return false;

                  default:
                    return true;
                }
              }
              case "infermiere":
              case "medico": {
                switch (title) {
                  case "anagrafica":
                  case "richieste":
                    return true;

                  default:
                    return false;
                }
              }

              default:
                return true;
            }
          })
          .map((item) => (
            <NavItem key={item.title} item={item} active={match} />
          ))}
      </List>
    </Box>
  );
};

export default NavSection;
