import React from "react";
import { useAsync, useDebounce } from "react-use";
// material
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Stack,
  TextField,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridColDef, DataGridProps, itIT } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUserType } from "../../features/user/userSlice";
import {
  addService,
  removeService,
  selectServicesInCart,
  selectCartLength,
  clearCart,
} from "../../features/cart/cartSlice";

// components
import Page from "../../components/Page";
import GenericError from "../../components/GenericError";
import PageLoader from "../../components/PageLoader";

import removeNullUndefined from "../../utils/removeNullOrUndefined";

import {
  UsersService,
  ServicesService,
  OrdersService,
} from "../../api/assistenza-domiciliare";

import type {
  GetUserResponse,
  GetServicesResponse,
  PostOrderResponse,
} from "../../api/assistenza-domiciliare";
import { NoRowsOverlay } from "../../components/datagrid/NoRowsOverlay";
import { Footer } from "../../components/datagrid";
import { SearchInput } from "../../components/SearchInput";

// ----------------------------------------------------------------------

const AddOrderToUser: React.FC = () => {
  const { id: userId } = useParams();
  const userType = useAppSelector(selectUserType);
  const servicesInCart = useAppSelector(selectServicesInCart);
  const numberOfServicesInCart = useAppSelector(selectCartLength);

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");
  const [, cancelSearchtermDebouncing] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);
  const [isSuccessSnackbarShown, setSuccessSnackbarVisibility] =
    React.useState<boolean>(false);

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [services, setServices] = React.useState<GetServicesResponse>();
  const [loadingServices, setLoadingServices] = React.useState<boolean>(false);
  const [errorFetchingServices, setErrorFetchingServices] =
    React.useState<boolean>(false);

  const [user, setUser] = React.useState<GetUserResponse>();
  const [loadingUser, setLoadingUser] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [createdOrder, setCreatedOrder] = React.useState<PostOrderResponse>();
  const [isCreatingOrder, setIsCreatingOrder] = React.useState<boolean>(false);
  const [errorCreatingOrder, setErrorCreatingOrder] =
    React.useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: "Cart",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 165,
      renderCell: ({ row }) => {
        const serviceQtyAdded =
          servicesInCart.find(({ id }) => id === row.id)?.quantity || 0;
        const numberOfMedicalServicesInCart = servicesInCart.filter(
          ({ type }) => type?.id === 3
        ).length;

        return (
          <>
            <Tooltip
              enterDelay={750}
              title={"Rimuovi questa prestazione dal carrello"}
            >
              <span>
                <IconButton
                  color="primary"
                  aria-label="remove from shopping cart"
                  onClick={() => {
                    dispatch(removeService(row));
                  }}
                  disabled={serviceQtyAdded === 0}
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title={
                serviceQtyAdded === 0
                  ? "Non hai aggiunto questa prestazione al carrello"
                  : `Hai aggiunto questa prestazione ${serviceQtyAdded} volte al carrello`
              }
            >
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                    style: {
                      textAlign: "center",
                      width: 25,
                      paddingRight: 5,
                      paddingLeft: 5,
                    },
                  }}
                  size="small"
                  value={serviceQtyAdded}
                  disabled
                />
              </Box>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title={"Aggiungi questa prestazione al carrello"}
            >
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => {
                  dispatch(addService(row));
                }}
                disabled={
                  row.type.id === 3 && numberOfMedicalServicesInCart >= 1
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
      field: "name",
      headerName: "Nome",
      width: 110,
      sortable: false,
      hide: userType === "paziente",
    },
    {
      field: "description",
      headerName: "Descrizione",
      flex: 1,
      editable: false,
      filterable: true,
      sortable: false,
    },
    {
      field: "type",
      headerName: "Tipologia",
      type: "text",
      width: 150,
      sortable: false,
      valueGetter: (parameters) => parameters?.value?.description,
    },
    {
      field: "price",
      headerName: "Prezzo",
      type: "number",
      width: 90,
      sortable: false,
      valueGetter: (parameters) =>
        new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(parameters.value),
    },
  ];

  useAsync(async () => {
    try {
      setError(false);
      setLoadingUser(true);

      setUser(
        await UsersService.getUser({
          id: +userId!,
        })
      );
    } catch {
      setError(true);
    } finally {
      setLoadingUser(false);
    }
  }, [userId]);

  useAsync(async () => {
    try {
      setErrorFetchingServices(false);
      setLoadingServices(true);

      setServices(
        await ServicesService.getServicesWithSample({
          skip: page * pageSize,
          take: pageSize,
          description: debouncedSearchTerm,
          advancedDescription: debouncedSearchTerm,
          name: debouncedSearchTerm,
        })
      );
    } catch {
      setErrorFetchingServices(true);
    } finally {
      setLoadingServices(false);
    }
  }, [page, pageSize, debouncedSearchTerm]);

  React.useEffect(() => {
    if (createdOrder) {
      dispatch(clearCart());
      setSuccessSnackbarVisibility(true);
    }
  }, [createdOrder, dispatch]);

  React.useEffect(() => {
    if (errorCreatingOrder) {
      setErrorSnackbarVisibility(true);
    }
  }, [errorCreatingOrder]);

  const handleRowDoubleClick: DataGridProps["onRowDoubleClick"] = ({ row }) => {
    if (userType === "amministratore") {
      navigate(`${row.id}`);
    }
  };

  const paymentButtonHandler: React.MouseEventHandler<HTMLButtonElement> =
    async () => {
      if (user?.data) {
        try {
          setErrorCreatingOrder(false);
          setIsCreatingOrder(true);

          setCreatedOrder(
            await OrdersService.createOrder({
              requestBody: {
                services: removeNullUndefined(servicesInCart),
                user: user.data,
              },
            })
          );
        } catch {
          setErrorCreatingOrder(true);
        } finally {
          setIsCreatingOrder(false);
        }
      }
    };

  const handleSnackbarErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSnackbarSuccessClose = () => {
    setSuccessSnackbarVisibility((previous) => !previous);
  };

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchTerm(value);
  };

  const handleSearchTermClearing: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    cancelSearchtermDebouncing();
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  if (loadingUser) {
    return <PageLoader />;
  }

  if (errorFetchingServices || error) {
    return <GenericError />;
  }

  return (
    <Page
      title={`Aggiungi ordine per l'utente ${user?.data.firstName} ${user?.data.lastName}`}
      showBackButton
    >
      <Container>
        <Box sx={{ pb: 5 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <SearchInput
              value={searchTerm}
              onChange={handleSearchTerm}
              placeholder="Ricerca per nome o descrizione..."
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchIcon />
                  </Box>
                </InputAdornment>
              }
              endAdornment={
                searchTerm.length > 0 ? (
                  <InputAdornment position="end">
                    <Tooltip enterDelay={500} title="Resetta campo di ricerca">
                      <IconButton
                        aria-label="Resetta campo di ricerca"
                        onClick={handleSearchTermClearing}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null
              }
            />

            {(userType === "amministratore" ||
              userType === "amministrativo") && (
              <LoadingButton
                loading={isCreatingOrder}
                variant="contained"
                startIcon={<AddIcon />}
                onClick={paymentButtonHandler}
                disabled={numberOfServicesInCart === 0}
                sx={{
                  ml: "auto",
                }}
              >
                Aggiungi ordine per l'utente
              </LoadingButton>
            )}
          </Stack>

          <DataGrid
            rows={services?.data || []}
            loading={loadingServices}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={services?.total}
            density="comfortable"
            editMode="row"
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            onRowDoubleClick={handleRowDoubleClick}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            components={{
              Footer,
              NoRowsOverlay,
            }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Container>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSnackbarSuccessClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSnackbarSuccessClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Ordine aggiunto con successo
        </Alert>
      </Snackbar>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSnackbarErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSnackbarErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Non è stato possibile aggiungere l'ordine per l'utente{" "}
          {`${user?.data.firstName} ${user?.data.lastName}`}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default AddOrderToUser;
