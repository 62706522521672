import React from "react";
import { useAsync, useDebounce } from "react-use";
// material
import {
  Box,
  Container,
  Chip,
  ChipProps,
  Tooltip,
  IconButton,
  NativeSelect,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  getGridStringOperators,
  GridFilterInputValueProps,
  itIT,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { saveAs } from "file-saver";
import { OrdersService } from "../../api/assistenza-domiciliare";

import type {
  GetOrdersResponse,
  GetOrderStatusesResponse,
} from "../../api/assistenza-domiciliare";

// components
import Page from "../../components/Page";

import { NoRowsOverlay, Footer } from "../../components/datagrid";
import { SearchInput } from "../../components/SearchInput";
import { selectUserType } from "../../features/user/userSlice";
import { useAppSelector } from "../../app/hooks";
import { b64toBlob } from "../../utils/b64toBlob";

// ----------------------------------------------------------------------

function StatusSelectFilter({
  item,
  applyValue,
  // @ts-ignore
  focusElementRef,
}: GridFilterInputValueProps) {
  const [, setIsLoadingRequestsStatus] = React.useState<boolean>(false);
  const [requestStatuses, setRequestStatuses] =
    React.useState<GetOrderStatusesResponse>();

  const ratingReference: React.Ref<any> = React.useRef(null);

  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      ratingReference.current
        .querySelector(`input[value="${item.value || ""}"]`)
        .focus();
    },
  }));

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  useAsync(async () => {
    try {
      setIsLoadingRequestsStatus(true);

      setRequestStatuses(await OrdersService.getOrdersStatuses({}));
    } finally {
      setIsLoadingRequestsStatus(false);
    }
  }, []);

  return (
    <>
      <InputLabel variant="standard" htmlFor="request-status-select">
        Stato Richiesta
      </InputLabel>

      <NativeSelect
        defaultValue={""}
        inputProps={{
          name: "request-status",
          id: "request-status-select",
        }}
        onChange={handleFilterChange}
        ref={focusElementRef}
      >
        <option value="" disabled>
          Seleziona stato richiesta
        </option>
        {requestStatuses?.data.map(({ status }) => (
          <option value={status}>{status}</option>
        ))}
      </NativeSelect>
    </>
  );
}

const Orders: React.FC = () => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");

  const [, cancelSearchtermDebouncing] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const [isLoadingOrders, setIsLoadingOrders] = React.useState<boolean>(false);
  const [orders, setOrders] = React.useState<GetOrdersResponse>();

  const [isExportingOrders, setIsExportingOrders] = React.useState(false);
  const [exportedOrders, setExportedOrders] =
    React.useState<GetOrdersResponse>();

  const navigate = useNavigate();
  const userType = useAppSelector(selectUserType);

  const columns: GridColDef[] = [
    {
      field: "Actions",
      headerName: "",
      align: "center",
      disableColumnMenu: true,
      disableExport: true,
      width: 90,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [anchorElement, setAnchorElement] = React.useState(null);
        const open = Boolean(anchorElement);
        const handleClick = (event) => {
          setAnchorElement(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorElement(null);
        };

        return (
          <>
            {userType === "paziente" && (
              <Tooltip enterDelay={750} title={"Visualizza questo ordine"}>
                <IconButton
                  color="primary"
                  aria-label="visualizza ordine"
                  onClick={() => navigate(`/orders/${row.id}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}

            {(userType === "amministratore" ||
              userType === "amministrativo") && (
              <Tooltip enterDelay={750} title={"Modifica questo ordine"}>
                <IconButton
                  color="primary"
                  aria-label="modifica questo ordine"
                  onClick={() => navigate(`/orders/${row.id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip enterDelay={750} title={"Altre azioni disponibili"}>
              <IconButton
                aria-label="more"
                id={`action-menu-button-${row.id}`}
                aria-controls={open ? `action-menu-${row.id}` : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            </Tooltip>

            <Menu
              id={`action-menu-${row.id}`}
              MenuListProps={{
                "aria-labelledby": `action-menu-button-${row.id}`,
              }}
              anchorEl={anchorElement}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={async () => {
                  const blob = await b64toBlob(
                    row.orderBill,
                    "application/pdf"
                  );

                  saveAs(
                    blob,
                    `fattura-ordine-#${row.id.toString().padStart(7, "0")}.pdf`
                  );

                  handleClose();
                }}
                disabled={row.orderBill === null}
              >
                <ListItemIcon>
                  <ReceiptIcon color="primary" />
                </ListItemIcon>
                <ListItemText>Scarica fattura ordine</ListItemText>
              </MenuItem>

              {row.orderRefundBill && (
                <MenuItem
                  onClick={async () => {
                    const blob = await b64toBlob(
                      row.orderRefundBill,
                      "application/pdf"
                    );

                    saveAs(
                      blob,
                      `nota-credito-ordine-#${row.id
                        .toString()
                        .padStart(7, "0")}.pdf`
                    );

                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <ReceiptLongIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText>Scarica nota credito ordine</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "# Ordine",
      sortable: true,
      filterable: true,
      width: 95,
      renderCell: ({ value }) => (
        <Chip
          label={`#${value.toString().padStart(7, "0")}`}
          color="secondary"
          variant="outlined"
          sx={{
            maxWidth: 95,
          }}
        />
      ),
    },
    {
      field: "user",
      headerName: "Paziente",
      sortable: true,
      filterable: true,
      width: 165,
      valueGetter: ({ value }) => [value.firstName, value.lastName].join(" "),
      renderCell: ({ value }) => (
        <Chip
          label={value}
          color="info"
          variant="outlined"
          sx={{
            maxWidth: 165,
          }}
        />
      ),
    },
    {
      field: "prestazioniRichieste",
      headerName: "Prestazioni Richieste",
      align: "left",
      width: 570,
      sortable: true,
      filterable: true,
      renderCell: ({ row }) =>
        row.requests?.map(({ service }, index) => (
          <Chip
            key={`service-${service.id}-${index}`}
            label={service.advancedDescription || service.description}
            color="info"
            variant="outlined"
            sx={{
              maxWidth: 450,
            }}
          />
        )),
      valueGetter: ({ row }) =>
        row.requests
          .map(
            ({ service }) => service.advancedDescription || service.description
          )
          .join(" "),
      filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === "contains")
        .map((operator) => ({
          ...operator,
        })),
    },
    {
      field: "status",
      headerName: "Stato",
      align: "left",
      minWidth: 180,
      sortable: true,
      filterable: true,
      renderCell: ({ row }) => {
        if (row.status) {
          const { id, status } = row.status;

          const getChipColor = (rowStatus: number): ChipProps["color"] => {
            switch (rowStatus) {
              case 1:
              case 9:
              case 4:
                return "warning";

              case 2:
                return "success";

              case 3:
              case 7:
              case 5:
                return "error";

              default:
                return "info";
            }
          };

          return (
            <Chip
              label={status}
              color={getChipColor(id)}
              variant="outlined"
              sx={{
                maxWidth: 250,
              }}
            />
          );
        }

        return null;
      },
      valueGetter: ({ row }) => row.status.status,
      filterOperators: getGridStringOperators()
        .filter((operator) => operator.value === "equals")
        .map((operator) => ({
          ...operator,
          InputComponent: StatusSelectFilter,
        })),
    },
    {
      field: "createdAt",
      headerName: "Data",
      type: "date",
      width: 100,
      sortable: true,
      renderCell: ({ row }) => {
        const parsedDate = new Intl.DateTimeFormat("it").format(
          new Date(row.createdAt)
        );

        return (
          <Chip
            label={parsedDate}
            variant="outlined"
            sx={{
              maxWidth: 100,
            }}
          />
        );
      },
      valueGetter: (parameters: any) => {
        if (!Number.isNaN(Date.parse(parameters.value))) {
          return new Intl.DateTimeFormat("it").format(
            new Date(parameters.value)
          );
        }

        return "";
      },
      sortComparator: (v1, v2) =>
        new Date(v1 as string).getTime() - new Date(v2 as string).getTime(),
    },
    {
      field: "totalPrice",
      headerName: "Prezzo",
      type: "number",
      sortable: true,
      filterable: true,
      width: 90,
      renderCell: ({ row }) => {
        const price = new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(row?.totalPrice);

        return (
          <Chip
            label={price}
            variant="outlined"
            sx={{
              maxWidth: 90,
            }}
          />
        );
      },
    },
  ];

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchTerm(value);
  };

  const handleSearchTermClearing: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    cancelSearchtermDebouncing();
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  const exportOrders = async () => {
    try {
      setIsExportingOrders(true);

      setExportedOrders(
        await OrdersService.getOrders({
          skip: page * pageSize,
          take: pageSize,
          download: "csv",
        })
      );
    } finally {
      setIsExportingOrders(false);
    }
  };

  const fetchOrders = async () => {
    try {
      setIsLoadingOrders(true);

      setOrders(
        await OrdersService.getOrders({
          skip: page * pageSize,
          take: pageSize,
          id: debouncedSearchTerm,
        })
      );
    } finally {
      setIsLoadingOrders(false);
    }
  };

  useAsync(fetchOrders, [page, pageSize, debouncedSearchTerm]);

  React.useEffect(() => {
    if (exportedOrders) {
      const blob = new Blob([exportedOrders as unknown as string], {
        type: "text/csv; encoding=UTF-8",
      });
      saveAs(blob, "ordini.csv");
    }
  }, [exportedOrders]);

  return (
    <Page title="Ordini">
      <Container>
        <Box pb={5}>
          <Box mb={5}>
            <SearchInput
              value={searchTerm}
              onChange={handleSearchTerm}
              placeholder="Ricerca per numero ordine..."
              startAdornment={
                <InputAdornment position="start">
                  <Box sx={{ color: "text.disabled" }}>
                    <SearchIcon />
                  </Box>
                </InputAdornment>
              }
              endAdornment={
                searchTerm.length > 0 ? (
                  <InputAdornment position="end">
                    <Tooltip enterDelay={500} title="Resetta campo di ricerca">
                      <IconButton
                        aria-label="Resetta campo di ricerca"
                        onClick={handleSearchTermClearing}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ) : null
              }
            />
          </Box>

          <DataGrid
            rows={orders?.data || []}
            loading={isLoadingOrders}
            columns={columns}
            pageSize={pageSize}
            rowCount={orders?.total}
            rowsPerPageOptions={[10, 20, 30]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            density="comfortable"
            editMode="row"
            autoHeight
            // disableColumnSelector
            // disableDensitySelector
            disableSelectionOnClick
            onRowDoubleClick={({ row }) => navigate(`/orders/${row.id}`)}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            components={{
              Footer,
              NoRowsOverlay,
            }}
            componentsProps={{
              footer: {
                canExportData: true,
                disableExportButton:
                  !orders?.data || isLoadingOrders || isExportingOrders,
                loadingServices: isLoadingOrders,
                exportFn: exportOrders,
              },
            }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default Orders;
