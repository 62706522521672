// ----------------------------------------------------------------------

const Autocomplete = () => ({
  MuiSpeedDial: {
    styleOverrides: {
      root: {
        zIndex: 1200,
      },
    },
  },
});

export default Autocomplete;
