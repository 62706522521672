import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
// components
//
import { MHidden } from "../components/@material_extend";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "flex-end",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

// ----------------------------------------------------------------------

const AuthLayout: React.FC = ({ children }) => (
  <HeaderStyle>
    <MHidden width="smDown">
      <Typography
        variant="body2"
        sx={{
          mt: { md: -2 },
        }}
      >
        {children}
      </Typography>
    </MHidden>
  </HeaderStyle>
);

export default AuthLayout;
