import React from "react";
import { useAsync, useDebounce } from "react-use";
// material
import {
  Box,
  Container,
  IconButton,
  Tooltip,
  Stack,
  Button,
  TextField,
  InputAdornment,
  Chip,
  Typography,
  Alert,
  Badge,
} from "@mui/material";
import { DataGrid, GridColDef, DataGridProps, itIT } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUserType } from "../../features/user/userSlice";
import {
  addService,
  removeService,
  selectServicesInCart,
} from "../../features/cart/cartSlice";

// components
import Page from "../../components/Page";
import GenericError from "../../components/GenericError";
import { ServicesService } from "../../api/assistenza-domiciliare";

import type { GetServicesResponse } from "../../api/assistenza-domiciliare";

import { NoRowsOverlay, Footer } from "../../components/datagrid";
import { SearchInput } from "../../components/SearchInput";

// ----------------------------------------------------------------------

const ServicesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userType = useAppSelector(selectUserType);

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");

  const [, cancelSearchtermDebouncing] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [pageSize, setPageSize] = React.useState<number>(
    ["amministratore", "amministrativo"].includes(userType!) ? 100 : 10
  );

  const [page, setPage] = React.useState<number>(0);

  const servicesInCart = useAppSelector(selectServicesInCart);

  const modifyService = (id: number) => {
    if (userType === "amministratore") {
      navigate(`/services/${id}`);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "Cart",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 165,
      renderCell: ({ row }) => {
        const numberOfMedicalServicesInCart = servicesInCart.filter(
          ({ type }) => type?.id === 3
        ).length;
        const serviceQtyAdded =
          servicesInCart.find(({ id }) => id === row.id)?.quantity || 0;

        return (
          <>
            <Tooltip
              enterDelay={750}
              title={"Rimuovi questa prestazione dal carrello"}
            >
              <span>
                <IconButton
                  color="primary"
                  aria-label="remove from shopping cart"
                  onClick={() => {
                    dispatch(removeService(row));
                  }}
                  disabled={serviceQtyAdded === 0}
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title={
                serviceQtyAdded === 0
                  ? "Non hai aggiunto questa prestazione al carrello"
                  : `Hai aggiunto questa prestazione ${serviceQtyAdded} volte al carrello`
              }
            >
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                    style: {
                      textAlign: "center",
                      width: 25,
                      paddingRight: 5,
                      paddingLeft: 5,
                    },
                  }}
                  size="small"
                  value={serviceQtyAdded}
                  disabled
                />
              </Box>
            </Tooltip>
            <Tooltip
              enterDelay={750}
              title={"Aggiungi questa prestazione al carrello"}
            >
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => {
                  dispatch(addService(row));
                }}
                disabled={
                  row.type.id === 3 && numberOfMedicalServicesInCart >= 1
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      hide: userType === "amministratore" || userType === "amministrativo",
    },
    {
      field: "Edit",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 25,
      renderCell: ({ row }) => (
        <Tooltip enterDelay={750} title={"Modifica questa prestazione"}>
          <IconButton
            color="primary"
            aria-label="modifica questa prestazione"
            onClick={() => modifyService(row.id)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
      hide: userType !== "amministratore",
    },
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
      field: "name",
      headerName: "Nome",
      width: 125,
      sortable: false,
      hide: userType === "paziente",
      renderCell: ({ row }) => (
        <Badge color="warning" variant="dot" invisible={!row.endValidity}>
          <Chip label={row.name} variant="outlined" sx={{ maxWidth: 115 }} />
        </Badge>
      ),
    },
    {
      field: "description",
      headerName: "Descrizione",
      minWidth: 700,
      // flex: 1,
      editable: false,
      filterable: true,
      sortable: false,
      renderCell: ({ row }) => (
        <Chip
          label={row.advancedDescription || row.description}
          color={row.endValidity ? "default" : "secondary"}
          variant="outlined"
        />
      ),
      valueGetter: ({ row }) => row.advancedDescription || row.description,
    },
    {
      field: "type",
      headerName: "Tipologia",
      type: "text",
      width: 135,
      sortable: false,
      valueGetter: (parameters) => parameters?.value?.description,
      renderCell: ({ row }) => (
        <Tooltip
          enterDelay={750}
          title={
            row.type.description === "Oss" ? "Operatore Socio Sanitario" : ""
          }
          disableFocusListener={row.type.description !== "Oss"}
          disableHoverListener={row.type.description !== "Oss"}
          disableInteractive={row.type.description !== "Oss"}
          disableTouchListener={row.type.description !== "Oss"}
        >
          <Chip
            label={row.type.description}
            color={row.endValidity ? "default" : "secondary"}
            variant="outlined"
            sx={{
              maxWidth: 135,
            }}
          />
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "Prezzo",
      type: "number",
      width: 100,
      sortable: false,
      renderCell: ({ row }) => {
        const price = new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(row.price);

        return (
          <Chip
            label={price}
            variant="outlined"
            sx={{
              maxWidth: 100,
            }}
          />
        );
      },
      valueGetter: (parameters) =>
        new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(parameters.value),
    },
  ];

  const handleRowDoubleClick: DataGridProps["onRowDoubleClick"] = ({ row }) =>
    modifyService(row.id);

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchTerm(value);
  };

  const handleSearchTermClearing: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    cancelSearchtermDebouncing();
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  const {
    loading: loadingServices,
    value: services,
    error,
  } = useAsync<() => Promise<GetServicesResponse>>(
    async () =>
      ServicesService.getServices({
        skip: page * pageSize,
        take: pageSize,
        description: debouncedSearchTerm || undefined,
        advancedDescription: debouncedSearchTerm || undefined,
        name: debouncedSearchTerm || undefined,
      }),
    [page, pageSize, debouncedSearchTerm]
  );

  if (error) {
    return <GenericError />;
  }

  return (
    <Page title="Elenco Prestazioni">
      <Container>
        <Box sx={{ pb: 5 }}>
          <Stack direction="column" gap={2}>
            <Alert severity="info">
              <Typography>
                Cerca le prestazioni di cui hai bisogno nella barra di ricerca
                qui sotto o scorrendo nella lista.
              </Typography>

              <Typography>
                Successivamente verrai ricontattato da un nostro operatore per
                definire il giorno dell’appuntamento e i dettagli della
                prestazione verrai abilitato al pagamento nel carrello.
              </Typography>
            </Alert>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <SearchInput
                value={searchTerm}
                onChange={handleSearchTerm}
                placeholder="Ricerca per nome o descrizione..."
                startAdornment={
                  <InputAdornment position="start">
                    <Box sx={{ color: "text.disabled" }}>
                      <SearchIcon />
                    </Box>
                  </InputAdornment>
                }
                endAdornment={
                  searchTerm.length > 0 ? (
                    <InputAdornment position="end">
                      <Tooltip
                        enterDelay={500}
                        title="Resetta campo di ricerca"
                      >
                        <IconButton
                          aria-label="Resetta campo di ricerca"
                          onClick={handleSearchTermClearing}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ) : null
                }
              />

              {userType === "amministratore" && (
                <Button
                  variant="contained"
                  component={RouterLink}
                  to="/services/new"
                  startIcon={<AddIcon />}
                  sx={{
                    ml: "auto",
                  }}
                >
                  Crea nuova prestazione
                </Button>
              )}
            </Stack>
          </Stack>

          <DataGrid
            rows={services?.data || []}
            loading={loadingServices}
            columns={columns}
            pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 60, 80, 100]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowCount={services?.total}
            density="comfortable"
            editMode="row"
            autoHeight
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableDensitySelector
            disableSelectionOnClick
            onRowDoubleClick={handleRowDoubleClick}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            components={{
              Footer,
              NoRowsOverlay,
            }}
            componentsProps={{
              footer: {
                canExportData: false,
                disableExportButton: !services?.data || loadingServices,
                loadingServices,
              },
            }}
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            getRowClassName={({ row }) =>
              clsx({ "disabled-service": row.endValidity })
            }
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ServicesList;
