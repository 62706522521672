import * as Yup from "yup";
import React from "react";
import { useAsync, useToggle } from "react-use";
import { useFormik, Form, FormikProvider } from "formik";
import countries from "i18n-iso-countries";

// material
import {
  Alert,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LoadingButton } from "@mui/lab";

import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useLocation } from "react-router-dom";
import CodiceFiscale from "codice-fiscale-js";
import MuiPhoneNumber from "material-ui-phone-number";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectUser, setUser } from "../../features/user/userSlice";
import {
  $PostRegisterBody,
  PatchUserBody,
  User,
  UsersService,
  UserType,
} from "../../api/assistenza-domiciliare";
import GenericError from "../GenericError";
import PageLoader from "../PageLoader";

import type {
  GetUserTypeResponse,
  PatchUserResponse,
} from "../../api/assistenza-domiciliare";
import CountriesAutocomplete from "../CountriesAutocomplete";

// ----------------------------------------------------------------------

interface IUserDetailsFormProperties {
  initialValues?: User;
}

const UserDetailsForm: React.FC<IUserDetailsFormProperties> = ({
  initialValues,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { pathname } = useLocation();
  const [isSuccessSnackbarShown, setSuccesSnackbarVisibility] =
    React.useState<boolean>(false);
  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);
  const currentUser = useAppSelector(selectUser) || {
    id: undefined,
    email: "",
    codfis: "",
    address: "",
    cap: "",
    city: "",
    province: "",
    firstName: "",
    lastName: "",
    type: {},
    cityOfBirth: "",
    dateOfBirth: "",
    mobile: "",
    nationality: "",
    provinceOfBirth: "",
    sex: "",
    telephone: "",
    acceptedFundRaisingAgreement: 0,
    acceptedMarketingAgreement: 0,
    activated: 0,
  };

  const dispatch = useAppDispatch();

  const [userTypes, setUserTypes] = React.useState<GetUserTypeResponse>();

  const [isLoadingUserTypes, setIsLoadingUserTypes] =
    React.useState<boolean>(false);

  const [errorLoadingUserTypes, setErrorLoadingUserTypes] =
    React.useState<boolean>(false);

  const [isFundRaisingAgreementAccepted, toggleFundRaisingAgreementAcceptance] =
    useToggle(Boolean(currentUser?.acceptedFundRaisingAgreement));

  const [isMarketAgreementAccepted, toggleMarketAgreementAcceptance] =
    useToggle(Boolean(currentUser?.acceptedMarketingAgreement));

  const [isActivated, toggleIsActivated] = useToggle(
    initialValues
      ? Boolean(initialValues.activated)
      : Boolean(currentUser.activated)
  );

  const [updatedUser, setUpdatedUser] = React.useState<PatchUserResponse>();

  const PatchUserBodySchema: Yup.SchemaOf<
    Omit<PatchUserBody, "telephone" | "password" | "activated">
  > = Yup.object().shape({
    firstName: Yup.string()
      .min($PostRegisterBody.properties.firstName.minLength)
      .max($PostRegisterBody.properties.firstName.maxLength)
      .required("Il nome dell'utente è richiesto"),
    lastName: Yup.string().required("Il cognome dell'utente è richiesto"),
    email: Yup.string()
      .email("L'indirizzo email inserito non è valido")
      .max($PostRegisterBody.properties.email.maxLength)
      .required("È necessario inserire l'indirizzo email"),
    codfis: Yup.string()
      .when("nationality", {
        is: "Italia",
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .min(16, "Il Codice Fiscale deve contenere almeno 16 caratteri")
            .max(16, "Il Codice Fiscale deve contenere al più 16 caratteri")
            .test("invalid-cf", "Il Codice Fiscale non è valido", (value) => {
              if (!value) {
                return false;
              }

              return CodiceFiscale.check(value);
            }),
      })
      .required("Il Codice Fiscale è richiesto"),
    address: Yup.string()
      .max($PostRegisterBody.properties.address.maxLength)
      .required("L'indirizzo dell'utente è richiesto"),
    city: Yup.string()
      .max($PostRegisterBody.properties.city.maxLength)
      // eslint-disable-next-line sonarjs/no-duplicate-string
      .required("Campo richiesto"),
    province: Yup.string()
      .min($PostRegisterBody.properties.province.minLength)
      .max($PostRegisterBody.properties.province.maxLength)
      .required("Campo richiesto"),
    cap: Yup.string()
      .max($PostRegisterBody.properties.cap.maxLength)
      .required("Campo richiesto"),
    type: Yup.object()
      .shape({
        id: Yup.number().required(),
        description: Yup.string().required(),
      })
      .required("Campo richiesto"),
    cityOfBirth: Yup.string()
      // eslint-disable-next-line sonarjs/no-duplicate-string
      .required("Campo richiesto"),
    provinceOfBirth: Yup.string()
      .min($PostRegisterBody.properties.provinceOfBirth.minLength)
      .max($PostRegisterBody.properties.provinceOfBirth.maxLength)
      .required("Campo richiesto"),
    dateOfBirth: Yup.string().required("Campo richiesto"),
    nationality: Yup.string().required("Campo richiesto"),
    sex: Yup.string().oneOf(["M", "F"]).defined().required("Campo richiesto"),
    acceptedFundRaisingAgreement: Yup.number().notRequired(),
    acceptedMarketingAgreement: Yup.number().notRequired(),
    mobile: Yup.string().required("Campo richiesto"),
    activated: Yup.number().notRequired(),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? {
          ...initialValues,
          type: JSON.stringify(initialValues.type),
        }
      : {
          ...currentUser,
          type: JSON.stringify(currentUser.type),
        },
    validationSchema: PatchUserBodySchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setUpdatedUser(
          await UsersService.patchUser({
            id: values.id!,
            // @ts-ignore
            requestBody: {
              ...values,
              type: JSON.parse(values.type as unknown as string),
              nationality: countries.getAlpha2Code(values.nationality!, "it"),
              email: values.email.trim(),
              telephone: "",
              acceptedFundRaisingAgreement: Number(
                isFundRaisingAgreementAccepted
              ),
              acceptedMarketingAgreement: Number(isMarketAgreementAccepted),
              activated: Number(isActivated),
            },
          })
        );

        setSuccesSnackbarVisibility(true);
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
    setFieldTouched,
  } = formik;

  const handleLoginErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSuccessfulUpdateClose = () => {
    setSuccesSnackbarVisibility((previous) => !previous);
  };

  React.useEffect(() => {
    if (!initialValues && updatedUser) {
      dispatch(setUser(updatedUser.data));
    }
  }, [updatedUser, dispatch, initialValues]);

  useAsync(async () => {
    try {
      setErrorLoadingUserTypes(false);
      setIsLoadingUserTypes(true);
      setUserTypes(await UsersService.getUserType());
    } catch {
      setErrorLoadingUserTypes(true);
    } finally {
      setIsLoadingUserTypes(false);
    }
  }, []);

  if (isLoadingUserTypes) {
    return <PageLoader />;
  }

  if (errorLoadingUserTypes) {
    return <GenericError />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {[1, 2].includes((currentUser.type as UserType).id) &&
              currentUser.id !== values.id && (
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Switch />}
                      label="Questa utenza è attiva"
                      checked={isActivated}
                      disabled={
                        initialValues?.activated === 1 ||
                        updatedUser?.data.activated === 1
                      }
                      onChange={toggleIsActivated}
                    />
                  </FormGroup>
                </Stack>
              )}
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label="Indirizzo email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <MuiPhoneNumber
                defaultCountry={"it"}
                disableAreaCodes
                variant="outlined"
                label="Numero di telefono"
                fullWidth
                value={values.mobile}
                onChange={(value) => setFieldValue("mobile", value)}
                sx={{
                  svg: {
                    maxHeight: "20px",
                    height: "100%",
                  },
                }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Nome"
                {...getFieldProps("firstName")}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                type="text"
                label="Cognome"
                {...getFieldProps("lastName")}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <DesktopDatePicker
              label="Data di nascita"
              inputFormat="dd/MM/yyyy"
              renderInput={(parameters) => (
                <TextField
                  {...parameters}
                  fullWidth
                  error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                  helperText={touched.dateOfBirth && errors.dateOfBirth}
                />
              )}
              value={values.dateOfBirth}
              onChange={(value) => setFieldValue("dateOfBirth", value)}
            />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="user-sex-label">Sesso</InputLabel>

                <Select
                  labelId="user-sex-label"
                  aria-describedby="user-sex-label"
                  label="Sesso"
                  error={Boolean(touched.sex && errors.sex)}
                  {...getFieldProps("sex")}
                >
                  <MenuItem value="" disabled>
                    Seleziona Sesso
                  </MenuItem>
                  <MenuItem value={"M"}>Maschio</MenuItem>
                  <MenuItem value={"F"}>Femmina</MenuItem>
                </Select>

                {touched.sex && errors.sex && (
                  <FormHelperText id="user-type-label">
                    Seleziona il sesso
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth>
                <CountriesAutocomplete
                  id="nationality"
                  value={values.nationality}
                  error={Boolean(touched.nationality && errors.nationality)}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setFieldTouched("nationality");
                      setFieldValue("nationality", "");
                    } else {
                      setFieldValue(
                        "nationality",
                        // @ts-ignore
                        value.label
                      );
                    }
                  }}
                />

                {touched.nationality && errors.nationality && (
                  <FormHelperText id="user-nationality-label">
                    Seleziona la nazionalità dell'utente
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>

            <TextField
              fullWidth
              type="text"
              label="Codice Fiscale"
              {...getFieldProps("codfis")}
              error={Boolean(touched.codfis && errors.codfis)}
              helperText={touched.codfis && errors.codfis}
              inputProps={{
                minLength: values.nationality === "Italia" && 16,
                maxLength: values.nationality === "Italia" && 16,
                style: {
                  textTransform: "uppercase",
                },
              }}
            />

            {pathname !== "/me" && (
              <FormControl fullWidth>
                <InputLabel id="user-type-label">
                  Tipologia dell'utente
                </InputLabel>
                <Select
                  labelId="user-type-label"
                  aria-describedby="user-type-label"
                  label="Tipologia dell'utente"
                  error={Boolean(touched.type && errors.type)}
                  {...getFieldProps("type")}
                >
                  <MenuItem value="" disabled>
                    Seleziona tipologia utente
                  </MenuItem>
                  {userTypes?.data?.map((userType, index) => (
                    <MenuItem
                      value={JSON.stringify(userType)}
                      key={`service-type-${userType.id}-${index}`}
                    >
                      {userType.description}
                    </MenuItem>
                  ))}
                </Select>

                {touched.type && errors.type && (
                  <FormHelperText id="user-type-label">
                    Seleziona la tipologia dell'utente
                  </FormHelperText>
                )}
              </FormControl>
            )}

            <Divider />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="address"
                type="text"
                label="Indirizzo"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />

              <TextField
                fullWidth
                type="text"
                label="CAP"
                {...getFieldProps("cap")}
                error={Boolean(touched.cap && errors.cap)}
                helperText={touched.cap && errors.cap}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Città"
                {...getFieldProps("city")}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />

              <TextField
                fullWidth
                type="text"
                label="Provincia"
                {...getFieldProps("province")}
                error={Boolean(touched.province && errors.province)}
                helperText={touched.province && errors.province}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>

            <Divider />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Città di nascita"
                {...getFieldProps("cityOfBirth")}
                error={Boolean(touched.cityOfBirth && errors.cityOfBirth)}
                helperText={touched.cityOfBirth && errors.cityOfBirth}
              />

              <TextField
                fullWidth
                type="text"
                label="Provincia di nascita"
                {...getFieldProps("provinceOfBirth")}
                error={Boolean(
                  touched.provinceOfBirth && errors.provinceOfBirth
                )}
                helperText={touched.provinceOfBirth && errors.provinceOfBirth}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>
            {(currentUser.type as UserType).id === 5 && (
              <Stack spacing={5}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMarketAgreementAccepted}
                        onChange={toggleMarketAgreementAcceptance}
                        sx={{
                          top: "-48px",
                        }}
                      />
                    }
                    label={
                      <Typography>
                        Acconsento all’utilizzo dei miei dati anagrafici e di
                        contatto da parte del Titolare per consentirgli di
                        inviarmi comunicazioni promozionali e di marketing,
                        comprese newsletter e ricerche di mercato, relative a
                        iniziative di prevenzione e promozione della salute e di
                        nuovi servizi clinici e/o attività formativa e didattica
                        professionalizzante (ad es. corsi post lauream; tirocini
                        e attività formative nell’ambito delle scuole di
                        specializzazione), attraverso strumenti automatizzati
                        (sms, e-mail; notifiche push; mms; telefono senza
                        operatore) e non (posta ordinaria, telefono con
                        operatore) (“<b>finalità di marketing diretto</b>”).
                      </Typography>
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFundRaisingAgreementAccepted}
                        onChange={toggleFundRaisingAgreementAcceptance}
                        sx={{
                          top: "-60px",
                        }}
                      />
                    }
                    label={
                      <Typography>
                        Acconsento alla comunicazione dei miei dati anagrafici e
                        di contatto a terzi quali la Fondazione e l’Università
                        Campus Bio-Medico di Roma - in qualità di contitolari
                        del trattamento - per consentire loro di inviarmi
                        materiale informativo e promozionale sulle attività nei
                        settori della formazione universitaria, della ricerca
                        bio-medica e ingegneristica, dell’assistenza ospedaliera
                        e ambulatoriale condotte dai Contitolari stessi,
                        comprese l’attività di costumer satisfaction e l’invio
                        di newsletter, al fine di promuovere campagne di
                        raccolta fondi a loro favore, attraverso strumenti
                        automatizzati (sms, mms, e-mail, sistemi di chiamata
                        automatizzati senza operatore, utilizzo dei social
                        network, whatsapp) e non (posta ordinaria, telefono con
                        operatore) (“
                        <b>finalità di fundraising</b>”)
                      </Typography>
                    }
                  />
                </FormGroup>
              </Stack>
            )}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              endIcon={<UpgradeIcon />}
            >
              Aggiorna dati
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore durante l'aggiornamento dei dati
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSuccessfulUpdateClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSuccessfulUpdateClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Hai aggiornato i dati con successo
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserDetailsForm;
