import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
  TypedStartListening,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import cartReducer from "../features/cart/cartSlice";
import userReducer from "../features/user/userSlice";
import { cartSliceListenerMiddleware } from "../features/cart/cartSliceListener";

const reducers = combineReducers({
  user: userReducer,
  cart: cartReducer,
});

const persistConfig = {
  key: "assistenza_domiciliare_persist",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    // Add the listener middleware to the store.
    // NOTE: Since this can receive actions with functions inside,
    // it should go before the serializability check middleware
    getDefaultMiddleware().prepend(cartSliceListenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type AppStartListening = TypedStartListening<RootState, AppDispatch>;
