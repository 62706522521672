import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// import homeFill from '@iconify/icons-eva/home-fill';
// import personFill from '@iconify/icons-eva/person-fill';
// import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import LogoutIcon from "@mui/icons-material/Logout";
import MenuPopover from "../../components/MenuPopover";
//
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearUser, selectUser } from "../../features/user/userSlice";

// ----------------------------------------------------------------------

const MENU_OPTIONS: { label: string; icon?: string; linkTo: string }[] = [
  // {
  //   label: 'Home',
  //   // icon: homeFill,
  //   linkTo: '/',
  // },
  // {
  //   label: 'Profile',
  //   // icon: personFill,
  //   linkTo: '/me',
  // },
  // {
  //   label: 'Settings',
  //   // icon: settings2Fill,
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

const AccountPopover: React.FC = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const anchorReference = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("password");
    navigate("/auth/login", { replace: true });
  };

  return (
    <>
      <IconButton
        ref={anchorReference}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar
          src={
            // @ts-ignore
            user?.photoURL
          }
          alt={[user?.firstName, user?.lastName]
            .filter((value) => value)
            .join(" ")}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorReference.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {[user?.firstName, user?.lastName]
              .filter((value) => value)
              .join(" ")}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {
          // eslint-disable-next-line sonarjs/no-empty-collection
          MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Box
                // component={Icon}
                // icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))
        }

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            endIcon={<LogoutIcon />}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
