import React from "react";
import { Box, Typography, Stack } from "@mui/material";

import GenericErrorImage from "../assets/generic_error.svg";

const GenericError: React.FC = () => (
  <Box sx={{ display: "flex", height: "100%", justifyContent: "center" }}>
    <Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
    >
      <img
        src={GenericErrorImage}
        alt=""
        style={{
          maxWidth: 425,
        }}
      />

      <Stack alignItems="center" spacing={1}>
        <Typography variant="h5">Ops... qualcosa è andato storto!</Typography>

        <Typography color="text.secondary">
          Non preoccuparti, il nostro team è già stato notificato.
        </Typography>
      </Stack>
    </Stack>
  </Box>
);

export default GenericError;
