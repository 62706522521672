import React from "react";
import { useNavigate } from "react-router-dom";
// material
import { Stack, Typography, IconButton, Tooltip } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import usePortal from "react-useportal";

interface IPageTitleProperties {
  showBackButton: boolean;
  backButtonHandler?: () => void;
  hidePageTitle: boolean;
}

const Title: React.FC<IPageTitleProperties> = ({
  children,
  showBackButton,
  backButtonHandler,
  hidePageTitle,
}) => {
  const { Portal } = usePortal({
    // @ts-ignore
    bindTo: document && document.querySelector("#navbar-page-title"),
  });
  const navigate = useNavigate();

  return (
    <Portal>
      {showBackButton && (
        <Stack flexDirection="row" alignItems="center">
          <Tooltip enterDelay={1000} title="Torna indietro">
            <IconButton
              onClick={() =>
                backButtonHandler ? backButtonHandler() : navigate(-1)
              }
              sx={{ color: "gray.700" }}
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {!hidePageTitle && (
        <Typography
          variant="h4"
          color="primary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "25%",
            transform: "translateX(-50%)",
            textAlign: "center",
          }}
        >
          {children}
        </Typography>
      )}
    </Portal>
  );
};

export default Title;
