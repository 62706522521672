import React from "react";

// material
import {
  Container,
  Stack,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ContactsImage from "../../assets/contacts.svg";

// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

const Contacts: React.FC = () => (
  <Page title="Contatti">
    <Container>
      <Stack spacing={10}>
        <img src={ContactsImage} alt="" height={250} />

        <Stack
          spacing={5}
          direction={{
            xs: "column",
            md: "row",
          }}
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Card
            sx={{
              width: "414px",
              height: "300px",
            }}
          >
            <CardContent>
              <Stack alignItems="center" spacing={2}>
                <PhoneIcon
                  color="primary"
                  sx={{
                    fontSize: "xx-large",
                  }}
                />

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  textAlign="center"
                >
                  Puoi contattarci telefonicamente ai seguenti recapiti
                </Typography>
              </Stack>

              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                  marginLeft: "-16px !important",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                        backgroundColor: "primary.main",
                      }}
                    >
                      <PhoneIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        Informazioni e prenotazioni
                        <Tooltip title="" enterDelay={750} placement="right">
                          <HelpOutlineIcon
                            sx={{
                              color: "primary.main",
                              fontSize: "initial",
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    }
                    secondary="+39 06 225417788"
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>

          <Typography variant="overline" color="primary" fontSize="x-large">
            OPPURE
          </Typography>

          <Card
            sx={{
              width: "414px",
              height: "300px",
            }}
          >
            <CardContent>
              <Stack alignItems="center" spacing={2}>
                <AlternateEmailIcon
                  color="primary"
                  sx={{
                    fontSize: "xx-large",
                  }}
                />

                <Typography fontWeight="bold" textAlign="center">
                  Puoi inviare una mail ai seguenti indirizzi
                </Typography>

                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    marginLeft: "-16px !important",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: "primary.main",
                        }}
                      >
                        <AlternateEmailIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Stack alignItems="center" direction="row">
                          Informazioni e prenotazioni prestazioni mediche
                          <Tooltip title="" enterDelay={750} placement="right">
                            <HelpOutlineIcon
                              sx={{
                                color: "primary.main",
                                fontSize: "initial",
                                marginLeft: "4px",
                              }}
                            />
                          </Tooltip>
                        </Stack>
                      }
                      secondary="medicinadomiciliare@policlinicocampus.it"
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />

                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: "#ED9E31",
                        }}
                      >
                        <AlternateEmailIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <Stack alignItems="center" direction="row">
                          Informazioni e prenotazioni prestazioni
                          infermieristiche
                          <Tooltip title="" enterDelay={750} placement="right">
                            <HelpOutlineIcon
                              sx={{
                                color: "#ED9E31",
                                fontSize: "initial",
                                marginLeft: "4px",
                              }}
                            />
                          </Tooltip>
                        </Stack>
                      }
                      secondary="infermieristicadomiciliare@policlinicocampus.it"
                    />
                  </ListItem>
                </List>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
    </Container>
  </Page>
);

export default Contacts;
