import React from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

const LogoOnlyLayout: React.FC = () => (
  <>
    <HeaderStyle>
      <RouterLink
        to="/"
        style={{
          width: 400,
          maxWidth: "85vw",
          display: "block",
        }}
      >
        <Logo />
      </RouterLink>
    </HeaderStyle>
    <Outlet />
  </>
);

export default LogoOnlyLayout;
