import React from "react";
import { Box, CircularProgress } from "@mui/material";

const PageLoader: React.FC = () => (
  <Box
    sx={{
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress />
  </Box>
);

export default PageLoader;
