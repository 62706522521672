import React from "react";
import { useAsync } from "react-use";
// material
import {
  Box,
  Container,
  Typography,
  Stack,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  itIT,
} from "@mui/x-data-grid";
import { useNavigate, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import AssignmentIcon from "@mui/icons-material/Assignment";

// components
import Page from "../../components/Page";

import NoRequests from "../../assets/no_requests.svg";

import { RequestsService } from "../../api/assistenza-domiciliare";

import type { GetRequestsResponse } from "../../api/assistenza-domiciliare";
import { NoRowsOverlay } from "../../components/datagrid/NoRowsOverlay";
import { Footer } from "../../components/datagrid";

// ----------------------------------------------------------------------

const RequestsList: React.FC = () => {
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);

  const [requests, setRequests] = React.useState<GetRequestsResponse>();
  const [isLoadingRequests, setIsLoadingRequests] =
    React.useState<boolean>(false);

  const [isExportingRequests, setIsExportingRequests] = React.useState(false);
  const [exportedRequests, setExportedRequests] =
    React.useState<GetRequestsResponse>();

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "actions",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 10,
      renderCell: ({ row }) => (
        <Tooltip enterDelay={750} title={"Visualizza dettaglio richiesta"}>
          <IconButton
            color="primary"
            aria-label="visualizza dettaglio richiesta"
            component={Link}
            to={`/requests/${row.id}`}
          >
            <AssignmentIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "service",
      headerName: "Prestazione da Erogare",
      align: "left",
      flex: 2,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => (
        <Chip
          label={value.advancedDescription || value.description}
          color="info"
          variant="outlined"
          sx={{
            maxWidth: 550,
          }}
        />
      ),
    },
    {
      field: "orderStatus",
      headerName: "Stato Ordine",
      align: "left",
      flex: 2,
      sortable: false,
      filterable: false,
      valueGetter: ({ row }: GridValueGetterParams) => {
        if (row && row.order && row.order.status) {
          const { status, id } = row.order.status;

          return { status, id };
        }

        return {};
      },
      renderCell: ({ value }) => (
        <Chip
          label={value.status}
          color={value.id === 1 || value.id === 9 ? "error" : "info"}
          variant="outlined"
          sx={{
            maxWidth: 550,
          }}
        />
      ),
    },
    {
      field: "bookedDate",
      headerName: "Data Erogazione",
      width: 150,
      sortable: false,
      renderCell: ({ value }) => {
        const parsedDate =
          value &&
          new Intl.DateTimeFormat("it", {
            // @ts-ignore
            dateStyle: "medium",
            // @ts-ignore
            timeStyle: "short",
          }).format(new Date(value));

        return <Chip label={parsedDate} variant="outlined" />;
      },
    },
    {
      field: "indirizzo",
      headerName: "Indirizzo",
      flex: 1.25,
      valueGetter: ({ row }) => {
        const { user } = row.order;

        return [user?.address, user?.city, user?.province, user?.cap]
          .filter((value) => value)
          .join(", ");
      },
      renderCell: ({ value }) => (
        <Chip
          label={value}
          variant="outlined"
          sx={{
            maxWidth: 200,
          }}
        />
      ),
    },
    {
      field: "paziente",
      headerName: "Paziente",
      width: 150,
      valueGetter: ({ row }) => {
        const { user } = row.order;

        return [user?.firstName, user?.lastName]
          .filter((value) => value)
          .join(" ");
      },
      renderCell: ({ value }) => (
        <Chip
          label={value}
          variant="outlined"
          sx={{
            maxWidth: 150,
          }}
        />
      ),
    },
    {
      field: "cf",
      headerName: "CF Paziente",
      width: 175,
      valueGetter: ({ row }) => {
        const { user } = row.order;

        return user?.codfis;
      },
      // eslint-disable-next-line sonarjs/no-identical-functions
      renderCell: ({ value }) => (
        <Chip
          label={value}
          variant="outlined"
          sx={{
            maxWidth: 200,
          }}
        />
      ),
    },
  ];

  const exportRequests = async () => {
    try {
      setIsExportingRequests(true);

      setExportedRequests(
        await RequestsService.getRequests({
          skip: page * pageSize,
          take: pageSize,
          download: "csv",
        })
      );
    } finally {
      setIsExportingRequests(false);
    }
  };

  useAsync(async () => {
    try {
      setIsLoadingRequests(true);
      setRequests(
        await RequestsService.getRequests({
          skip: page * pageSize,
          take: pageSize,
        })
      );
    } finally {
      setIsLoadingRequests(false);
    }
  }, [page, pageSize]);

  React.useEffect(() => {
    if (exportedRequests) {
      const blob = new Blob([exportedRequests as unknown as string], {
        type: "text/csv; encoding=UTF-8",
      });
      saveAs(blob, "richieste.csv");
    }
  }, [exportedRequests]);

  return (
    <Page title="Richieste">
      <Container>
        <Box sx={{ pb: 5, height: "100%" }}>
          {requests?.data?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={4}
              >
                <img
                  src={NoRequests}
                  alt=""
                  style={{
                    width: "400px",
                  }}
                />

                <Stack
                  spacing={1}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography variant="h5">
                    Relax, te lo sei meritato! 😎
                  </Typography>

                  <Typography color="text.secondary">
                    Al momento non hai altre richieste da gestire
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          ) : (
            <DataGrid
              rows={requests?.data || []}
              loading={isLoadingRequests}
              columns={columns}
              pageSize={pageSize}
              rowCount={requests?.total}
              rowsPerPageOptions={[10, 20, 30]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              density="comfortable"
              editMode="row"
              autoHeight
              onRowDoubleClick={({ row }) => navigate(`/requests/${row.id}`)}
              paginationMode="server"
              onPageChange={(newPage) => setPage(newPage)}
              components={{
                Footer,
                NoRowsOverlay,
              }}
              componentsProps={{
                footer: {
                  canExportData: false,
                  disableExportButton:
                    !requests?.data || isLoadingRequests || isExportingRequests,
                  loadingServices: isLoadingRequests,
                  exportFn: exportRequests,
                },
              }}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default RequestsList;
