import React from "react";
import { useAsync } from "react-use";
// material
import { Container, Stack } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import UserDetailsForm from "../../components/forms/UserDetailsForm";
import ProfileImage from "../../assets/profile.svg";
// components
import Page from "../../components/Page";
import GenericError from "../../components/GenericError";
import PageLoader from "../../components/PageLoader";

import { UsersService } from "../../api/assistenza-domiciliare";

import type { GetUserResponse } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

const UserDetails: React.FC = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const isEditingItself = pathname === "/me";

  const [user, setUser] = React.useState<GetUserResponse>();
  const [loadingUser, setIsLoadingUser] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  useAsync(async () => {
    if (pathname !== "/me" && id) {
      try {
        setError(false);
        setIsLoadingUser(true);

        setUser(await UsersService.getUser({ id: +id }));
      } catch {
        setError(true);
      } finally {
        setIsLoadingUser(false);
      }
    }
  }, [id, pathname]);

  if (loadingUser) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <Page title="Anagrafica Utente" showBackButton={!isEditingItself}>
      <Container>
        <Stack spacing={5}>
          <img src={ProfileImage} alt="" height={150} />
          <UserDetailsForm initialValues={user?.data} />
        </Stack>
      </Container>
    </Page>
  );
};

export default UserDetails;
