import axios from "axios";
import qs from "qs";

export const cancelPaypalOrder = async (orderId: string) => {
  const paypalToken = await axios({
    method: "post",
    url: `${process.env.PAYPAL_BASE_URL!}/v1/oauth2/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: process.env.PAYPAL_BASIC_TOKEN!,
    },
    data: qs.stringify({
      grant_type: "client_credentials",
    }),
  });

  await axios({
    method: "delete",
    url: `${process.env.PAYPAL_BASE_URL!}/v1/checkout/orders/${orderId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${paypalToken.data.access_token}`,
    },
  });
};
