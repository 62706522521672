import * as Yup from "yup";
import React from "react";
import { useToggle } from "react-use";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CodiceFiscale from "codice-fiscale-js";
import MuiPhoneNumber from "material-ui-phone-number";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import countries from "i18n-iso-countries";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { boolean } from "boolean";
import EmailSentImage from "../../../assets/email_sent.svg";

import { useAppDispatch } from "../../../app/hooks";
import { setUser } from "../../../features/user/userSlice";

import {
  $PostRegisterBody,
  AuthService,
} from "../../../api/assistenza-domiciliare";

import type {
  PostRegisterResponse,
  PostRegisterBody,
} from "../../../api/assistenza-domiciliare";
import CountriesAutocomplete from "../../CountriesAutocomplete";

// ----------------------------------------------------------------------

// eslint-disable-next-line sonarjs/cognitive-complexity
const RegisterForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isPrivacyPolicyAccepted, togglePrivacyPolicyAcceptance] =
    useToggle(false);

  const [isFundRaisingAgreementAccepted, toggleFundRaisingAgreementAcceptance] =
    useToggle(false);

  const [isMarketAgreementAccepted, toggleMarketAgreementAcceptance] =
    useToggle(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const [newlyCreatedUser, setNewlyCreatedUser] =
    React.useState<PostRegisterResponse>();

  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);

  const RegisterSchema: Yup.SchemaOf<
    Omit<PostRegisterBody, "telephone" | "recaptchaResponse">
  > = Yup.object().shape({
    firstName: Yup.string()
      .min($PostRegisterBody.properties.firstName.minLength)
      .max($PostRegisterBody.properties.firstName.maxLength)
      .required("Il nome dell'utente è richiesto"),
    lastName: Yup.string().required("Il cognome dell'utente è richiesto"),
    email: Yup.string()
      .email("L'indirizzo email inserito non è valido")
      .max($PostRegisterBody.properties.email.maxLength)
      .required("È necessario inserire l'indirizzo email"),
    mobile: Yup.string().required(
      "È necessario inserire il numero di telefono dell'utente"
    ),
    password: Yup.string()
      .max($PostRegisterBody.properties.password.maxLength)
      .required("La password è richiesta"),
    codfis: Yup.string()
      .when("nationality", {
        is: "Italia",
        // eslint-disable-next-line unicorn/no-thenable
        then: (schema) =>
          schema
            .min(16, "Il Codice Fiscale deve contenere almeno 16 caratteri")
            .max(16, "Il Codice Fiscale deve contenere al più 16 caratteri")
            .test("invalid-cf", "Il Codice Fiscale non è valido", (value) => {
              if (!value) {
                return false;
              }

              return CodiceFiscale.check(value);
            }),
      })
      .required("Il Codice Fiscale è richiesto"),
    address: Yup.string()
      .max($PostRegisterBody.properties.address.maxLength)
      .required("L'indirizzo dell'utente è richiesto"),
    city: Yup.string()
      .max($PostRegisterBody.properties.city.maxLength)
      // eslint-disable-next-line sonarjs/no-duplicate-string
      .required("Campo richiesto"),
    province: Yup.string()
      .min($PostRegisterBody.properties.province.minLength)
      .max($PostRegisterBody.properties.province.maxLength)
      .required("Campo richiesto"),
    cap: Yup.string()
      .max($PostRegisterBody.properties.cap.maxLength)
      .required("Campo richiesto"),
    cityOfBirth: Yup.string()
      // eslint-disable-next-line sonarjs/no-duplicate-string
      .required("Campo richiesto"),
    provinceOfBirth: Yup.string()
      .min($PostRegisterBody.properties.provinceOfBirth.minLength)
      .max($PostRegisterBody.properties.provinceOfBirth.maxLength)
      .required("Campo richiesto"),
    dateOfBirth: Yup.string().required("Campo richiesto"),
    nationality: Yup.string().required("Campo richiesto"),
    sex: Yup.string().oneOf(["M", "F"]).defined().required("Campo richiesto"),
    acceptedFundRaisingAgreement: Yup.number().notRequired().default(0),
    acceptedMarketingAgreement: Yup.number().notRequired().default(0),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      codfis: "",
      password: "",
      address: "",
      city: "",
      province: "",
      cap: "",
      firstName: "",
      lastName: "",
      cityOfBirth: "",
      provinceOfBirth: "",
      dateOfBirth: "",
      nationality: "",
      sex: "",
      acceptedFundRaisingAgreement: 0,
      acceptedMarketingAgreement: 0,
      mobile: "",
      telephone: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const isGoogleRecaptchaEnabled = boolean(
        process.env.REACT_APP_ENABLE_GOOGLE_RECAPTCHA
      );

      if (isGoogleRecaptchaEnabled) {
        if (isPrivacyPolicyAccepted) {
          try {
            setNewlyCreatedUser(
              await AuthService.register({
                // @ts-ignore
                requestBody: {
                  ...values,
                  nationality: countries.getAlpha2Code(
                    values.nationality,
                    "it"
                  ),
                  email: values.email.trim(),
                  recaptchaResponse: await executeRecaptcha!(),
                  acceptedFundRaisingAgreement: Number(
                    isFundRaisingAgreementAccepted
                  ),
                  acceptedMarketingAgreement: Number(isMarketAgreementAccepted),
                },
              })
            );
          } catch {
            setErrorSnackbarVisibility(true);
            setSubmitting(false);
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (isPrivacyPolicyAccepted) {
          try {
            setNewlyCreatedUser(
              await AuthService.register({
                // @ts-ignore
                requestBody: {
                  ...values,
                  nationality: countries.getAlpha2Code(
                    values.nationality,
                    "it"
                  ),
                  email: values.email.trim(),
                  telephone: "",
                  recaptchaResponse: "",
                  acceptedFundRaisingAgreement: Number(
                    isFundRaisingAgreementAccepted
                  ),
                  acceptedMarketingAgreement: Number(isMarketAgreementAccepted),
                },
              })
            );
          } catch {
            setErrorSnackbarVisibility(true);
            setSubmitting(false);
          }
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    values,
  } = formik;

  const handleSnackbarErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  React.useEffect(() => {
    if (newlyCreatedUser) {
      dispatch(setUser(newlyCreatedUser.data));
    }
  }, [newlyCreatedUser, dispatch]);

  return (
    <>
      {!newlyCreatedUser && (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="email"
                  type="email"
                  label="Indirizzo email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <MuiPhoneNumber
                  defaultCountry={"it"}
                  disableAreaCodes
                  variant="outlined"
                  label="Numero di telefono"
                  fullWidth
                  value={values.mobile}
                  onChange={(value) => setFieldValue("mobile", value)}
                  sx={{
                    svg: {
                      maxHeight: "20px",
                      height: "100%",
                    },
                  }}
                  error={Boolean(touched.mobile && errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Nome"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Cognome"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>

              <DesktopDatePicker
                label="Data di nascita"
                inputFormat="dd/MM/yyyy"
                renderInput={(parameters) => (
                  <TextField
                    {...parameters}
                    fullWidth
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                  />
                )}
                value={values.dateOfBirth}
                onChange={(value) => setFieldValue("dateOfBirth", value)}
              />

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormControl fullWidth>
                  <InputLabel id="user-sex-label">Sesso</InputLabel>

                  <Select
                    labelId="user-sex-label"
                    aria-describedby="user-sex-label"
                    label="Sesso"
                    error={Boolean(touched.sex && errors.sex)}
                    {...getFieldProps("sex")}
                  >
                    <MenuItem value="" disabled>
                      Seleziona Sesso
                    </MenuItem>
                    <MenuItem value={"M"}>Maschio</MenuItem>
                    <MenuItem value={"F"}>Femmina</MenuItem>
                  </Select>

                  {touched.sex && errors.sex && (
                    <FormHelperText id="user-type-label">
                      Seleziona il sesso
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl fullWidth>
                  <CountriesAutocomplete
                    id="nationality"
                    value={values.nationality}
                    error={Boolean(touched.nationality && errors.nationality)}
                    onChange={(_, value, reason) => {
                      if (reason === "clear") {
                        setFieldTouched("nationality");
                        setFieldValue("nationality", "");
                      } else {
                        setFieldValue(
                          "nationality",
                          // @ts-ignore
                          value.label
                        );
                      }
                    }}
                  />

                  {touched.nationality && errors.nationality && (
                    <FormHelperText id="user-nationality-label">
                      Seleziona la nazionalità dell'utente
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <TextField
                fullWidth
                type="text"
                label="Codice Fiscale"
                {...getFieldProps("codfis")}
                error={Boolean(touched.codfis && errors.codfis)}
                helperText={touched.codfis && errors.codfis}
                inputProps={{
                  minLength: values.nationality === "Italia" && 16,
                  maxLength: values.nationality === "Italia" && 16,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                label="Password"
                {...getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((previous) => !previous)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}{" "}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />

              <Divider />

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="address"
                  type="text"
                  label="Indirizzo"
                  {...getFieldProps("address")}
                  error={Boolean(touched.address && errors.address)}
                  helperText={touched.address && errors.address}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="CAP"
                  {...getFieldProps("cap")}
                  error={Boolean(touched.cap && errors.cap)}
                  helperText={touched.cap && errors.cap}
                />
              </Stack>

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Città"
                  {...getFieldProps("city")}
                  error={Boolean(touched.city && errors.city)}
                  helperText={touched.city && errors.city}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Provincia"
                  {...getFieldProps("province")}
                  error={Boolean(touched.province && errors.province)}
                  helperText={touched.province && errors.province}
                  inputProps={{
                    minLength: 2,
                    maxLength: 2,
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                />
              </Stack>

              <Divider />

              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  fullWidth
                  type="text"
                  label="Città di nascita"
                  {...getFieldProps("cityOfBirth")}
                  error={Boolean(touched.cityOfBirth && errors.cityOfBirth)}
                  helperText={touched.cityOfBirth && errors.cityOfBirth}
                />

                <TextField
                  fullWidth
                  type="text"
                  label="Provincia di nascita"
                  {...getFieldProps("provinceOfBirth")}
                  error={Boolean(
                    touched.provinceOfBirth && errors.provinceOfBirth
                  )}
                  helperText={touched.provinceOfBirth && errors.provinceOfBirth}
                  inputProps={{
                    minLength: 2,
                    maxLength: 2,
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                />
              </Stack>

              <Typography>
                Letta e compresa l’informativa di cui all’art. 13 del
                Regolamento UE 2016/679 e consapevole che:
              </Typography>

              <Typography
                sx={{
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  marginTop: "4px !important",
                }}
              >
                - il trattamento riguarda anche i miei Dati Particolari;
              </Typography>

              <Typography
                sx={{
                  marginTop: "4px !important",
                }}
              >
                - il consenso, una volta manifestato, potrà essere modificato o
                revocato ex art. 7 del GDPR, in qualsiasi momento e senza
                pregiudizio della liceità del trattamento effettuato prima della
                revoca;
              </Typography>

              <Typography
                sx={{
                  marginTop: "4px !important",
                }}
              >
                - il consenso al trattamento dei Dati Personali prestato ha
                validità permanente salvo revoca ex art. 7 del GDPR e/o modifica
                dello stresso;
              </Typography>

              <Typography
                sx={{
                  marginTop: "4px !important",
                }}
              >
                - il consenso relativo ai minori decade col raggiungimento della
                maggiore età con la conseguenza che, in tal caso, dovrà essere
                nuovamente espresso:
              </Typography>

              <Stack spacing={5}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPrivacyPolicyAccepted}
                        sx={{
                          top: "-58px",
                        }}
                        onChange={togglePrivacyPolicyAcceptance}
                      />
                    }
                    label={
                      <Typography>
                        Acconsento al trattamento dei miei Dati Personali
                        Particolari da parte della Fondazione Policlinico
                        Universitario Campus Bio-Medico di Roma per le finalità
                        di prenotazione e pagamento della vista e/o dell’esame
                        e/o della prestazione sanitaria e assistenziale
                        richiesta attraverso la piattaforma “Assistenza
                        Domiciliare
                      </Typography>
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMarketAgreementAccepted}
                        onChange={toggleMarketAgreementAcceptance}
                        sx={{
                          top: "-118px",
                        }}
                      />
                    }
                    label={
                      <Typography>
                        Acconsento all’utilizzo dei miei dati anagrafici e di
                        contatto da parte del Titolare per consentirgli di
                        inviarmi comunicazioni promozionali e di marketing,
                        comprese newsletter e ricerche di mercato, relative a
                        iniziative di prevenzione e promozione della salute e di
                        nuovi servizi clinici e/o attività formativa e didattica
                        professionalizzante (ad es. corsi post lauream; tirocini
                        e attività formative nell’ambito delle scuole di
                        specializzazione), attraverso strumenti automatizzati
                        (sms, e-mail; notifiche push; mms; telefono senza
                        operatore) e non (posta ordinaria, telefono con
                        operatore) (“<b>finalità di marketing diretto</b>”).
                      </Typography>
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isFundRaisingAgreementAccepted}
                        onChange={toggleFundRaisingAgreementAcceptance}
                        sx={{
                          top: "-155px",
                        }}
                      />
                    }
                    label={
                      <Typography>
                        Acconsento alla comunicazione dei miei dati anagrafici e
                        di contatto a terzi quali la Fondazione e l’Università
                        Campus Bio-Medico di Roma - in qualità di contitolari
                        del trattamento - per consentire loro di inviarmi
                        materiale informativo e promozionale sulle attività nei
                        settori della formazione universitaria, della ricerca
                        bio-medica e ingegneristica, dell’assistenza ospedaliera
                        e ambulatoriale condotte dai Contitolari stessi,
                        comprese l’attività di costumer satisfaction e l’invio
                        di newsletter, al fine di promuovere campagne di
                        raccolta fondi a loro favore, attraverso strumenti
                        automatizzati (sms, mms, e-mail, sistemi di chiamata
                        automatizzati senza operatore, utilizzo dei social
                        network, whatsapp) e non (posta ordinaria, telefono con
                        operatore) (“<b>finalità di fundraising</b>”)
                      </Typography>
                    }
                  />
                </FormGroup>
              </Stack>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                disabled={!isPrivacyPolicyAccepted}
              >
                Registrati
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      )}

      {newlyCreatedUser && (
        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={4}
        >
          <img
            src={EmailSentImage}
            alt=""
            style={{
              maxWidth: "200px",
            }}
          />

          <Typography variant="h5" textAlign="center">
            A breve riceverai una mail contenente un link per attivare la tua
            utenza!
          </Typography>
        </Stack>
      )}

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSnackbarErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSnackbarErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          La registrazione non è andata a buon fine
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterForm;
