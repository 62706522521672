import * as Yup from "yup";
import React from "react";
import { useFormik, Form, FormikProvider } from "formik";

// material
import {
  Stack,
  TextField,
  Alert,
  Snackbar,
  Typography,
  Button,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton } from "@mui/lab";
import LockResetIcon from "@mui/icons-material/LockReset";
import EmailSentImage from "../../assets/email_sent.svg";

import { AuthService } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

export default function RecoverPasswordForm() {
  const [isRecoverPasswordErrorVisible, setRecoverPasswordErrorVisibility] =
    React.useState<boolean>(false);
  const [emailSent, setEmailSent] = React.useState<boolean>(false);

  const [, setError] = React.useState<boolean>(false);

  const RecoverPasswordFormSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci la tua Email")
      .required("Devi necessariamente fornire il tuo indirizzo email"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RecoverPasswordFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setError(false);

        await AuthService.askResetPassword({
          requestBody: values,
        });

        setEmailSent(true);
      } catch {
        setError(true);
        setRecoverPasswordErrorVisibility(true);
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    formik;

  const handleLoginErrorClose = () => {
    setRecoverPasswordErrorVisibility((previous) => !previous);
  };

  if (emailSent) {
    return (
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={4}
      >
        <img
          src={EmailSentImage}
          alt=""
          style={{
            maxWidth: "200px",
          }}
        />

        <Typography variant="h5" textAlign="center">
          A breve riceverai una mail contenente un link per resettare la tua
          password!
        </Typography>

        <Stack
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={1}
        >
          <Typography textAlign="center" color="text.secondary">
            Se stai ancora aspettando puoi richiedere l'invio di una nuova mail!
          </Typography>

          <Button
            variant="contained"
            endIcon={<ReplayIcon />}
            size="large"
            sx={{
              alignSelf: "center",
              textTransform: "none",
            }}
            onClick={async () => {
              await AuthService.askResetPassword({
                requestBody: values,
              });
            }}
          >
            Invia nuovamente email
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Indirizzo email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              endIcon={<LockResetIcon fontSize="large" />}
            >
              Richiedi reset password
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isRecoverPasswordErrorVisible}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Impossibile resettare password
        </Alert>
      </Snackbar>
    </>
  );
}
