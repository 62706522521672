import * as Yup from "yup";
import React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import LoginIcon from "@mui/icons-material/Login";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { boolean } from "boolean";

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Link,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AuthService } from "../../api/assistenza-domiciliare/services/AuthService";
import { useAppDispatch } from "../../app/hooks";
import { setUser } from "../../features/user/userSlice";

import type {
  ApiError,
  PostLoginResponse,
} from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [user, setLoginUser] = React.useState<PostLoginResponse>();
  const [loginError, setLoginError] = React.useState<ApiError | undefined>();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci un indirizzo Email valido")
      .required("Devi inserire un indirizzo Email"),
    password: Yup.string().required("Inserisci una password"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const isGoogleRecaptchaEnabled = boolean(
        process.env.REACT_APP_ENABLE_GOOGLE_RECAPTCHA
      );

      if (isGoogleRecaptchaEnabled) {
        try {
          const loginResponse = await AuthService.login({
            requestBody: {
              ...values,
              email: values.email.trim(),
              recaptchaResponse: await executeRecaptcha!(),
            },
          });
          setLoginUser(loginResponse);

          sessionStorage.setItem("email", values.email);
          sessionStorage.setItem("password", values.password);

          navigate("/", { replace: true });
        } catch (error) {
          setLoginError(error as ApiError);
        } finally {
          setSubmitting(false);
        }
      } else {
        try {
          setLoginUser(
            await AuthService.login({
              requestBody: {
                ...values,
                email: values.email.trim(),
                recaptchaResponse: "",
              },
            })
          );

          sessionStorage.setItem("email", values.email);
          sessionStorage.setItem("password", values.password);

          navigate("/", { replace: true });
        } catch (error) {
          setLoginError(error as ApiError);
        } finally {
          setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleLoginErrorClose = () => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setLoginError(undefined);
  };

  React.useEffect(() => {
    if (user) {
      dispatch(setUser(user.data));
    }
  }, [user, dispatch]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Indirizzo email"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
            <Link
              variant="subtitle2"
              component={RouterLink}
              to="/auth/recover-password"
              sx={{
                fontSize: "small",
                marginLeft: "auto !important",
                marginTop: "5px !important",
              }}
            >
              Password dimenticata?
            </Link>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              endIcon={<LoginIcon />}
            >
              Login
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={loginError !== undefined}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore durante l'accesso
        </Alert>
      </Snackbar>

      <Snackbar
        open={loginError?.message.includes("403")}
        autoHideDuration={0}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="warning"
          sx={{ width: "100%" }}
          action={
            <Button
              component={RouterLink}
              to="/auth/resend-account-activation-link"
            >
              Non hai ricevuto la mail?
            </Button>
          }
        >
          Questo account non è attivo
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginForm;
