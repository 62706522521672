/* eslint-disable max-len */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import type { DialogProps } from "@mui/material";

export interface IDoctorPrivacyPolicyDialogProperties
  extends Omit<DialogProps, "fullScreen"> {
  children?: never;
}

const DoctorPrivacyPolicyDialog: React.FC<IDoctorPrivacyPolicyDialogProperties> =
  (properties) => {
    const theme = useTheme();

    return (
      <Dialog
        {...properties}
        fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
      >
        <DialogTitle id="doctor-privacy-policy-dialog">
          Privacy Policy
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            component={Box}
            px={2}
            dangerouslySetInnerHTML={{
              __html: `<p style="margin-right: -.05pt; text-align: center; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">INFORMATIVA AI SENSI DELL&rsquo; ART. 13 DEL REGOLAMENTO (UE) 679/2016</span></strong></p>
<p style="margin-right: -.05pt; text-align: center; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">RELATIVA AL TRATTAMENTO DEI DATI PERSONALI PER LA PIATTAFORMA WEB &ldquo;ASSISTENZA DOMICILIARE&rdquo;</span></strong></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></strong></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><strong><em><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black;">La presente informativa &egrave; integrativa dell&rsquo;informativa ex art. 13 e 14 del GDPR resa per il sito web &ldquo;</span></em></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">ASSISTENZA DOMICILIARE&rdquo;<em><span style="color: black;"> raggiungibile al seguente link </span></em></span></strong><a href="https://assistenzadomiciliare.policlinicocampusbiomedico.it/auth/register%20"><strong><em><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">https://assistenzadomiciliare.policlinicocampusbiomedico.it/auth/register</span></em></strong></a><strong><em><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black;"> , e dell&rsquo;informativa resa ai dipendenti disponibile presso&nbsp;&nbsp;&nbsp; </span></em></strong><a href="http://campusnet.unicampus-int.it/universita/1790"><strong><em><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">http://campusnet.unicampus-int.it/universita/1790</span></em></strong></a><strong><em><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black;">,</span></em></strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black;"> <strong><em>alla quale si rinvia per la consultazione di tutte le informazioni di seguito non espressamente riportate.</em></strong></span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Gentile Signora/e,</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">con la presente informativa la <strong>Fondazione Policlinico Universitario Campus Bio-Medico</strong>, (di seguito denominata &ldquo;<strong>Fondazione</strong>&rdquo;), con sede in Roma, Via &Aacute;lvaro del Portillo, 200<strong>,&nbsp; </strong>raggiungibile all&rsquo;indirizzo<strong> </strong>e-mail: <strong>presidenza@pec.policlinicocampus.it</strong>; in qualit&agrave; di Titolare del trattamento dei Suoi Dati Personali (di seguito anche &ldquo;<strong>Titolare</strong>&rdquo;), intende informarLa, ai sensi dell&rsquo;art. 13 e 14 del Regolamento (UE) 2016/679 (di seguito &ldquo;<strong>Regolamento</strong>&rdquo;), sulla tipologia di dati raccolti e sulle modalit&agrave; di trattamento degli stessi nell&rsquo;ambito dell&rsquo;utilizzo della piattaforma &ldquo;Assistenza Domiciliare&rdquo;<span style="color: #1f497d;">&nbsp;</span><span style="color: black;"> (di </span>seguito &ldquo;<strong>Piattaforma</strong>&rdquo;), integrata all&rsquo;interno del sito web </span><a href="https://www.policlinicocampusbiomedico.it/"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">policlinicocampusbiomedico.it</span></a><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">.</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Il Responsabile della protezione dei Dati Personali&nbsp;(di seguito il<strong>&nbsp;&ldquo;Data Protection Officer&rdquo;</strong>&nbsp;o&nbsp;<strong>&ldquo;DPO&rdquo;</strong>) &egrave; contattabile ai seguenti indirizzi:</span></p>
<ul style="margin-top: 0cm;">
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">e-mail, all&rsquo;indirizzo: </span><a href="mailto:dpo@policlinicocampus.it"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">dpo@policlinicocampus.it</span></a><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"> ;</span></li>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">posta ordinaria, all&rsquo;indirizzo <strong>Fondazione Policlinico Universitario Campus Bio-Medico</strong>, con sede in Roma, Via &Aacute;lvaro del Portillo, 200,&nbsp;<strong>c.a. del&nbsp;Data Protection Officer.</strong></span></li>
</ul>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol>
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Tipi di dati oggetto del trattamento</span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Il Titolare tratter&agrave; i Suoi dati personali comuni, raccolti in occasione e nell&rsquo;ambito della Sua registrazione alla Piattaforma in qualit&agrave; di utente (personale medico) tra cui rientrano, a titolo esemplificativo e non esaustivo, nome, cognome, dati di contatto, indirizzo, username, password e log operativi e di sistema generati attraverso l&rsquo;utilizzo della piattaforma. </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%; vertical-align: baseline;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black; letter-spacing: .6pt;">&nbsp;</span></p>
<ol start="2">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Finalit&agrave;, base giuridica e facoltativit&agrave; del trattamento</span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">I Suoi Dati Personali saranno trattati esclusivamente per le seguenti finalit&agrave;: </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">l&rsquo;erogazione del servizio di prenotazione e pagamento erogato agli utenti attraverso la piattaforma, comprensivo delle comunicazioni di servizio di avvenuto pagamento e della gestione della sicurezza del Sito, nonch&eacute; delle relazioni contrattuali e amministrativo contabili e di assistenza a tale servizio connesse (&ldquo;<strong>finalit&agrave; di esecuzione del servizio di prenotazione&rdquo;</strong>);</span></li>
</ol>
<p style="text-align: justify; line-height: 115%; margin: 0cm -.05pt .0001pt 21.3pt;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Una volta conferiti, i Suoi dati potranno essere trattati dal Titolare anche per le seguenti finalit&agrave;:</span></p>
<p style="text-align: justify; line-height: 115%; margin: 0cm -.05pt .0001pt 21.3pt;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">adempiere ad eventuali obblighi previsti dalle leggi vigenti, da regolamenti o dalla normativa comunitaria (in particolare in materia di igiene e sanit&agrave; ed in relazione ad adempimenti fiscali), o soddisfare richieste provenienti dalle autorit&agrave; (in particolare verifiche di carattere amministrativo, ispezioni di organi preposti alla vigilanza in materia sanitaria; investigazioni della polizia giudiziaria ecc); (&ldquo;<strong>finalit&agrave; di <em>compliance</em></strong>&rdquo;);</span></li>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black;">nell&rsquo;eventualit&agrave; in cui sia necessario accertare, esercitare o difendere un diritto in sede giudiziaria </span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">(&ldquo;<strong>finalit&agrave; difensive</strong>&rdquo;);</span></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%; vertical-align: baseline;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif; color: black; letter-spacing: .6pt;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">La base di liceit&agrave; del trattamento di Dati Personali per la Finalit&agrave; di esecuzione servizio di prenotazione si basa sull&rsquo;art. 6.1.b) del GDPR, in quanto necessario per dare esecuzione al rapporto lavorativo con il Titolare &ndash; quale Suo datore di lavoro<span style="text-decoration: line-through;">.</span><span style="color: black; letter-spacing: .6pt;"> </span>Il conferimento dei dati personali per questa finalit&agrave; &egrave; facoltativo, ma l'eventuale mancato conferimento comporterebbe l&rsquo;impossibilit&agrave; di accedere ed utilizzare tale piattaforma web e quindi di dare seguito al servizio di assistenza infermieristica, socio &ndash; sanitaria, ausiliaria offerto dalla Fondazione. </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">La base legale del trattamento di Dati Personali per le finalit&agrave; di <em>compliance</em> di cui alla lett. b) &egrave; l&rsquo;art. 6, par. 1, lett. c) del Regolamento. </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">La base legale del trattamento per le finalit&agrave; difensive di cui alla lettera c) risiede negli artt. 6, par. 1, lett. f) e 9, per. 2, lett. f) del Regolamento. Una volta conferiti i Dati Personali, il trattamento&nbsp;potrebbe diventare necessario per garantire il legittimo interesse del Titolare per accertare, esercitare o difendere un proprio diritto in sede giudiziale e stragiudiziale.</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol start="3">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Modalit&agrave; di trattamento dei dati</span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">I Suoi Dati saranno trattati con modalit&agrave; manuali nonch&eacute; con l&rsquo;ausilio di strumenti elettronici e telematici, nel rispetto del Regolamento e dei Provvedimenti del Garante per la protezione dei Dati Personali applicabili, secondo logiche e procedure strettamente correlate alle finalit&agrave; sopra indicate.&nbsp; </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol start="4">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Destinatari e trasferimento dei Dati Personali </span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">I Suoi Dati Personali potranno essere condivisi, per le finalit&agrave; di cui sopra, con i soggetti indicati di seguito (&ldquo;</span><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Destinatari</span></strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&rdquo;):</span></p>
<ul>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">soggetti che agiscono tipicamente in qualit&agrave; di responsabili del trattamento ai sensi dell&rsquo;art. 28 del Regolamento (tra cui il fornitore della piattaforma Cyberneid S.r.l.,);</span></li>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">soggetti che agiscono in qualit&agrave; di autonomi titolari del trattamento, incaricati dell&rsquo;esecuzione di specifiche attivit&agrave; di cui alla presente informativa e con i quali il Titolare abbia stipulato accordi commerciali (in particolare Paypal per l&rsquo;esecuzione dei servizi di pagamento).</span></li>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">soggetti, enti od autorit&agrave;, autonomi titolari, a cui sia obbligatorio comunicare i Suoi Dati Personali in forza di disposizioni di legge o di ordini delle autorit&agrave; (es. ASL, l&rsquo;Agenzia delle Entrate, Autorit&agrave; giudiziaria ecc.);</span></li>
<li><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">persone autorizzate dal Titolare al trattamento di Dati Personali ai sensi degli artt. 29 del Regolamento e 2-quaterdecies del D. Lgs. 196/2003 (cd. &ldquo;Codice Privacy&rdquo;) che si siano impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza (es. dipendenti, collaboratori, ecc.);</span></li>
</ul>
<p style="text-align: justify; line-height: 115%; margin: 0cm -.05pt .0001pt 57.3pt;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol start="5">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Trasferimento dei dati verso un paese terzo o un&rsquo;organizzazione internazionale </span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Il Titolare assicura che in caso di <span style="color: black;">eventuale trasferimento dei Suoi Dati Personali verso Paesi Terzi extra Spazio Economico Europeo (SEE) o organizzazioni internazionali, il trattamento avverr&agrave; nel rispetto della normativa ovvero secondo una delle modalit&agrave; consentite dalla legge ai sensi degli artt. 44-49 del GDPR, quali ad esempio il consenso dell&rsquo;interessato, l&rsquo;adozione di Clausole Standard approvate dalla Commissione Europea, la selezione di soggetti aderenti a programmi internazionali per la libera circolazione dei dati,&nbsp;nel rispetto di quanto disposto dalle Raccomandazioni 01/2020 e 02/2020 adottate il 10 novembre 2020 &nbsp;dello&nbsp;European&nbsp;Data&nbsp;Protection&nbsp;Board.</span></span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol start="6">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Conservazione dei Dati Personali </span></strong></li>
</ol>
<p style="text-autospace: none;"><span style="font-size: 12.0pt; font-family: 'Calibri',sans-serif; color: black;">I Dati Personali trattati saranno conservati per il tempo strettamente necessario a raggiungere quelle stesse finalit&agrave;&nbsp;nel rispetto dei principi di minimizzazione e limitazione della conservazione ex artt. 5.1.c) ed e) del GDPR.&nbsp; Maggiori informazioni sono disponibili presso il Titolare o al DPO scrivendo agli recapiti suindicati.</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<ol start="7">
<li><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;"><span style="font: 7.0pt 'Times New Roman';"> </span></span></strong><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">I Suoi diritti </span></strong></li>
</ol>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Lei ha il diritto di esercitare i diritti di cui agli artt. 15-22 del Regolamento e di revocare in ogni momento i consensi prestati senza pregiudizio alla liceit&agrave; del trattamento effettuato prima della revoca. In particolare, Lei potr&agrave; accedere ai suoi dati ai sensi dell&rsquo;art. 15 del Regolamento, chiederne la rettifica ex art. 15 del Regolamento, la cancellazione ex art. 17 del Regolamento, la limitazione del trattamento nei casi previsti dall'art. 18 del Regolamento e ottenerne la portabilit&agrave; nei casi previsti dall'art. 20 del Regolamento.</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Inoltre, Lei pu&ograve; formulare una richiesta di opposizione al trattamento dei Suoi Dati Personali ex art. 21 del Regolamento nella quale dare evidenza delle ragioni che giustifichino l&rsquo;opposizione: il Titolare si riserva di valutare la Sua istanza, che non verrebbe accettata in caso di esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgano sui Suoi interessi, diritti e libert&agrave;. </span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">Le richieste relative all&rsquo;esercizio dei Suoi diritti vanno rivolte per iscritto al Titolare ovvero al DPO ai recapiti sopraindicati.</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">La informiamo, infine, che Lei pu&ograve;, in ogni caso, proporre reclamo all'Autorit&agrave; di controllo competente ex art. 77 del Regolamento (Garante per la Protezione dei Dati Personali).</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></strong></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></strong></p>
<p style="margin-right: -.05pt; text-align: center; line-height: 115%;"><strong><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></strong></p>
<p style="text-align: justify; line-height: 115%; margin: 3.0pt -.05pt 3.0pt 0cm;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>
<p style="margin-right: -.05pt; text-align: justify; line-height: 115%;"><span style="font-size: 12.0pt; line-height: 115%; font-family: 'Calibri',sans-serif;">&nbsp;</span></p>`,
            }}
          ></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event) =>
              // @ts-ignore
              properties.onClose?.(event)
            }
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default DoctorPrivacyPolicyDialog;
