/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetRequestResponse } from '../models/GetRequestResponse';
import type { GetRequestsAvailabilityResponse } from '../models/GetRequestsAvailabilityResponse';
import type { GetRequestsResponse } from '../models/GetRequestsResponse';
import type { GetRequestStatusesResponse } from '../models/GetRequestStatusesResponse';
import type { GetRequestStatusResponse } from '../models/GetRequestStatusResponse';
import type { PatchRequestBody } from '../models/PatchRequestBody';
import type { PatchRequestResponse } from '../models/PatchRequestResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class RequestsService {

    /**
     * @returns GetRequestStatusesResponse Default Response
     * @throws ApiError
     */
    public static getRequestsStatuses({
skip,
take,
sort,
orderby,
}: {
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetRequestStatusesResponse> {
        return __request({
            method: 'GET',
            path: `/requestsStatus/`,
            query: {
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns GetRequestStatusResponse Default Response
     * @throws ApiError
     */
    public static getRequestStatus({
id,
}: {
id: number,
}): CancelablePromise<GetRequestStatusResponse> {
        return __request({
            method: 'GET',
            path: `/requestsStatus/${id}`,
        });
    }

    /**
     * @returns GetRequestsResponse Default Response
     * @throws ApiError
     */
    public static getRequests({
skip,
take,
sort,
orderby,
download,
}: {
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
download?: 'csv',
}): CancelablePromise<GetRequestsResponse> {
        return __request({
            method: 'GET',
            path: `/requests/`,
            query: {
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
                'download': download,
            },
        });
    }

    /**
     * @returns GetRequestResponse Default Response
     * @throws ApiError
     */
    public static getRequest({
id,
}: {
id: number,
}): CancelablePromise<GetRequestResponse> {
        return __request({
            method: 'GET',
            path: `/requests/${id}`,
            errors: {
                403: `Default Response`,
            },
        });
    }

    /**
     * @returns PatchRequestResponse Default Response
     * @throws ApiError
     */
    public static patchRequest({
id,
requestBody,
}: {
id: number,
requestBody?: PatchRequestBody,
}): CancelablePromise<PatchRequestResponse> {
        return __request({
            method: 'PATCH',
            path: `/requests/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetRequestsAvailabilityResponse Default Response
     * @throws ApiError
     */
    public static getAvailability({
date,
doctorId,
}: {
date: number,
doctorId: number,
}): CancelablePromise<GetRequestsAvailabilityResponse> {
        return __request({
            method: 'GET',
            path: `/requests/availability`,
            query: {
                'date': date,
                'doctorId': doctorId,
            },
        });
    }

}