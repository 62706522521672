import * as Yup from "yup";
import React from "react";
import { useAsync } from "react-use";
import { useFormik, Form, FormikProvider } from "formik";
import AddIcon from "@mui/icons-material/Add";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import ClearIcon from "@mui/icons-material/Clear";
import TodayIcon from "@mui/icons-material/Today";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { LoadingButton } from "@mui/lab";
// eslint-disable-next-line import/no-duplicates
import { isSameDay, differenceInDays } from "date-fns";
// eslint-disable-next-line import/no-duplicates

import type { SchemaOf } from "yup";

// material
import {
  Alert,
  Snackbar,
  Stack,
  TextField,
  InputAdornment,
  FormHelperText,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RequiredStringSchema } from "yup/lib/string";
import GenericError from "../GenericError";
import PageLoader from "../PageLoader";

import { ServicesService } from "../../api/assistenza-domiciliare";

import type {
  GetServicesTypesResponse,
  GetServiceResponse,
  PostServiceBody,
  PatchServiceResponse,
  Service,
} from "../../api/assistenza-domiciliare";

import ServicesAutocomplete from "../ServicesAutocomplete";

// ----------------------------------------------------------------------

interface IServiceFormProperties {
  initialValues?: GetServiceResponse["data"];
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const ServiceForm: React.FC<IServiceFormProperties> = ({ initialValues }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [service, setService] = React.useState<PatchServiceResponse>();
  const [isPackage, setIsPackage] = React.useState<boolean>(false);

  const [referencedService, setReferencedService] = React.useState<Service>(
    initialValues?.referencedService
  );
  const [referencedServiceQuantity, setReferencedServiceQuantity] =
    React.useState<number | undefined>(
      initialValues?.referencedServiceQuantity
    );

  const [isSuccessSnackbarShown, setSuccesSnackbarVisibility] =
    React.useState<boolean>(false);
  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);

  const schema: SchemaOf<PostServiceBody> = Yup.object().shape({
    type: Yup.object()
      .shape({
        id: Yup.number().required(),
        description: Yup.string().required(),
      })
      .required("Seleziona la tipologia della prestazione"),
    name: Yup.string().required("Inserisci il nome della prestazione"),
    description: Yup.string().required(
      "Inserisci la descrizione della prestazione"
    ),
    advancedDescription: Yup.string().notRequired(),
    price: Yup.number()
      .required("Inserisci il prezzo di acquisto della prestazione")
      .moreThan(
        0,
        "Il prezzo di acquisto di una prestazione deve essere superiore a 0€"
      ),
    startValidity: Yup.date()
      .required("Inserisci la data di inizio validità")
      // eslint-disable-next-line func-names
      .transform(function (value) {
        return this.isType(value) && value !== null && value;
      }) as unknown as RequiredStringSchema<string>,
    endValidity: Yup.date()
      .nullable()
      .notRequired()
      // eslint-disable-next-line func-names
      .transform(function (value) {
        return this.isType(value) && value;
      }) as unknown as RequiredStringSchema<string | undefined | null>,
    referencedServiceId: Yup.number().notRequired().nullable().default(null),
    referencedServiceQuantity: Yup.number().min(2).notRequired().default(2),
    sharedPercentage: Yup.number()
      .required("Questo campo è richiesto")
      .min(0, "La percentuale di compartecipazione deve essere almeno del 0%")
      .max(100, "La percentuale di compartecipazione è al più del 100%")
      .default(0),
    ivaCodeId: Yup.number().default(4),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? {
          ...initialValues,
          type: JSON.stringify(initialValues.type),
          referencedServiceQuantity:
            initialValues.referencedServiceQuantity || 2,
        }
      : {
          name: "",
          description: "",
          advancedDescription: "",
          price: 1,
          startValidity: new Date(),
          endValidity: null,
          type: "",
          referencedServiceQuantity: 2,
          referencedService: null,
          sharedPercentage: 0,
        },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const type = JSON.parse(values.type);

        if (id) {
          setService(
            await ServicesService.patchService({
              id: +id!,
              // @ts-ignore
              requestBody: {
                ...values,
                referencedServiceQuantity: isPackage
                  ? values.referencedServiceQuantity
                  : undefined,
                type: {
                  id: type.id,
                  description: type.description,
                },
                sharedPercentage: type.id !== 3 ? 0 : values.sharedPercentage,
              },
            })
          );
        } else {
          setService(
            await ServicesService.createService({
              // @ts-ignore
              requestBody: {
                ...values,
                referencedServiceQuantity: isPackage
                  ? values.referencedServiceQuantity
                  : undefined,
                type: {
                  id: type.id,
                  description: type.description,
                },
                sharedPercentage: type.id !== 3 ? 0 : values.sharedPercentage,
              },
            })
          );
        }

        setSuccesSnackbarVisibility(true);
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    dirty,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
    setFieldTouched,
    submitForm,
    resetForm,
  } = formik;

  const handleUpdateErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSuccessfulUpdateClose = () => {
    setSuccesSnackbarVisibility((previous) => !previous);
  };

  const handleClearEndValidity: React.MouseEventHandler = () => {
    setFieldValue("endValidity", null);
  };

  const {
    loading: isLoadingServiceTypes,
    error: serviceTypesError,
    value: serviceTypes,
  } = useAsync<() => Promise<GetServicesTypesResponse>>(async () =>
    ServicesService.getServicesTypes({})
  );

  React.useEffect(() => {
    if (service) {
      navigate(`/services/${service.data.id}`);

      resetForm({
        values: {
          ...service.data,
          type: JSON.stringify(service.data.type),
          referencedServiceQuantity:
            service.data.referencedServiceQuantity || 2,
        },
      });
    }
  }, [service, navigate, resetForm]);

  React.useEffect(() => {
    if (initialValues) {
      const {
        referencedServiceId,
        referencedServiceQuantity: initialReferencedServiceQuantity,
      } = initialValues;

      setIsPackage(!!initialReferencedServiceQuantity || !!referencedServiceId);
    }
  }, [initialValues]);

  React.useEffect(() => {
    if (values.type) {
      const typeId = JSON.parse(values.type)?.id;

      if (typeId !== 3) {
        setFieldValue("sharedPercentage", 0);
      }
    }
  }, [values.type]);

  React.useEffect(() => {
    if (isPackage === false) {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setFieldValue("referencedServiceId", undefined);
      // eslint-disable-next-line unicorn/no-useless-undefined
      setFieldValue("referencedService", undefined);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (referencedService) {
        setFieldTouched("referencedServiceId");
        setFieldValue("referencedServiceId", referencedService.id);
        setFieldValue("referencedService", referencedService);
        setFieldValue("referencedServiceQuantity", referencedServiceQuantity);
      }
    }
  }, [isPackage, setFieldValue, referencedService]);

  if (isLoadingServiceTypes) {
    return <PageLoader />;
  }

  if (serviceTypesError) {
    return <GenericError />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Nome"
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                type="number"
                label="Prezzo"
                {...getFieldProps("price")}
                error={Boolean(touched.price && errors.price)}
                helperText={touched.price && errors.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 1,
                }}
              />

              <TextField
                fullWidth
                type="number"
                label="Compartecipazione"
                disabled={JSON.parse(values.type || "{}")?.id !== 3}
                {...getFieldProps("sharedPercentage")}
                error={Boolean(
                  touched.sharedPercentage && errors.sharedPercentage
                )}
                helperText={touched.sharedPercentage && errors.sharedPercentage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.01,
                }}
              />
            </Stack>

            <FormControl fullWidth>
              <InputLabel id="service-type-label">
                Tipologia della prestazione
              </InputLabel>
              <Select
                labelId="service-type-label"
                aria-describedby="service-type-label"
                label="Tipologia della prestazione"
                error={Boolean(touched.type && errors.type)}
                {...getFieldProps("type")}
              >
                <MenuItem value="" disabled>
                  Seleziona tipologia prestazione
                </MenuItem>
                {serviceTypes?.data
                  ?.filter((serviceType) => serviceType.id !== 4)
                  .map((serviceType, index) => (
                    <MenuItem
                      value={JSON.stringify(serviceType)}
                      key={`service-type-${serviceType.id}-${index}`}
                    >
                      {serviceType.description}
                    </MenuItem>
                  ))}
              </Select>

              {touched.type && errors.type && (
                <FormHelperText id="service-type-label">
                  Seleziona la tipologia della prestazione
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              fullWidth
              multiline
              minRows={3}
              type="text"
              label="Descrizione"
              {...getFieldProps("description")}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />

            <TextField
              fullWidth
              multiline
              minRows={3}
              type="text"
              label="Descrizione Avanzata"
              {...getFieldProps("advancedDescription")}
              error={Boolean(
                touched.advancedDescription && errors.advancedDescription
              )}
              helperText={
                touched.advancedDescription && errors.advancedDescription
              }
            />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <DesktopDatePicker
                label="Data inizio validità"
                inputFormat="dd/MM/yyyy"
                renderInput={(parameters) => (
                  <TextField
                    {...parameters}
                    fullWidth
                    error={Boolean(
                      touched.startValidity && errors.startValidity
                    )}
                    helperText={touched.startValidity && errors.startValidity}
                  />
                )}
                maxDate={values.endValidity && new Date(values.endValidity)}
                value={values.startValidity}
                onChange={(value) => setFieldValue("startValidity", value)}
              />

              <DesktopDatePicker
                label="Data fine validità"
                inputFormat="dd/MM/yyyy"
                renderInput={(parameters) => (
                  <TextField
                    {...parameters}
                    fullWidth
                    error={Boolean(touched.endValidity && errors.endValidity)}
                    helperText={touched.endValidity && errors.endValidity}
                    InputProps={{
                      ...parameters.InputProps,
                      endAdornment:
                        values.endValidity === null ? (
                          parameters.InputProps?.endAdornment
                        ) : (
                          <Tooltip
                            title="Elimina la data selezionata"
                            enterDelay={500}
                          >
                            <IconButton onClick={handleClearEndValidity}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        ),
                    }}
                  />
                )}
                minDate={
                  values.startValidity
                    ? new Date(values.startValidity)
                    : new Date()
                }
                value={values.endValidity || null}
                onChange={(value) => setFieldValue("endValidity", value)}
              />
            </Stack>

            <Stack direction="row" spacing={2} alignItems="center">
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  label="Questa prestazione è un pacchetto"
                  checked={isPackage}
                  onChange={(event) => {
                    // @ts-ignore
                    setIsPackage(event.currentTarget.checked as boolean);
                  }}
                />
              </FormGroup>

              <ServicesAutocomplete
                label="Seleziona prestazione"
                onServiceSelected={(selectedService) => {
                  if (selectedService) {
                    setReferencedService(selectedService);
                  }
                }}
                disabled={!isPackage}
                value={
                  initialValues?.referencedService ||
                  (referencedService as Service)
                }
              />

              <Tooltip
                enterDelay={750}
                title="Quantità di servizi da includere nel pacchetto"
              >
                <span>
                  <TextField
                    type="number"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[2-9]*",
                      min: 2,
                      style: {
                        textAlign: "center",
                      },
                    }}
                    label="Quantità"
                    disabled={!isPackage}
                    value={referencedServiceQuantity}
                    onChange={({ target: { value } }) => {
                      setReferencedServiceQuantity(+value);
                      setFieldValue("referencedServiceQuantity", +value);
                    }}
                    error={Boolean(
                      touched.referencedServiceQuantity &&
                        errors.referencedServiceQuantity
                    )}
                    helperText={
                      touched.referencedServiceQuantity &&
                      errors.referencedServiceQuantity
                    }
                  />
                </span>
              </Tooltip>
            </Stack>

            <Stack spacing={2} direction="row">
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                disabled={!dirty}
                loading={isSubmitting}
                endIcon={initialValues ? <UpgradeIcon /> : <AddIcon />}
              >
                {initialValues
                  ? "Aggiorna prestazione"
                  : "Crea nuova prestazione"}
              </LoadingButton>

              {initialValues && (
                <LoadingButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  loading={isSubmitting}
                  endIcon={<TodayIcon />}
                  onClick={() => {
                    if (
                      differenceInDays(
                        new Date(values.startValidity!),
                        new Date()
                      ) > 0
                    ) {
                      setFieldValue("startValidity", new Date());
                    }

                    setFieldValue("endValidity", new Date());
                    submitForm();
                  }}
                  color="warning"
                  disabled={
                    values.endValidity
                      ? isSameDay(new Date(values.endValidity), new Date())
                      : false
                  }
                >
                  Rendi la prestazione non più prenotabile
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleUpdateErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleUpdateErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {initialValues
            ? "Errore durante l'aggiornamento della prestazione"
            : "Errore nella creazione della prestazione"}
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSuccessfulUpdateClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSuccessfulUpdateClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {initialValues
            ? "Hai aggiornato correttamente questa prestazione"
            : "Hai creato correttamente questa prestazione"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ServiceForm;
