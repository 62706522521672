import * as Yup from "yup";
import React from "react";
import { useAsync } from "react-use";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import CodiceFiscale from "codice-fiscale-js";
import countries from "i18n-iso-countries";
import MuiPhoneNumber from "material-ui-phone-number";

// material
import {
  Alert,
  Snackbar,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Divider,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { DesktopDatePicker, LoadingButton } from "@mui/lab";

import {
  PostUserBody,
  $PostUserBody,
  UsersService,
} from "../../api/assistenza-domiciliare";
import PageLoader from "../PageLoader";
import GenericError from "../GenericError";

import type { GetUserTypeResponse } from "../../api/assistenza-domiciliare";
import CountriesAutocomplete from "../CountriesAutocomplete";

// ----------------------------------------------------------------------

// eslint-disable-next-line sonarjs/cognitive-complexity
const RegisterNewUserForm: React.FC = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const [isSuccessSnackbarShown, setSuccesSnackbarVisibility] =
    React.useState<boolean>(false);

  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);

  const [userTypes, setUserTypes] = React.useState<GetUserTypeResponse>();

  const [isLoadingUserTypes, setIsLoadingUserTypes] =
    React.useState<boolean>(false);

  const [errorLoadingUserTypes, setErrorLoadingUserTypes] =
    React.useState<boolean>(false);

  const PostUserBodySchema: Yup.SchemaOf<Omit<PostUserBody, "telephone">> =
    Yup.object().shape({
      firstName: Yup.string()
        .min($PostUserBody.properties.firstName.minLength)
        .max($PostUserBody.properties.firstName.maxLength)
        .required("Il nome dell'utente è richiesto"),
      lastName: Yup.string().required("Il cognome dell'utente è richiesto"),
      email: Yup.string()
        .email("L'indirizzo email inserito non è valido")
        .max($PostUserBody.properties.email.maxLength)
        .required("È necessario inserire l'indirizzo email"),
      // eslint-disable-next-line sonarjs/no-duplicate-string
      mobile: Yup.string().required("Campo richiesto"),
      password: Yup.string().required("La password è richiesta"),
      codfis: Yup.string()
        .when("nationality", {
          is: "Italia",
          // eslint-disable-next-line unicorn/no-thenable
          then: (schema) =>
            schema
              .min(16, "Il Codice Fiscale deve contenere almeno 16 caratteri")
              .max(16, "Il Codice Fiscale deve contenere al più 16 caratteri")
              .test("invalid-cf", "Il Codice Fiscale non è valido", (value) => {
                if (!value) {
                  return false;
                }

                return CodiceFiscale.check(value);
              }),
        })
        .required("Il Codice Fiscale è richiesto"),
      address: Yup.string()
        .max($PostUserBody.properties.address.maxLength)
        .required("L'indirizzo dell'utente è richiesto"),
      city: Yup.string()
        .max($PostUserBody.properties.city.maxLength)
        // eslint-disable-next-line sonarjs/no-duplicate-string
        .required("Campo richiesto"),
      province: Yup.string()
        .min($PostUserBody.properties.province.minLength)
        .max($PostUserBody.properties.province.maxLength)
        .required("Campo richiesto"),
      cap: Yup.string()
        .max($PostUserBody.properties.cap.maxLength)
        .required("Campo richiesto"),
      type: Yup.object()
        .shape({
          id: Yup.number().required(),
          description: Yup.string().required(),
        })
        .required("Campo richiesto"),
      cityOfBirth: Yup.string()
        // eslint-disable-next-line sonarjs/no-duplicate-string
        .required("Campo richiesto"),
      provinceOfBirth: Yup.string()
        .min($PostUserBody.properties.provinceOfBirth.minLength)
        .max($PostUserBody.properties.provinceOfBirth.maxLength)
        .required("Campo richiesto"),
      dateOfBirth: Yup.string().required("Campo richiesto"),
      nationality: Yup.string().required("Campo richiesto"),
      sex: Yup.string().oneOf(["M", "F"]).defined().required("Campo richiesto"),
      acceptedFundRaisingAgreement: Yup.number().notRequired().default(0),
      acceptedMarketingAgreement: Yup.number().notRequired().default(0),
    });

  const formik = useFormik<PostUserBody>({
    initialValues: {
      email: "",
      codfis: "",
      password: "",
      address: "",
      city: "",
      province: "",
      cap: "",
      firstName: "",
      lastName: "",
      cityOfBirth: "",
      provinceOfBirth: "",
      dateOfBirth: "",
      nationality: "",
      sex: "",
      mobile: "",
      telephone: "",
      // @ts-ignore
      type: "",
      acceptedFundRaisingAgreement: 0,
      acceptedMarketingAgreement: 0,
    },
    validationSchema: PostUserBodySchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await UsersService.createUser({
          requestBody: {
            ...values,
            type: JSON.parse(values.type as unknown as string),
            nationality: countries.getAlpha2Code(values.nationality, "it"),
            email: values.email.trim(),
            telephone: "",
            acceptedFundRaisingAgreement: 0,
            acceptedMarketingAgreement: 0,
          },
        });

        setSuccesSnackbarVisibility(true);
        navigate(-1);
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    dirty,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched,
    values,
  } = formik;

  const handleLoginErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSuccessfulUpdateClose = () => {
    setSuccesSnackbarVisibility((previous) => !previous);
  };

  useAsync(async () => {
    try {
      setErrorLoadingUserTypes(false);
      setIsLoadingUserTypes(true);
      setUserTypes(await UsersService.getUserType());
    } catch {
      setErrorLoadingUserTypes(true);
    } finally {
      setIsLoadingUserTypes(false);
    }
  }, []);

  React.useEffect(() => {
    if (errors) {
      console.log({ errors });
    }
  }, [errors]);

  if (isLoadingUserTypes) {
    return <PageLoader />;
  }

  if (errorLoadingUserTypes) {
    return <GenericError />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="email"
                label="Indirizzo email"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <MuiPhoneNumber
                defaultCountry={"it"}
                disableAreaCodes
                variant="outlined"
                label="Numero di telefono"
                fullWidth
                value={values.mobile}
                onChange={(value) => setFieldValue("mobile", value)}
                sx={{
                  svg: {
                    maxHeight: "20px",
                    height: "100%",
                  },
                }}
                error={Boolean(touched.mobile && errors.mobile)}
                helperText={touched.mobile && errors.mobile}
              />

              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                label="Password"
                {...getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword((previous) => !previous)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Nome"
                {...getFieldProps("firstName")}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                type="text"
                label="Cognome"
                {...getFieldProps("lastName")}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />

              <FormControl fullWidth>
                <InputLabel id="user-sex-label">Sesso</InputLabel>

                <Select
                  labelId="user-sex-label"
                  aria-describedby="user-sex-label"
                  label="Sesso"
                  error={Boolean(touched.sex && errors.sex)}
                  {...getFieldProps("sex")}
                >
                  <MenuItem value="" disabled>
                    Seleziona Sesso
                  </MenuItem>
                  <MenuItem value={"M"}>Maschio</MenuItem>
                  <MenuItem value={"F"}>Femmina</MenuItem>
                </Select>

                {touched.sex && errors.sex && (
                  <FormHelperText id="user-type-label">
                    Seleziona il sesso
                  </FormHelperText>
                )}
              </FormControl>

              <DesktopDatePicker
                label="Data di nascita"
                inputFormat="dd/MM/yyyy"
                renderInput={(parameters) => (
                  <TextField
                    {...parameters}
                    fullWidth
                    error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                    helperText={touched.dateOfBirth && errors.dateOfBirth}
                  />
                )}
                value={values.dateOfBirth}
                onChange={(value) => setFieldValue("dateOfBirth", value)}
              />
            </Stack>

            <FormControl fullWidth>
              <InputLabel id="user-type-label">
                Tipologia dell'utente
              </InputLabel>
              <Select
                labelId="user-type-label"
                aria-describedby="user-type-label"
                label="Tipologia dell'utente"
                error={Boolean(touched.type && errors.type)}
                {...getFieldProps("type")}
              >
                <MenuItem value="" disabled>
                  Seleziona tipologia utente
                </MenuItem>
                {userTypes?.data?.map((userType, index) => (
                  <MenuItem
                    value={JSON.stringify(userType)}
                    key={`service-type-${userType.id}-${index}`}
                  >
                    {userType.description}
                  </MenuItem>
                ))}
              </Select>

              {touched.type && errors.type && (
                <FormHelperText id="user-type-label">
                  Seleziona la tipologia dell'utente
                </FormHelperText>
              )}
            </FormControl>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <FormControl fullWidth>
                <CountriesAutocomplete
                  id="nationality"
                  value={values.nationality}
                  error={Boolean(touched.nationality && errors.nationality)}
                  onChange={(_, value, reason) => {
                    if (reason === "clear") {
                      setFieldTouched("nationality");
                      setFieldValue("nationality", "");
                    } else {
                      setFieldValue(
                        "nationality",
                        // @ts-ignore
                        value.label
                      );
                    }
                  }}
                />

                {touched.nationality && errors.nationality && (
                  <FormHelperText id="user-nationality-label">
                    Seleziona la nazionalità dell'utente
                  </FormHelperText>
                )}
              </FormControl>

              <TextField
                fullWidth
                type="text"
                label="Codice Fiscale"
                {...getFieldProps("codfis")}
                error={Boolean(touched.codfis && errors.codfis)}
                helperText={touched.codfis && errors.codfis}
                inputProps={{
                  minLength: values.nationality === "Italia" && 16,
                  maxLength: values.nationality === "Italia" && 16,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>

            <Divider />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="address"
                type="text"
                label="Indirizzo"
                {...getFieldProps("address")}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              />

              <TextField
                fullWidth
                type="text"
                label="CAP"
                {...getFieldProps("cap")}
                error={Boolean(touched.cap && errors.cap)}
                helperText={touched.cap && errors.cap}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Città"
                {...getFieldProps("city")}
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
              />

              <TextField
                fullWidth
                type="text"
                label="Provincia"
                {...getFieldProps("province")}
                error={Boolean(touched.province && errors.province)}
                helperText={touched.province && errors.province}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>

            <Divider />

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Città di nascita"
                {...getFieldProps("cityOfBirth")}
                error={Boolean(touched.cityOfBirth && errors.cityOfBirth)}
                helperText={touched.cityOfBirth && errors.cityOfBirth}
              />

              <TextField
                fullWidth
                type="text"
                label="Provincia di nascita"
                {...getFieldProps("provinceOfBirth")}
                error={Boolean(
                  touched.provinceOfBirth && errors.provinceOfBirth
                )}
                helperText={touched.provinceOfBirth && errors.provinceOfBirth}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!dirty}
              loading={isSubmitting}
              endIcon={<PersonAddIcon />}
            >
              Inserisci nuovo utente
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore durante la creazione dell'utente
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSuccessfulUpdateClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSuccessfulUpdateClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          You have succesfully create a new user
        </Alert>
      </Snackbar>
    </>
  );
};

export default RegisterNewUserForm;
