import React from "react";
import countries from "i18n-iso-countries";
import { Autocomplete, TextField } from "@mui/material";

import type { AutocompleteProps } from "@mui/material";

interface ICountriesAutocompleteProperties
  extends Partial<
    AutocompleteProps<
      { isoCode: string; label: string },
      boolean,
      boolean,
      boolean
    >
  > {
  children?: never;
  error?: boolean;
}

// eslint-disable-next-line unicorn/prefer-module
countries.registerLocale(require("i18n-iso-countries/langs/it.json"));

const CountriesAutocomplete: React.FC<ICountriesAutocompleteProperties> = ({
  error,
  ...properties
}) => (
  <Autocomplete
    disablePortal
    options={[
      {
        label: "Seleziona Nazionalità",
        isoCode: "",
      },
      ...Object.entries(countries.getNames("it", { select: "official" })).map(
        ([isoCode, label]) => ({
          isoCode,
          label,
        })
      ),
    ]}
    isOptionEqualToValue={(option, value) =>
      // @ts-ignore
      option.label === value
    }
    // getOptionLabel={(option) => option.label}
    getOptionDisabled={(option) =>
      // @ts-ignore
      option.label === "Seleziona Nazionalità"
    }
    renderInput={(parameters) => (
      <TextField
        {...parameters}
        error={error}
        label="Nazionalità"
        name="nationality"
      />
    )}
    {...properties}
  />
);
export default CountriesAutocomplete;
