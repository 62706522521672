import React from "react";
import { useAsync, useDebounce } from "react-use";
import { Link as RouterLink, Outlet, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";

// material
import {
  Stack,
  Button,
  Container,
  IconButton,
  Tooltip,
  Box,
  InputAdornment,
  Chip,
} from "@mui/material";
// components
import EditIcon from "@mui/icons-material/Edit";

import { GridColDef, DataGrid, itIT } from "@mui/x-data-grid";
import Page from "../../components/Page";

import { UsersService } from "../../api/assistenza-domiciliare";

import type { GetUsersResponse } from "../../api/assistenza-domiciliare";
import { NoRowsOverlay } from "../../components/datagrid/NoRowsOverlay";
import { Footer } from "../../components/datagrid";
import { SearchInput } from "../../components/SearchInput";

const User: React.FC = () => {
  const [users, setUsers] = React.useState<GetUsersResponse>();
  const [loadingUsers, setLoadingUsers] = React.useState<boolean>(false);

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");
  const [, cancelSearchtermDebouncing] = useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [pageSize, setPageSize] = React.useState<number>(10);
  const [page, setPage] = React.useState<number>(0);
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "Actions",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 85,
      renderCell: ({ row }) => {
        const userType = row.type.description.toLowerCase();

        return (
          <>
            <Tooltip enterDelay={750} title="Modifica questo utente">
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                onClick={() => {
                  navigate(`/users/${row.id}`);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title="Crea nuovo ordine per questo utente"
            >
              <span>
                <IconButton
                  color="primary"
                  aria-label="crea ordine per questo utente"
                  onClick={() => {
                    navigate(`/users/${row.id}/add-order`);
                  }}
                  disabled={userType !== "paziente"}
                >
                  <AddShoppingCartIcon />
                </IconButton>
              </span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 70,
      hide: true,
      renderCell: ({ value }) => (
        <Chip
          key={`user-${value}`}
          label={value}
          // color="info"
          variant="outlined"
          sx={{
            maxWidth: 70,
          }}
        />
      ),
    },
    {
      field: "firstNameAndLastName",
      headerName: "Nome",
      width: 200,
      editable: false,
      filterable: true,
      sortable: true,
      valueGetter: ({ row }) =>
        [row.firstName, row.lastName].filter((value) => value).join(" "),
      renderCell: ({ row }) => (
        <Chip
          key={[row.id, row.firstName, row.lastName]
            .filter((value) => value)
            .join("-")}
          label={[row.firstName, row.lastName]
            .filter((value) => value)
            .join(" ")}
          variant="outlined"
          sx={{
            maxWidth: 200,
          }}
        />
      ),
    },
    {
      field: "type",
      headerName: "Tipologia",
      editable: false,
      filterable: true,
      sortable: true,
      valueGetter: ({ value }) => value.description,
      width: 150,
      renderCell: ({ value }) => (
        <Chip
          key={`user-${value}`}
          label={value}
          // color="info"
          variant="outlined"
          sx={{
            maxWidth: 150,
          }}
        />
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      sortable: false,
      renderCell: ({ value }) => (
        <Chip
          key={`user-email-${value}`}
          label={value}
          // color="info"
          variant="outlined"
          sx={{
            maxWidth: 200,
          }}
        />
      ),
    },
    {
      field: "codfis",
      headerName: "Codice Fiscale",
      width: 190,
      sortable: false,
      renderCell: ({ value }) => (
        <Chip
          key={`user-cf-${value}`}
          label={value}
          // color="info"
          variant="outlined"
          sx={{
            maxWidth: 190,
          }}
        />
      ),
    },
    {
      field: "address",
      headerName: "Indirizzo",
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) =>
        `${row?.address}, ${row?.city}, ${row?.province}, ${row?.cap}`,
      renderCell: ({ row }) => (
        <Chip
          key={`user-address-${row?.address}, ${row?.city}, ${row?.province}, ${row?.cap}`}
          label={`${row?.address}, ${row?.city}, ${row?.province}, ${row?.cap}`}
          // color="info"
          variant="outlined"
          sx={{
            maxWidth: 300,
          }}
        />
      ),
    },
  ];

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setSearchTerm(value);
  };

  const handleSearchTermClearing: React.MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault();
    event.stopPropagation();
    cancelSearchtermDebouncing();
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  useAsync(async () => {
    try {
      setLoadingUsers(true);

      setUsers(
        await UsersService.getUsers({
          skip: page * 10,
          take: pageSize,
          firstName: debouncedSearchTerm,
          lastName: debouncedSearchTerm,
        })
      );
    } finally {
      setLoadingUsers(false);
    }
  }, [page, debouncedSearchTerm, pageSize]);

  return (
    <Page title="Utenti">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <SearchInput
            value={searchTerm}
            onChange={handleSearchTerm}
            placeholder="Ricerca per nome utente..."
            startAdornment={
              <InputAdornment position="start">
                <Box sx={{ color: "text.disabled" }}>
                  <SearchIcon />
                </Box>
              </InputAdornment>
            }
            endAdornment={
              searchTerm.length > 0 ? (
                <InputAdornment position="end">
                  <Tooltip enterDelay={500} title="Resetta campo di ricerca">
                    <IconButton
                      aria-label="Resetta campo di ricerca"
                      onClick={handleSearchTermClearing}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null
            }
          />

          <Button
            variant="contained"
            component={RouterLink}
            to="/users/new"
            startIcon={<AddIcon />}
            sx={{
              ml: "auto",
            }}
          >
            Crea nuovo utente
          </Button>
        </Stack>

        <DataGrid
          rows={users?.data || []}
          rowCount={users?.total}
          loading={loadingUsers}
          columns={columns}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          density="comfortable"
          editMode="row"
          autoHeight
          // disableColumnFilter
          // disableColumnMenu
          // disableColumnSelector
          // disableDensitySelector
          // disableSelectionOnClick
          onRowDoubleClick={({ row }) => navigate(`/users/${row.id}`)}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          components={{
            Footer,
            NoRowsOverlay,
          }}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
        />
      </Container>
      <Outlet />
    </Page>
  );
};

export default User;
