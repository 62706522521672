import React from "react";
import { useAsync } from "react-use";
// material
import { Alert, Box, Container, Stack, Typography } from "@mui/material";
// components
import { useParams } from "react-router-dom";
import Page from "../../components/Page";
import GenericError from "../../components/GenericError";
import PageLoader from "../../components/PageLoader";
import RequestDetailsForm from "../../components/forms/RequestDetailsForm";

import { RequestsService } from "../../api/assistenza-domiciliare";

import type { GetRequestResponse } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

const RequestDetails: React.FC = () => {
  const { id } = useParams();

  const [request, setRequest] = React.useState<GetRequestResponse>();
  const [loadingRequest, setLoadingRequest] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  useAsync(async () => {
    try {
      setError(false);
      setLoadingRequest(true);
      setRequest(
        await RequestsService.getRequest({
          id: +id!,
        })
      );
    } catch {
      setError(true);
    } finally {
      setLoadingRequest(false);
    }
  }, [id]);

  if (loadingRequest) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <Page title="Dettagli Richiesta" showBackButton>
      <Container>
        <Box sx={{ pb: 5 }}>
          <Stack direction="column" gap={2}>
            {(request?.data.order?.status.id === 1 ||
              request?.data.order?.status.id === 9) && (
              <Alert severity="error">
                <Typography>
                  La prestazione corrente non risulta ancora pagata.
                </Typography>
              </Alert>
            )}
            <RequestDetailsForm initialValues={request?.data} />
          </Stack>
        </Box>
      </Container>
    </Page>
  );
};

export default RequestDetails;
