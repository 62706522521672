import * as Yup from "yup";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordIcon from "@mui/icons-material/Password";

// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  PostResetPasswordBody,
  AuthService,
} from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

interface IRecoverPasswordFormProperties {
  token: PostResetPasswordBody["token"];
}

const RecoverPasswordForm: React.FC<IRecoverPasswordFormProperties> = ({
  token,
}) => {
  const navigate = useNavigate();

  const [, setError] = React.useState<boolean>(false);

  const [isResetPasswordErrorVisible, setResetPasswordErrorVisibility] =
    React.useState<boolean>(false);

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);

  const ResetPasswordForm = Yup.object().shape({
    password: Yup.string().required("Inserisci la nuova password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Le password devono coincidere")
      .required("Devi necessariamente confermare la password"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordForm,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setError(false);
        await AuthService.resetPassword({
          requestBody: {
            ...values,
            token,
          },
        });
        navigate("/auth/login", {
          state: {
            hasRecoveredPassword: true,
          },
        });
      } catch {
        setError(true);
        setResetPasswordErrorVisibility(true);
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleLoginErrorClose = () => {
    setResetPasswordErrorVisibility((previous) => !previous);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="new-password"
              type={showPassword ? "text" : "password"}
              label="Nuova Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              label="Conferma Nuova Password"
              disabled={
                values.password.length === 0 || Boolean(errors.password)
              }
              {...getFieldProps("confirmPassword")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleShowConfirmPassword}
                      edge="end"
                      disabled={
                        values.password.length === 0 || Boolean(errors.password)
                      }
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              endIcon={<PasswordIcon />}
            >
              Imposta nuova password
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isResetPasswordErrorVisible}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Impossibile resettare password
        </Alert>
      </Snackbar>
    </>
  );
};

export default RecoverPasswordForm;
