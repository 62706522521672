import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Link, Grid, Typography, Alert, Stack } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import { MHidden } from "../../components/@material_extend";
import Page from "../../components/Page";
import { RegisterForm } from "../../components/authentication/register";
import RegisterImage from "../../assets/register.svg";
import RecaptchaWrapper from "../../components/RecaptchaWrapper";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Register: React.FC = () => {
  const { state } = useLocation();

  return (
    <RecaptchaWrapper>
      <RootStyle title="Registrazione" hidePageTitle>
        <AuthLayout>
          Ti sei già registrato? &nbsp;
          <Link
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            to="/auth/login"
          >
            Esegui l'accesso
          </Link>
        </AuthLayout>

        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          justifyContent="space-between"
        >
          <MHidden width="mdDown">
            <Grid
              item
              xs={false}
              sm={4}
              md={5}
              sx={{
                backgroundImage: `url(${RegisterImage})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            />
          </MHidden>

          <ContentStyle>
            <Box sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Iniziamo!
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                Registrati ad Assistenza Domiciliare
              </Typography>
            </Box>

            <Stack spacing={4}>
              {state?.accountActivionFailure && (
                <Alert severity="error">{state?.errorMessage}</Alert>
              )}

              <RegisterForm />
            </Stack>

            <Typography
              variant="body2"
              align="center"
              sx={{ color: "text.secondary", mt: 3 }}
            >
              Registrandoti confermi di accettare i &nbsp;
              <Link
                underline="always"
                sx={{ color: "text.primary" }}
                component={RouterLink}
                to="/privacy-policy"
              >
                Termini di servizio
              </Link>
              &nbsp;e la&nbsp;
              <Link
                underline="always"
                sx={{ color: "text.primary" }}
                component={RouterLink}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Hai già un account? &nbsp;
              <Link to="/auth/login" component={RouterLink}>
                Esegui il login
              </Link>
            </Typography>
          </ContentStyle>
        </Grid>
      </RootStyle>
    </RecaptchaWrapper>
  );
};

export default Register;
