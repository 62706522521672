import React from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Stack,
  Link,
  Container,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
// layouts
import { useToggle } from "react-use";
import { LoadingButton } from "@mui/lab";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SendIcon from "@mui/icons-material/Send";
import ReplayIcon from "@mui/icons-material/Replay";
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material_extend";
import RecoverPasswordImage from "../../assets/recover-password.svg";
import { AuthService } from "../../api/assistenza-domiciliare";
import EmailSentImage from "../../assets/email_sent.svg";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const ResendActivationAccountLink: React.FC = () => {
  const [email, setEmail] = React.useState<string>("");

  const [isSendingActivationEmail, toggleSendingActivationEmail] =
    useToggle(false);
  const [isActivationEmailSent, toggleActivationEmailSent] = useToggle(false);
  const [isErrorVisible, toggleErrorVisibility] = useToggle(false);

  const sendActivationEmail = async () => {
    try {
      toggleSendingActivationEmail(true);

      await AuthService.askAccountActivation({
        requestBody: {
          email,
        },
      });

      toggleActivationEmailSent(true);
    } catch {
      toggleErrorVisibility(true);
    } finally {
      toggleSendingActivationEmail(false);
    }
  };

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    currentTarget: { value },
  }) => {
    setEmail(value);
  };

  return (
    <RootStyle title="Attivazione Account" hidePageTitle>
      <AuthLayout>
        Non hai un account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/auth/register"
        >
          Inizia da qui
        </Link>
      </AuthLayout>

      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        justifyContent="space-between"
      >
        <MHidden width="mdDown">
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundImage: `url(${RecoverPasswordImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </MHidden>

        {isActivationEmailSent ? (
          <Container>
            <ContentStyle>
              <Stack
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={4}
              >
                <img
                  src={EmailSentImage}
                  alt=""
                  style={{
                    maxWidth: "200px",
                  }}
                />

                <Typography variant="h5" textAlign="center">
                  A breve riceverai una mail contenente un link per resettare la
                  tua password!
                </Typography>

                <Stack
                  direction="column"
                  justifyContent="space-evenly"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography textAlign="center" color="text.secondary">
                    Se stai ancora aspettando puoi richiedere l'invio di una
                    nuova mail!
                  </Typography>

                  <LoadingButton
                    variant="contained"
                    endIcon={<ReplayIcon />}
                    loading={isSendingActivationEmail}
                    size="large"
                    sx={{
                      alignSelf: "center",
                      textTransform: "none",
                    }}
                    onClick={sendActivationEmail}
                  >
                    Invia nuovamente email
                  </LoadingButton>
                </Stack>
              </Stack>
            </ContentStyle>
          </Container>
        ) : (
          <Grid item xs={12} md={6} component={Container}>
            <ContentStyle>
              <Stack sx={{ mb: 5 }}>
                <Typography variant="h4" gutterBottom>
                  Invia nuovamente la mail di attivazione dell'account
                  Assistenza Domiciliare
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Inserisci i dettagli richiesti
                </Typography>
              </Stack>

              <Stack spacing={3}>
                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="Email"
                  disabled={isSendingActivationEmail}
                  onChange={handleEmailChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  variant="contained"
                  loading={isSendingActivationEmail}
                  onClick={sendActivationEmail}
                  endIcon={<SendIcon />}
                >
                  Invia email di attivazione
                </LoadingButton>
              </Stack>

              <MHidden width="smUp">
                <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                  Non hai un account?&nbsp;
                  <Link
                    variant="subtitle2"
                    component={RouterLink}
                    to="/register"
                  >
                    Inizia da qui
                  </Link>
                </Typography>
              </MHidden>
            </ContentStyle>
          </Grid>
        )}
      </Grid>

      <Snackbar
        open={isErrorVisible}
        autoHideDuration={6000}
        onClose={toggleErrorVisibility}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={toggleErrorVisibility}
          severity="error"
          sx={{ width: "100%" }}
        >
          Questa email non è associata ad alcun account
        </Alert>
      </Snackbar>
    </RootStyle>
  );
};

export default ResendActivationAccountLink;
