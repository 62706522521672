import React from "react";
import { useAsync, useDebounce } from "react-use";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { User, UsersService } from "../api/assistenza-domiciliare";

import type { GetUsersResponse } from "../api/assistenza-domiciliare";

interface IMedicAutocompleteProperties {
  label: string;
  onDoctorSelected: (doctor?: User | null) => void;
  value?: User | null;
  isMedic: boolean;
  disabled?: boolean;
}

const MedicAutocomplete: React.FC<IMedicAutocompleteProperties> = ({
  label,
  onDoctorSelected,
  value: initialValue,
  isMedic,
  disabled = false,
}) => {
  const [value, setValue] = React.useState<User | null | undefined>(
    initialValue
  );
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    React.useState<string>("");

  useDebounce(
    () => {
      setDebouncedSearchTerm(searchTerm);
    },
    750,
    [searchTerm]
  );

  const [users, setUsers] = React.useState<GetUsersResponse>();
  const [loadingUsers, setLoadingUsers] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const handleSearchTerm: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value: currentSearchTermValue },
  }) => {
    setSearchTerm(currentSearchTermValue);
  };

  useAsync(async () => {
    try {
      setLoadingUsers(true);
      setUsers(
        await UsersService.getUsers({
          typeId: isMedic ? 4 : 3,
          firstName: debouncedSearchTerm,
          lastName: debouncedSearchTerm,
        })
      );
    } finally {
      setLoadingUsers(false);
    }
  }, [debouncedSearchTerm, isMedic]);

  return (
    <Autocomplete
      disabled={disabled}
      autoComplete
      open={open}
      onOpen={async () => {
        setOpen(true);
        setUsers(
          await UsersService.getUsers({
            typeId: isMedic ? 4 : 3,
            firstName: debouncedSearchTerm,
            lastName: debouncedSearchTerm,
          })
        );
      }}
      onClose={() => {
        setOpen(false);
      }}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      isOptionEqualToValue={(option, value) =>
        `${option.firstName} ${option.lastName}` ===
        `${value.firstName} ${value.lastName}`
      }
      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
      options={users?.data || []}
      loading={loadingUsers}
      renderInput={(parameters) => (
        <TextField
          {...parameters}
          label={label}
          InputProps={{
            ...parameters.InputProps,
            endAdornment: (
              <>
                {loadingUsers ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {parameters.InputProps.endAdornment}
              </>
            ),
          }}
          value={searchTerm}
          onChange={handleSearchTerm}
        />
      )}
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onChange={(_, value) => {
        setValue(value);
        onDoctorSelected(value);
      }}
      value={value}
      filterOptions={(x) => x}
      sx={{
        display: "flex",
        width: "100%",
      }}
      size="small"
    />
  );
};

export default MedicAutocomplete;
