import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Axios from "axios";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { it } from "date-fns/locale";
import { store } from "./app/store";
import * as serviceWorker from "./serviceWorker";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import { OpenAPI } from "./api/assistenza-domiciliare";

OpenAPI.BASE = process.env.REACT_APP_API_ENDPOINT!;

Axios.interceptors.request.use(
  (config) => {
    const email = sessionStorage.getItem("email");
    const password = sessionStorage.getItem("password");

    if (email && password) {
      // eslint-disable-next-line no-param-reassign
      config.auth = {
        username: email,
        password,
      };
    }

    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />

        <HelmetProvider>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistStore(store)}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
                <Router />
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </ThemeConfig>
    </BrowserRouter>
  </React.StrictMode>,
  document.querySelector("#root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
