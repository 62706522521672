import React from "react";
import { useAsync, useToggle } from "react-use";
// material
import {
  Alert,
  Backdrop,
  Container,
  Fab,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Zoom,
} from "@mui/material";
// components
import { useParams, useLocation } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingButton } from "@mui/lab";
import saveAs from "file-saver";
import Page from "../../components/Page";
import GenericError from "../../components/GenericError";
import OrderDetailsForm from "../../components/forms/OrderDetailsForm";
import PageLoader from "../../components/PageLoader";
import { OrdersService } from "../../api/assistenza-domiciliare";
import type { GetOrderResponse } from "../../api/assistenza-domiciliare";
import { b64toBlob } from "../../utils/b64toBlob";

// ----------------------------------------------------------------------

const OrderDetails: React.FC = () => {
  const [isSpeedDialVisible, toggleSpeedDialVisibility] = useToggle(false);
  const { id } = useParams();
  const { state } = useLocation();

  const [order, setOrder] = React.useState<GetOrderResponse>();
  const [loadingOrder, setLoadingOrder] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [isDownloadingInvoice, setIsDownloadingInvoice] =
    React.useState<boolean>();

  const handelDownloadInvoice = async () => {
    try {
      if (order?.data.orderBill) {
        setIsDownloadingInvoice(true);

        const blob = await b64toBlob(order.data.orderBill, "application/pdf");

        saveAs(
          blob,
          `fattura-ordine-#${order.data.id.toString().padStart(7, "0")}.pdf`
        );
      }
    } finally {
      setIsDownloadingInvoice(false);
    }
  };

  const handelDownloadRefundInvoice = async () => {
    try {
      if (order?.data.orderRefundBill) {
        setIsDownloadingInvoice(true);

        const blob = await b64toBlob(
          order.data.orderRefundBill,
          "application/pdf"
        );

        saveAs(
          blob,
          `nota-credito-ordine-#${order.data.id
            .toString()
            .padStart(7, "0")}.pdf`
        );
      }
    } finally {
      setIsDownloadingInvoice(false);
    }
  };

  useAsync(async () => {
    try {
      setError(false);
      setLoadingOrder(true);

      setOrder(await OrdersService.getOrder({ id: +id! }));
    } catch {
      setError(true);
    } finally {
      setLoadingOrder(false);
    }
  }, [id]);

  if (loadingOrder) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <Page
      title={[
        "Dettaglio ordine",
        id ? `#${id.toString().padStart(7, "0")}` : undefined,
      ]
        .filter((value) => value)
        .join(" ")}
      showBackButton
    >
      <Backdrop
        open={isSpeedDialVisible}
        sx={{
          zIndex: 1100,
        }}
      />

      <Container>
        <Stack sx={{ pb: 5 }} spacing={4}>
          {state?.payed === true && (
            <Alert severity="success">Ordine creato correttamente!</Alert>
          )}

          {state?.payed === false && (
            <Alert severity="warning">
              Il pagamento dell'ordine non è andato a buon fine
            </Alert>
          )}

          <OrderDetailsForm initialValues={order?.data} />

          <Zoom
            in={!!order?.data.orderBill}
            style={{
              transitionDelay: `375ms`,
            }}
            unmountOnExit
          >
            {order?.data.orderRefundBill ? (
              <SpeedDial
                ariaLabel="Download files"
                sx={{ position: "fixed", bottom: 20, right: 20 }}
                icon={<FileDownloadIcon />}
                onClose={toggleSpeedDialVisibility}
                onOpen={toggleSpeedDialVisibility}
                open={isSpeedDialVisible}
              >
                <SpeedDialAction
                  icon={<ReceiptIcon />}
                  tooltipTitle="Fattura"
                  tooltipOpen
                  onClick={() => {
                    handelDownloadInvoice();
                    toggleSpeedDialVisibility();
                  }}
                />

                <SpeedDialAction
                  icon={<ReceiptLongIcon />}
                  tooltipTitle="Nota di credito"
                  tooltipOpen
                  onClick={() => {
                    handelDownloadRefundInvoice();
                    toggleSpeedDialVisibility();
                  }}
                />
              </SpeedDial>
            ) : (
              <Fab
                variant="extended"
                onClick={handelDownloadInvoice}
                sx={{
                  position: "fixed",
                  bottom: "20px",
                  right: "20px",
                }}
                component={LoadingButton}
                loading={isDownloadingInvoice}
                color="primary"
              >
                <ReceiptIcon sx={{ mr: 1 }} />
                Scarica fattura
              </Fab>
            )}
          </Zoom>
        </Stack>
      </Container>
    </Page>
  );
};

export default OrderDetails;
