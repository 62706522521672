import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { Service } from "../../api/assistenza-domiciliare";
import { RootState } from "../../app/store";

export interface CartState {
  services: (Service & { quantity: number })[];
}

const initialState: CartState = {
  services: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addService: (state, action: PayloadAction<Service>) => {
      const actualServices = current(state).services;
      const serviceIndex = actualServices.findIndex(
        (service) => service.id === action.payload.id
      );

      if (serviceIndex !== -1) {
        // eslint-disable-next-line no-param-reassign
        if (action.payload.name !== "ADINF2371") {
          state.services[serviceIndex].quantity += 1;
        }
      } else {
        state.services.push({ ...action.payload, quantity: 1 });
      }
    },
    removeService: (state, action: PayloadAction<Service>) => {
      const actualServices = current(state).services;

      const serviceIndex = actualServices.findIndex(
        (service) => service.id === action.payload.id
      );

      if (serviceIndex !== -1) {
        if (state.services[serviceIndex].quantity > 1) {
          // eslint-disable-next-line no-param-reassign
          state.services[serviceIndex].quantity -= 1;
        } else {
          state.services.splice(serviceIndex, 1);

          if (action.payload.type.id === 4) {
            const differentSamplesCount = actualServices.filter(
              (service) => service.type.id === 4
            ).length;

            if (differentSamplesCount <= 2) {
              const sampleAtHomeIndex = state.services.findIndex(
                (service) => service.name === "ADINF2371"
              );

              state.services.splice(sampleAtHomeIndex, 1);
            }
          }
        }
      }
    },
    clearCart: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.services = [];
    },
  },
});

export const { addService, removeService, clearCart } = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart;

export const selectServicesInCart = (state: RootState) => state.cart.services;

export const selectCartLength = (state: RootState) =>
  state.cart.services
    .map(({ quantity }) => quantity)
    .reduce((previousQty, currentQty) => currentQty + previousQty, 0);

export const selectTotalPrice = (state: RootState) =>
  // eslint-disable-next-line unicorn/no-array-reduce
  state.cart.services.reduce((previousService, currentService) => {
    if (currentService) {
      const { price, quantity } = currentService;

      return (price || 0) * quantity + previousService;
    }

    return previousService;
  }, 0);

export default cartSlice.reducer;
