import React, { useState } from "react";
// material
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { DataGrid, GridColDef, itIT } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useAsync } from "react-use";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addService,
  clearCart,
  removeService,
  selectCartLength,
  selectServicesInCart,
} from "../../features/cart/cartSlice";
import { selectUser, selectUserType } from "../../features/user/userSlice";

// components
import Page from "../../components/Page";

import EmptyCartImage from "../../assets/empty_cart.svg";
import removeNullOrUndefined from "../../utils/removeNullOrUndefined";

import { OrdersService } from "../../api/assistenza-domiciliare";

import type { PostOrderResponse } from "../../api/assistenza-domiciliare";
import { Footer } from "../../components/datagrid";
import { NoRowsOverlay } from "../../components/datagrid/NoRowsOverlay";

// ----------------------------------------------------------------------

const EmptyCart: React.FC = () => (
  <Stack
    direction="column"
    justifyContent="space-evenly"
    alignItems="center"
    spacing={4}
  >
    <img
      src={EmptyCartImage}
      alt=""
      style={{
        maxWidth: "30vw",
      }}
    />

    <Typography variant="h5">
      Non hai aggiunto alcuna prestazione al carrello!
    </Typography>

    <Button
      component={Link}
      to="/services"
      variant="contained"
      endIcon={<AddIcon />}
      size="large"
      sx={{
        alignSelf: "center",
        textTransform: "none",
      }}
    >
      Aggiungi prestazioni al carrello
    </Button>
  </Stack>
);

const Cart: React.FC = () => {
  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    useState<boolean>(false);

  const [cartAmount, setCartAmount] = React.useState<number>();
  const [isCreatedOrderLoading, setIsCreatedOrderLoading] =
    React.useState<boolean>(false);

  const [additionalDetails, setAdditionalDetails] = React.useState<string>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const servicesInCart = useAppSelector(selectServicesInCart);
  const cartLength = useAppSelector(selectCartLength);

  const [pageSize, setPageSize] = React.useState<number>(10);

  const [createdOrder, setCreatedOrder] = React.useState<PostOrderResponse>();
  const [errorCreatingOrder, setErrorCreatingOrder] =
    React.useState<boolean>(false);

  const user = useAppSelector(selectUser);
  const userType = useAppSelector(selectUserType);

  const columns: GridColDef[] = [
    {
      field: "Cart",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 165,
      renderCell: ({ row }) => {
        const serviceQtyAdded =
          servicesInCart.find(({ id }) => id === row.id)?.quantity || 1;

        return (
          <>
            <Tooltip
              enterDelay={750}
              title={"Rimuovi questa prestazione dal carrello"}
            >
              <span>
                <IconButton
                  color="primary"
                  aria-label="remove from shopping cart"
                  onClick={() => {
                    dispatch(removeService(row));
                  }}
                  disabled={serviceQtyAdded === 0 || row.name === "ADINF2371"}
                >
                  <RemoveIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title={
                serviceQtyAdded === 0
                  ? "Non hai aggiunto questa presazione al carrello"
                  : `Hai aggiunto questa prestazione ${serviceQtyAdded} volte al carrello`
              }
            >
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 0,
                    style: {
                      textAlign: "center",
                      width: 25,
                      paddingRight: 5,
                      paddingLeft: 5,
                    },
                  }}
                  size="small"
                  value={serviceQtyAdded}
                  disabled
                />
              </Box>
            </Tooltip>

            <Tooltip
              enterDelay={750}
              title={"Aggiungi questa prestazione al carrello"}
            >
              <IconButton
                color="primary"
                aria-label="add to shopping cart"
                disabled={row.name === "ADINF2371"}
                onClick={() => {
                  dispatch(addService(row));
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      hide: userType === "amministratore" || userType === "amministrativo",
    },
    { field: "id", headerName: "ID", width: 70, hide: true },
    {
      field: "name",
      headerName: "Nome",
      width: 95,
      sortable: false,
      hide: userType === "paziente",
    },
    {
      field: "description",
      headerName: "Descrizione",
      flex: 1,
      editable: false,
      filterable: true,
      sortable: false,
      valueGetter: ({ row }) => row.advancedDescription || row.description,
    },
    {
      field: "price",
      headerName: "Prezzo",
      type: "number",
      width: 90,
      sortable: false,
      valueGetter: ({ value, row }) => {
        const serviceQtyAdded =
          servicesInCart.find(({ id }) => id === row.id)?.quantity || 1;

        return new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(value * serviceQtyAdded);
      },
    },
  ];

  const handleDetailsChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = ({ currentTarget: { value } }) => setAdditionalDetails(value);

  const handleSnackbarErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleError = async () => {
    try {
      setErrorCreatingOrder(false);
      setCreatedOrder(
        await OrdersService.createOrder({
          requestBody: {
            services: removeNullOrUndefined(servicesInCart),
            note: additionalDetails,
            user,
          },
        })
      );
    } catch {
      setErrorCreatingOrder(true);
    }
  };

  useAsync(async () => {
    if (cartLength > 0) {
      const { data } = await OrdersService.calculateTotal({
        requestBody: {
          services: removeNullOrUndefined(servicesInCart),
        },
      });

      setCartAmount(data.totalPrice);
    }
  }, [cartLength, servicesInCart]);

  React.useEffect(() => {
    if (createdOrder) {
      dispatch(clearCart());
      navigate(`/orders/${createdOrder.data[0].id}`, {});
    }
  }, [createdOrder, dispatch, navigate]);

  React.useEffect(() => {
    if (errorCreatingOrder) {
      setErrorSnackbarVisibility(true);
    }
  }, [errorCreatingOrder]);

  return (
    <Page title="Il tuo carrello">
      <Container>
        <Box sx={{ pb: 5 }}>
          {cartLength > 0 && (
            <Stack direction="column" justifyContent="space-evenly" spacing={2}>
              <DataGrid
                rows={[...new Set(servicesInCart)]}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                density="comfortable"
                editMode="row"
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                components={{
                  Footer,
                  NoRowsOverlay,
                }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
              />

              <TextField
                label="Informazioni aggiuntive"
                helperText="Puoi aggiungere informazioni aggiuntive, richieste particolari etc."
                minRows={3}
                multiline
                onChange={handleDetailsChange}
              />

              <LoadingButton
                size="large"
                variant="contained"
                loading={isCreatedOrderLoading}
                disabled={isCreatedOrderLoading}
                sx={{
                  alignSelf: "center",
                  textTransform: "none",
                  width: "30vw",
                }}
                onClick={async () => {
                  setIsCreatedOrderLoading(true);
                  const createOrderResponse = await OrdersService.createOrder({
                    requestBody: {
                      services: removeNullOrUndefined(servicesInCart),
                      note: additionalDetails,
                      user,
                    },
                  });

                  setIsCreatedOrderLoading(false);

                  setCreatedOrder(createOrderResponse);
                }}
              >
                Conferma ordine
              </LoadingButton>
            </Stack>
          )}

          {cartLength === 0 && <EmptyCart />}
        </Box>
      </Container>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSnackbarErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSnackbarErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Impossibile processare il pagamento
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default Cart;
