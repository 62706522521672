/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetServiceResponse } from '../models/GetServiceResponse';
import type { GetServicesResponse } from '../models/GetServicesResponse';
import type { GetServicesTypesResponse } from '../models/GetServicesTypesResponse';
import type { GetServiceTypeResponse } from '../models/GetServiceTypeResponse';
import type { PatchServiceBody } from '../models/PatchServiceBody';
import type { PatchServiceResponse } from '../models/PatchServiceResponse';
import type { PostServiceBody } from '../models/PostServiceBody';
import type { PostServiceResponse } from '../models/PostServiceResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class ServicesService {

    /**
     * @returns GetServicesResponse Default Response
     * @throws ApiError
     */
    public static getServices({
startValidity,
endValidity,
description,
advancedDescription,
name,
skip,
take,
sort,
orderby,
}: {
startValidity?: string,
endValidity?: string,
description?: string,
advancedDescription?: string,
name?: string,
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetServicesResponse> {
        return __request({
            method: 'GET',
            path: `/services/`,
            query: {
                'startValidity': startValidity,
                'endValidity': endValidity,
                'description': description,
                'advancedDescription': advancedDescription,
                'name': name,
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns PostServiceResponse Default Response
     * @throws ApiError
     */
    public static createService({
requestBody,
}: {
requestBody?: PostServiceBody,
}): CancelablePromise<PostServiceResponse> {
        return __request({
            method: 'POST',
            path: `/services/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetServicesResponse Default Response
     * @throws ApiError
     */
    public static getServices1({
startValidity,
endValidity,
description,
advancedDescription,
name,
skip,
take,
sort,
orderby,
}: {
startValidity?: string,
endValidity?: string,
description?: string,
advancedDescription?: string,
name?: string,
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetServicesResponse> {
        return __request({
            method: 'GET',
            path: `/services/samples`,
            query: {
                'startValidity': startValidity,
                'endValidity': endValidity,
                'description': description,
                'advancedDescription': advancedDescription,
                'name': name,
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns GetServicesResponse Default Response
     * @throws ApiError
     */
    public static getServicesWithSample({
startValidity,
endValidity,
description,
advancedDescription,
name,
skip,
take,
sort,
orderby,
}: {
startValidity?: string,
endValidity?: string,
description?: string,
advancedDescription?: string,
name?: string,
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetServicesResponse> {
        return __request({
            method: 'GET',
            path: `/services/services-with-samples`,
            query: {
                'startValidity': startValidity,
                'endValidity': endValidity,
                'description': description,
                'advancedDescription': advancedDescription,
                'name': name,
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns GetServiceResponse Default Response
     * @throws ApiError
     */
    public static getService({
id,
}: {
id: number,
}): CancelablePromise<GetServiceResponse> {
        return __request({
            method: 'GET',
            path: `/services/${id}`,
        });
    }

    /**
     * @returns PatchServiceResponse Default Response
     * @throws ApiError
     */
    public static patchService({
id,
requestBody,
}: {
id: number,
requestBody?: PatchServiceBody,
}): CancelablePromise<PatchServiceResponse> {
        return __request({
            method: 'PATCH',
            path: `/services/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetServicesTypesResponse Default Response
     * @throws ApiError
     */
    public static getServicesTypes({
skip,
take,
sort,
orderby,
}: {
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetServicesTypesResponse> {
        return __request({
            method: 'GET',
            path: `/servicesTypes/`,
            query: {
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns GetServiceTypeResponse Default Response
     * @throws ApiError
     */
    public static getServiceType({
id,
}: {
id: number,
}): CancelablePromise<GetServiceTypeResponse> {
        return __request({
            method: 'GET',
            path: `/servicesTypes/${id}`,
        });
    }

}