import React from "react";
import { useAsync } from "react-use";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Stack, Link, Container, Typography, Grid } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material_extend";
import RecoverPasswordImage from "../../assets/recover-password.svg";
import { useQueryParameters } from "../../hooks/useQueryParameters";
import PageLoader from "../../components/PageLoader";
import { AuthService } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const AccountActivation: React.FC = () => {
  const queryParameters = useQueryParameters();
  const token = queryParameters.get("token");
  const navigate = useNavigate();

  const { loading } = useAsync(async () => {
    if (token) {
      try {
        const account = await AuthService.confirmAccount({
          requestBody: {
            token,
          },
        });

        navigate("/auth/login", {
          state: {
            hasActivatedAccount: true,
            accountActivated: account,
          },
        });
      } catch (error) {
        if (error) {
          // @ts-ignore
          if (error.message.includes("401")) {
            navigate("/auth/register", {
              state: {
                accountActivionFailure: true,
                errorMessage:
                  "Nessuna utenza trovata. Effettua nuovamente la registrazione",
              },
            });
          } else {
            navigate("/auth/login", {
              state: {
                hasActivatedAccount: true,
              },
            });
          }
        }
      }
    }
  }, [token]);

  return (
    <RootStyle title="Attivazione account" hidePageTitle>
      <AuthLayout>
        Non hai un account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/auth/register"
        >
          Inizia da qui
        </Link>
      </AuthLayout>

      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        justifyContent="space-between"
      >
        <MHidden width="mdDown">
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundImage: `url(${RecoverPasswordImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </MHidden>

        <Grid item xs={12} md={6} component={Container}>
          <ContentStyle>
            {loading && (
              <>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant="h4" gutterBottom>
                    Attivazione dell'account in corso...
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Per favore, attendi
                  </Typography>
                </Stack>

                <PageLoader />
              </>
            )}

            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Non hai un account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Inizia da qui
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default AccountActivation;
