// material
import React from "react";
import { Box, BoxProps } from "@mui/material";
import LogoImage from "../assets/logo_colore.png";

const Logo: React.FC<BoxProps> = ({ sx }) => (
  <Box component="img" src={LogoImage} sx={{ ...sx }} />
);

export default Logo;
