// ----------------------------------------------------------------------

const DataGrid = (theme: any) => ({
  MuiDataGrid: {
    styleOverrides: {
      root: {
        boxShadow: theme.customShadows.z8,
        borderRadius: 16,
        minHeight: 450,
      },
    },
  },
});

export default DataGrid;
