import axios from "axios";

// eslint-disable-next-line import/prefer-default-export
export const b64toBlob = async (
  base64: string,
  type = "application/octet-stream"
): Promise<Blob> => {
  const { data } = await axios.get<Blob>(`data:${type};base64,${base64}`, {
    responseType: "blob",
    timeout: 25_000,
  });

  return data;
};
