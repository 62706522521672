// import type { TypedAddListener } from "@reduxjs/toolkit";
import { createListenerMiddleware } from "@reduxjs/toolkit";
import { ServicesService } from "../../api/assistenza-domiciliare";
import type { AppStartListening, RootState } from "../../app/store";
import { addService } from "./cartSlice";

export const cartSliceListenerMiddleware = createListenerMiddleware();

cartSliceListenerMiddleware.startListening({
  actionCreator: addService,
  effect: async (action, listenerApi) => {
    const { payload } = action;
    const servicesInCart = (listenerApi.getState() as RootState).cart.services;

    if (
      payload.type.id === 4 &&
      !servicesInCart.some(
        (serviceInCart) => serviceInCart.name === "ADINF2371"
      )
    ) {
      const { data: baseSampleServicesPrice } =
        await ServicesService.getServices1({
          name: "ADINF2371",
        });

      listenerApi.dispatch(addService(baseSampleServicesPrice[0]));
    }

    // Can cancel other running instances
    listenerApi.cancelActiveListeners();
  },
});

export const startAppListening =
  cartSliceListenerMiddleware.startListening as AppStartListening;
