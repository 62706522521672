import React from "react";
// material
import { Alert, Box, Container, Stack } from "@mui/material";
import RegisterNewUserForm from "../../components/forms/RegisterNewUserForm";
// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

const UserDetails: React.FC = () => (
  <Page title="Registra Nuovo Utente" showBackButton>
    <Container>
      <Stack spacing={2}>
        <Alert severity="info">
          Inserisci i dati della persona che usufruirà della prestazione.
        </Alert>

        <Box sx={{ pb: 5 }}>
          <RegisterNewUserForm />
        </Box>
      </Stack>
    </Container>
  </Page>
);

export default UserDetails;
