/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PostRegisterBody = {
    properties: {
        firstName: {
    type: 'string',
    isRequired: true,
    maxLength: 255,
    minLength: 2,
},
        lastName: {
    type: 'string',
    isRequired: true,
    maxLength: 255,
    minLength: 2,
},
        email: {
    type: 'string',
    isRequired: true,
    format: 'email',
    maxLength: 255,
},
        password: {
    type: 'string',
    isRequired: true,
    maxLength: 32,
},
        address: {
    type: 'string',
    isRequired: true,
    maxLength: 256,
},
        city: {
    type: 'string',
    isRequired: true,
    maxLength: 45,
},
        cap: {
    type: 'string',
    isRequired: true,
    maxLength: 6,
},
        codfis: {
    type: 'string',
    isRequired: true,
},
        province: {
    type: 'string',
    isRequired: true,
    maxLength: 2,
    minLength: 2,
},
        provinceOfBirth: {
    type: 'string',
    isRequired: true,
    maxLength: 2,
    minLength: 2,
},
        cityOfBirth: {
    type: 'string',
    isRequired: true,
},
        dateOfBirth: {
    type: 'one-of',
    contains: [{
    type: 'string',
    format: 'date',
}, {
    type: 'string',
    format: 'date-time',
}],
    isRequired: true,
},
        mobile: {
    type: 'string',
    isRequired: true,
    minLength: 1,
},
        telephone: {
    type: 'string',
    isNullable: true,
    maxLength: 18,
},
        nationality: {
    type: 'string',
    isRequired: true,
    maxLength: 2,
    minLength: 2,
},
        sex: {
    type: 'string',
    isRequired: true,
    maxLength: 1,
},
        recaptchaResponse: {
    type: 'string',
    isRequired: true,
},
        acceptedFundRaisingAgreement: {
    type: 'number',
    isRequired: true,
},
        acceptedMarketingAgreement: {
    type: 'number',
    isRequired: true,
},
    },
} as const;