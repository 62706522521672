// ----------------------------------------------------------------------

const Autocomplete = () => ({
  MuiSpeedDialAction: {
    styleOverrides: {
      staticTooltipLabel: {
        width: "max-content",
      },
    },
  },
});

export default Autocomplete;
