import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { Stack } from "@mui/material";
import { GridFooter, GridToolbarExport } from "@mui/x-data-grid";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

interface IFooterProperties {
  canExportData?: boolean;
  isExportingData?: boolean;
  exportFn?: LoadingButtonProps["onClick"];
  disableExportButton?: boolean;
}

export const Footer: React.FC<IFooterProperties> = ({
  canExportData = false,
  disableExportButton,
  isExportingData,
  exportFn,
}) => (
  <Stack alignItems="center" direction="row" justifyContent="space-between">
    {canExportData && <GridToolbarExport />}

    <GridFooter
      style={{
        marginLeft: "auto",
      }}
    />
  </Stack>
);
