import React from "react";
// material
import { useMediaQuery } from "@mui/material";

// ----------------------------------------------------------------------

interface MHiddenProperties {
  width:
    | "xsDown"
    | "smDown"
    | "mdDown"
    | "lgDown"
    | "xlDown"
    | "xsUp"
    | "smUp"
    | "mdUp"
    | "lgUp"
    | "xlUp";
}

// @ts-ignore
const MHidden: React.FC<MHiddenProperties> = ({ width, children }) => {
  const breakpoint = width.slice(0, 2);

  const hiddenUp = useMediaQuery((theme) =>
    // @ts-ignore
    theme.breakpoints.up(breakpoint)
  );
  const hiddenDown = useMediaQuery((theme) =>
    // @ts-ignore
    theme.breakpoints.down(breakpoint)
  );

  if (width.includes("Down")) {
    return hiddenDown ? null : children;
  }

  if (width.includes("Up")) {
    return hiddenUp ? null : children;
  }

  return null;
};

export default MHidden;
