import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Tooltip,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { selectCartLength } from "../../features/cart/cartSlice";
import { selectUser } from "../../features/user/userSlice";

// components
import { MHidden } from "../../components/@material_extend";
//
// import Searchbar from './Searchbar';
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

const DashboardNavbar: React.FC<{
  onOpenSidebar: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ onOpenSidebar }) => {
  const currentUser = useAppSelector(selectUser);
  const numberOfProductsInCart = useAppSelector(selectCartLength);

  return (
    <RootStyle>
      <ToolbarStyle disableGutters>
        <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <MenuIcon />
          </IconButton>
        </MHidden>

        {/* <Searchbar /> */}
        <Box id="navbar-page-title" />

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}

          {/* <NotificationsPopover /> */}

          {currentUser?.type.description.toLowerCase() === "paziente" && (
            <Badge badgeContent={numberOfProductsInCart} color="primary">
              <Link to="/cart">
                <Tooltip
                  enterDelay={275}
                  title={
                    numberOfProductsInCart > 0
                      ? `Ci sono ${numberOfProductsInCart} elementi nel carrello`
                      : "Il tuo carrello è vuoto"
                  }
                >
                  <IconButton color="primary" aria-label="shopping cart">
                    <ShoppingCartIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            </Badge>
          )}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};

export default DashboardNavbar;
