import { Helmet } from "react-helmet-async";
import React, { forwardRef } from "react";
// material
import { Box, BoxProps } from "@mui/material";
import PageTitle from "./PageTitle";

// ----------------------------------------------------------------------

interface IPageProperties extends BoxProps {
  showBackButton?: boolean;
  hidePageTitle?: boolean;
  backButtonHandler?: () => void;
}

const Page = forwardRef<HTMLDivElement, IPageProperties>(
  (
    {
      children,
      title = "",
      showBackButton = false,
      hidePageTitle = false,
      backButtonHandler,
      ...other
    },
    reference
  ) => (
    <Box
      ref={reference}
      sx={{
        height: "100%",
      }}
      {...other}
    >
      <Helmet>
        <title>{title} | Assistenza Domiciliare</title>
      </Helmet>
      <PageTitle
        showBackButton={showBackButton}
        backButtonHandler={backButtonHandler}
        hidePageTitle={hidePageTitle}
      >
        {title}
      </PageTitle>
      {children}
    </Box>
  )
);

export default Page;
