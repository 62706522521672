import React from "react";
import { useAsync } from "react-use";
// material
import { Box, Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SampleForm from "../../components/forms/SampleForm";
// components
import Page from "../../components/Page";
import PageLoader from "../../components/PageLoader";
import GenericError from "../../components/GenericError";
import { ServicesService } from "../../api/assistenza-domiciliare";

import type { GetServiceResponse } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

const SampleDetails: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loading: loadingService,
    error,
    value: service,
    // eslint-disable-next-line consistent-return
  } = useAsync<() => Promise<GetServiceResponse | undefined>>(async () => {
    if (id) {
      return ServicesService.getService({
        id: +id!,
      });
    }
  }, [id]);

  if (loadingService) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <Page
      title={["Dettaglio Prelievo", service?.data.name]
        .filter((value) => value)
        .join(" ")}
      showBackButton
      backButtonHandler={() => navigate("/samples")}
    >
      <Container>
        <Box>
          <SampleForm initialValues={service?.data} />
        </Box>
      </Container>
    </Page>
  );
};

export default SampleDetails;
