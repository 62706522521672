import React from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Stack, Link, Container, Typography, Grid } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material_extend";
import RecoverPasswordForm from "../../components/forms/RecoverPasswordForm";
import ResetPasswordForm from "../../components/forms/ResetPasswordForm";
import RecoverPasswordImage from "../../assets/recover-password.svg";
import { useQueryParameters } from "../../hooks/useQueryParameters";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const RecoverPassword: React.FC = () => {
  const queryParameters = useQueryParameters();
  const token = queryParameters.get("token");

  return (
    <RootStyle title="Recupera Password" hidePageTitle>
      <AuthLayout>
        Non hai un account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/auth/register"
        >
          Inizia da qui
        </Link>
      </AuthLayout>

      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        justifyContent="space-between"
      >
        <MHidden width="mdDown">
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundImage: `url(${RecoverPasswordImage})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </MHidden>

        <Grid item xs={12} md={6} component={Container}>
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Reimposta la password di accesso ad Assistenza Domiciliare
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Inserisci i dettagli richiesti
              </Typography>
            </Stack>

            {token ? (
              <ResetPasswordForm token={token} />
            ) : (
              <RecoverPasswordForm />
            )}

            <MHidden width="smUp">
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Non hai un account?&nbsp;
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Inizia da qui
                </Link>
              </Typography>
            </MHidden>
          </ContentStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default RecoverPassword;
