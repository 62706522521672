import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../api/assistenza-domiciliare";
import { RootState } from "../../app/store";

export interface UserState {
  user?: User;
}

const initialState: UserState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },

    clearUser: (state) => {
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("password");
      // eslint-disable-next-line no-param-reassign
      state.user = undefined;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user.user;

export const selectUserType = (state: RootState) =>
  state.user.user?.type.description.toLowerCase();

export default userSlice.reducer;
