/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUserResponse } from '../models/GetUserResponse';
import type { GetUsersResponse } from '../models/GetUsersResponse';
import type { GetUserTypeResponse } from '../models/GetUserTypeResponse';
import type { PatchUserBody } from '../models/PatchUserBody';
import type { PatchUserResponse } from '../models/PatchUserResponse';
import type { PostUserBody } from '../models/PostUserBody';
import type { PostUserResponse } from '../models/PostUserResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @returns GetUsersResponse Default Response
     * @throws ApiError
     */
    public static getUsers({
typeId,
firstName,
lastName,
skip,
take,
sort,
orderby,
}: {
typeId?: number,
firstName?: string,
lastName?: string,
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetUsersResponse> {
        return __request({
            method: 'GET',
            path: `/users/`,
            query: {
                'typeId': typeId,
                'firstName': firstName,
                'lastName': lastName,
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns PostUserResponse Default Response
     * @throws ApiError
     */
    public static createUser({
requestBody,
}: {
requestBody?: PostUserBody,
}): CancelablePromise<PostUserResponse> {
        return __request({
            method: 'POST',
            path: `/users/`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GetUserResponse Default Response
     * @throws ApiError
     */
    public static getUser({
id,
}: {
id: number,
}): CancelablePromise<GetUserResponse> {
        return __request({
            method: 'GET',
            path: `/users/${id}`,
        });
    }

    /**
     * @returns PatchUserResponse Default Response
     * @throws ApiError
     */
    public static patchUser({
id,
requestBody,
}: {
id: number,
requestBody?: PatchUserBody,
}): CancelablePromise<PatchUserResponse> {
        return __request({
            method: 'PATCH',
            path: `/users/${id}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Response when the entity to update is not found`,
            },
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static deleteUser({
id,
}: {
id: number,
}): CancelablePromise<void> {
        return __request({
            method: 'DELETE',
            path: `/users/${id}`,
            errors: {
                404: `Response when the entity to update is not found`,
            },
        });
    }

    /**
     * @returns GetUserTypeResponse Default Response
     * @throws ApiError
     */
    public static getUserType(): CancelablePromise<GetUserTypeResponse> {
        return __request({
            method: 'GET',
            path: `/users/types`,
        });
    }

}