import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { boolean } from "boolean";

import type { IGoogleReCaptchaConsumerProps } from "react-google-recaptcha-v3";

const RecaptchaWrapper: React.FC<IGoogleReCaptchaConsumerProps> = ({
  children,
  ...properties
}) => {
  const isGoogleRecaptchaEnabled = boolean(
    process.env.REACT_APP_ENABLE_GOOGLE_RECAPTCHA
  );

  if (isGoogleRecaptchaEnabled) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
        {...properties}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  }

  return <>{children}</>;
};

export default RecaptchaWrapper;
