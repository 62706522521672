/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetOrderResponse } from '../models/GetOrderResponse';
import type { GetOrdersResponse } from '../models/GetOrdersResponse';
import type { GetOrderStatusesResponse } from '../models/GetOrderStatusesResponse';
import type { GetOrderStatusResponse } from '../models/GetOrderStatusResponse';
import type { PatchOrderBody } from '../models/PatchOrderBody';
import type { PatchOrderResponse } from '../models/PatchOrderResponse';
import type { PostCalculateTotalResponse } from '../models/PostCalculateTotalResponse';
import type { PostOrderBody } from '../models/PostOrderBody';
import type { PostOrderResponse } from '../models/PostOrderResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class OrdersService {

    /**
     * @returns GetOrdersResponse Default Response
     * @throws ApiError
     */
    public static getOrders({
id,
statusId,
download,
startCreatedAt,
endCreatedAt,
skip,
take,
sort,
orderby,
}: {
id?: string,
statusId?: number,
download?: 'csv',
startCreatedAt?: string,
endCreatedAt?: string,
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetOrdersResponse> {
        return __request({
            method: 'GET',
            path: `/orders/`,
            query: {
                'id': id,
                'statusId': statusId,
                'download': download,
                'startCreatedAt': startCreatedAt,
                'endCreatedAt': endCreatedAt,
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns PostOrderResponse Default Response
     * @throws ApiError
     */
    public static createOrder({
requestBody,
}: {
requestBody?: PostOrderBody,
}): CancelablePromise<PostOrderResponse> {
        return __request({
            method: 'POST',
            path: `/orders/`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Default Response`,
            },
        });
    }

    /**
     * @returns GetOrderResponse Default Response
     * @throws ApiError
     */
    public static getOrder({
id,
}: {
id: number,
}): CancelablePromise<GetOrderResponse> {
        return __request({
            method: 'GET',
            path: `/orders/${id}`,
        });
    }

    /**
     * @returns PatchOrderResponse Default Response
     * @throws ApiError
     */
    public static patchOrder({
id,
requestBody,
}: {
id: number,
requestBody?: PatchOrderBody,
}): CancelablePromise<PatchOrderResponse> {
        return __request({
            method: 'PATCH',
            path: `/orders/${id}`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PostCalculateTotalResponse Default Response
     * @throws ApiError
     */
    public static calculateTotal({
requestBody,
}: {
requestBody?: PostOrderBody,
}): CancelablePromise<PostCalculateTotalResponse> {
        return __request({
            method: 'POST',
            path: `/orders/calculateTotal`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Default Response`,
            },
        });
    }

    /**
     * @returns GetOrderStatusesResponse Default Response
     * @throws ApiError
     */
    public static getOrdersStatuses({
skip,
take,
sort,
orderby,
}: {
skip?: number,
take?: number,
sort?: 'ASC' | 'DESC',
orderby?: string,
}): CancelablePromise<GetOrderStatusesResponse> {
        return __request({
            method: 'GET',
            path: `/ordersStatus/`,
            query: {
                'skip': skip,
                'take': take,
                'sort': sort,
                'orderby': orderby,
            },
        });
    }

    /**
     * @returns GetOrderStatusResponse Default Response
     * @throws ApiError
     */
    public static getOrderStatus({
id,
}: {
id: number,
}): CancelablePromise<GetOrderStatusResponse> {
        return __request({
            method: 'GET',
            path: `/ordersStatus/${id}`,
        });
    }

}