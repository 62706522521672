/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostAskForAccountConfirmationBody } from '../models/PostAskForAccountConfirmationBody';
import type { PostAskForResetPasswordBody } from '../models/PostAskForResetPasswordBody';
import type { PostConfirmAccountBody } from '../models/PostConfirmAccountBody';
import type { PostConfirmAccountResponse } from '../models/PostConfirmAccountResponse';
import type { PostLoginBody } from '../models/PostLoginBody';
import type { PostLoginResponse } from '../models/PostLoginResponse';
import type { PostRegisterBody } from '../models/PostRegisterBody';
import type { PostRegisterResponse } from '../models/PostRegisterResponse';
import type { PostResetPasswordBody } from '../models/PostResetPasswordBody';
import type { PostResetPasswordResponse } from '../models/PostResetPasswordResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * @returns PostLoginResponse Successful login
     * @throws ApiError
     */
    public static login({
requestBody,
}: {
requestBody?: PostLoginBody,
}): CancelablePromise<PostLoginResponse> {
        return __request({
            method: 'POST',
            path: `/auth/login`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PostRegisterResponse Successful register
     * @throws ApiError
     */
    public static register({
requestBody,
}: {
requestBody?: PostRegisterBody,
}): CancelablePromise<PostRegisterResponse> {
        return __request({
            method: 'POST',
            path: `/auth/register`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static askResetPassword({
requestBody,
}: {
requestBody?: PostAskForResetPasswordBody,
}): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/auth/ask-reset-password`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `User not found`,
            },
        });
    }

    /**
     * @returns void 
     * @throws ApiError
     */
    public static askAccountActivation({
requestBody,
}: {
requestBody?: PostAskForAccountConfirmationBody,
}): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/auth/ask-account-activation`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `User not found`,
            },
        });
    }

    /**
     * @returns PostResetPasswordResponse Successful reset password
     * @throws ApiError
     */
    public static resetPassword({
requestBody,
}: {
requestBody?: PostResetPasswordBody,
}): CancelablePromise<PostResetPasswordResponse> {
        return __request({
            method: 'POST',
            path: `/auth/reset-password`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid token`,
                403: `Token expired or already used`,
            },
        });
    }

    /**
     * @returns PostConfirmAccountResponse Successful account confirmation
     * @throws ApiError
     */
    public static confirmAccount({
requestBody,
}: {
requestBody?: PostConfirmAccountBody,
}): CancelablePromise<PostConfirmAccountResponse> {
        return __request({
            method: 'POST',
            path: `/auth/confirm-account`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid token`,
                403: `Token expired or already used`,
            },
        });
    }

}