/* eslint-disable max-len */
import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Container, Box } from "@mui/material";
// components
import Page from "../../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
});

// ----------------------------------------------------------------------

const PrivacyPolicy: React.FC = () => {
  const isLoggedIn =
    sessionStorage.getItem("email") && sessionStorage.getItem("password");

  return (
    <RootStyle
      title="Privacy Policy"
      showBackButton
      hidePageTitle={!isLoggedIn}
      pt={isLoggedIn ? 0 : 20}
    >
      <Container>
        <Box
          dangerouslySetInnerHTML={{
            __html: `<p><strong>INFORMATIVA AI SENSI DEGLI ARTT. 13 E 14 DEL REGOLAMENTO (UE) 679/2016</strong></p>
<p><strong>RELATIVA AL TRATTAMENTO DEI DATI PERSONALI PER LA PIATTAFORMA WEB &ldquo;ASSISTENZA DOMICILIARE&rdquo;</strong></p>
<p>&nbsp;</p>
<p><strong><em>La presente informativa &egrave; integrativa dell&rsquo;informativa ex art. 13 e 14 del GDPR resa per il sito web assistenzadomiciliare.policlinicocampusbiomedico.it raggiungibile al seguente link e </em></strong><a href="https://assistenzadomiciliare.policlinicocampusbiomedico.it"><strong><em>https://assistenzadomiciliare.policlinicocampusbiomedico.it</em></strong></a><strong><em>,</em></strong><strong><em> alla quale si rinvia per la consultazione di tutte le informazioni di seguito non espressamente riportate.</em></strong></p>
<p>&nbsp;</p>
<p>Gentile Signora/e,</p>
<p>con la presente informativa la <strong>Fondazione Policlinico Universitario Campus Bio-Medico</strong>, (di seguito denominata &ldquo;<strong>Fondazione</strong>&rdquo;), con sede in Roma, Via &Aacute;lvaro del Portillo, 200<strong>,<span class="Apple-converted-space">&nbsp; </span></strong>raggiungibile all&rsquo;indirizzo<strong> </strong>e-mail: <a href="mailto:presidenza@pec.policlinicocampus.it"><strong>presidenza@pec.policlinicocampus.it</strong></a><strong> </strong>; in qualit&agrave; di Titolare del trattamento dei Suoi Dati Personali (di seguito anche &ldquo;<strong>Titolare</strong>&rdquo;), intende informarLa, ai sensi dell&rsquo;art. 13 e 14 del Regolamento (UE) 2016/679 (di seguito &ldquo;<strong>Regolamento</strong>&rdquo;), sulla tipologia di dati raccolti e sulle modalit&agrave; di trattamento degli stessi nell&rsquo;ambito dell&rsquo;utilizzo della piattaforma &ldquo;Assistenza Domiciliare&rdquo;&nbsp; (di seguito &ldquo;<strong>Piattaforma</strong>&rdquo;), integrata all&rsquo;interno del sito web policlinicocampusbiomedico.it .</p>
<p><span class="Apple-converted-space">&nbsp;</span></p>
<p>Il Responsabile della protezione dei Dati Personali&nbsp;(di seguito il<strong>&nbsp;&ldquo;Data Protection Officer&rdquo;</strong>&nbsp;o&nbsp;<strong>&ldquo;DPO&rdquo;</strong>) &egrave; contattabile ai seguenti indirizzi:</p>
<ul>
<li>e-mail, all&rsquo;indirizzo:&nbsp;<a href="mailto:dpo@policlinicocampus.it"><strong>dpo@policlinicocampus.it</strong></a><strong> </strong>;</li>
<li>posta ordinaria, all&rsquo;indirizzo <strong>Fondazione Policlinico Universitario Campus Bio-Medico</strong>, con sede in Roma, Via &Aacute;lvaro del Portillo, 200,&nbsp;<strong>c.a. del&nbsp;Data Protection Officer.</strong></li>
</ul>
<p>&nbsp;</p>
<ol>
<li><strong>Tipi di dati oggetto del trattamento</strong></li>
</ol>
<p>Il Titolare tratter&agrave; i Suoi Dati Personali raccolti mediante l&rsquo;utilizzo della Piattaforma e dei servizi in essa presenti.<span class="Apple-converted-space">&nbsp;</span></p>
<p>In particolare, saranno trattate le informazioni appartenenti alla categoria dei dati comuni quali i Suoi dati anagrafici (nome, cognome, data e luogo di nascita, sesso, nazionalit&agrave; e CF), di contatto (indirizzo e-mail, indirizzo di residenza), le credenziali di accesso alla Piattaforma, nonch&eacute; le informazioni appartenenti alla categoria dei dati particolari, tra cui, nello specifico, dati relativi alla Sua salute, quali il numero di prescrizione medica, la vista e/o l&rsquo;esame e/o la prestazione sanitaria e assistenziale prenotato, l&rsquo;importo pagato.</p>
<p>&nbsp;</p>
<p>La Fondazione tratter&agrave; anche l&rsquo;informazione dell&rsquo;avvenuto pagamento tramite PayPal; al riguardo, si precisa che PayPal, quale autonomo titolare del trattamento, provvede a comunicare alla Fondazione esclusivamente la circostanza dell&rsquo;avvenuto pagamento, con esclusione, dunque, delle altre informazioni relative al Suo pagamento.</p>
<p>&nbsp;</p>
<ol start="2">
<li><strong>Finalit&agrave;, base giuridica e facoltativit&agrave; del trattamento</strong></li>
</ol>
<p>I Suoi Dati Personali saranno trattati esclusivamente per le seguenti finalit&agrave;:<span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<ol>
<li>consentire l&rsquo;utilizzo della piattaforma, la&nbsp;registrazione e l&rsquo;accesso nell&rsquo;area riservata nonch&eacute; l&rsquo;erogazione di tutti i servizi ivi messi a disposizione dal Titolare quali, il servizio di prenotazione della vista e/o l&rsquo;esame e/o la prestazione sanitaria e assistenziale richeista, comprensivo delle comunicazioni di servizio di avvenuto pagamento, compresa la gestione della sicurezza del Sito, nonch&eacute; delle relazioni contrattuali e amministrativo contabili e di assistenza a tale servizio connesse (&ldquo;<strong><em>finalit&agrave; di esecuzione servizio on line di prenotazione e pagamento</em>&rdquo;</strong>);</li>
</ol>
<p>&nbsp;</p>
<p>Una volta conferiti, i Suoi dati potranno essere trattati dal Titolare anche per le seguenti finalit&agrave;:</p>
<p>&nbsp;</p>
<ol start="2">
<li>adempiere ad eventuali obblighi previsti dalle leggi vigenti, da regolamenti o dalla normativa comunitaria (in particolare in materia di igiene e sanit&agrave; ed in relazione ad adempimenti fiscali), o soddisfare richieste provenienti dalle autorit&agrave; (in particolare verifiche di carattere amministrativo, ispezioni di organi preposti alla vigilanza in materia sanitaria; investigazioni della polizia giudiziaria ecc); (&ldquo;<strong><em>finalit&agrave; di compliance</em></strong>&rdquo;);</li>
<li>nell&rsquo;eventualit&agrave; in cui sia necessario accertare, esercitare o difendere un diritto in sede giudiziaria (&ldquo;<strong><em>finalit&agrave; difensive</em></strong>&rdquo;);</li>
<li>escluso ogni dato particolare, con il Suo previo consenso<strong>, </strong>per inviare comunicazioni promozionali e di marketing, compreso l&rsquo;invio di newsletter e ricerche di mercato, relative a iniziative di prevenzione e promozione della salute e di nuovi servizi clinici e/o attivit&agrave; formativa e didattica professionalizzante<strong> </strong>(ad es. corsi <em>post lauream; </em>tirocini e attivit&agrave; formative nell&rsquo;ambito delle scuole di specializzazione), attraverso strumenti automatizzati (sms, e-mail; notifiche push; mms; telefono senza operatore) e non (posta ordinaria, telefono con operatore) (&ldquo;<strong><em>finalit&agrave; di marketing diretto</em></strong>&rdquo;);</li>
<li>comunicazione a terzi quali la Fondazione e l&rsquo;Universit&agrave; Campus Bio-Medico di Roma &ndash; in qualit&agrave; di contitolari del trattamento &ndash; per consentire loro di inviarLe materiale informativo e promozionale sulle attivit&agrave; nei settori della formazione universitaria, della ricerca bio-medica e ingegneristica, dell&rsquo;assistenza ospedaliera e ambulatoriale condotte dai Contitolari stessi, comprese l&rsquo;attivit&agrave; di <em>costumer satisfaction</em> e l&rsquo;invio di newsletter, al fine di promuovere campagne di raccolta fondi a loro favore, attraverso strumenti automatizzati (sms, mms, e-mail, sistemi di chiamata automatizzati senza operatore, utilizzo dei social network, whatsapp) e non (posta ordinaria, telefono con operatore) (&ldquo;<strong><em>finalit&agrave; di fundraising</em></strong>&rdquo;);</li>
</ol>
<p>&nbsp;</p>
<p>La base di liceit&agrave; del trattamento di Dati Personali per la Finalit&agrave; di esecuzione servizio di prenotazione si basa sugli artt. 6.2.b), per i dati comuni e sul Suo libero e specifico consenso ex art. 9.2.a) del GDPR per i dati particolari.<span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<p>Il conferimento dei dati personali per queste finalit&agrave; &egrave; facoltativo, ma l'eventuale mancato conferimento comporterebbe l'impossibilit&agrave; di prenotare e pagare la vista e/o l&rsquo;esame e/o la prestazione sanitaria e assistenziale richiesta.<span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<p>La base legale del trattamento di Dati Personali per le finalit&agrave; di <em>compliance</em> di cui alla lett. b) &egrave; l&rsquo;art. 6, par. 1, lett. c) del Regolamento.<span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<p>La base legale del trattamento per le finalit&agrave; difensive di cui alla lettera c) risiede negli artt. 6, par. 1, lett. f) e 9, per. 2, lett. f) del Regolamento. Una volta conferiti i Dati Personali, il trattamento&nbsp;potrebbe diventare necessario per garantire il legittimo interesse del Titolare per accertare, esercitare o difendere un proprio diritto in sede giudiziale e stragiudiziale.</p>
<p>&nbsp;</p>
<p>Per le finalit&agrave; di cui alla lettera d) ed e) la base di legittimit&agrave; dei trattamenti &egrave; il Suo libero e specifico consenso ex art. 6.1.a) del GDPR. In caso di mancato consenso non sar&agrave; possibile per la Fondazione &ndash; nel caso di cui alla lett. d) ovvero per la Fondazione e l&rsquo;Universit&agrave; Campus Bio-Medico di Roma in qualit&agrave; di Contitolari - effettuare le comunicazioni promozionali indicate ma Le sar&agrave; sempre possibile prenotare e pagare la prestazione sanitaria richiesta.</p>
<p>&nbsp;</p>
<p>Lei potr&agrave; revocare i consensi prestati <em>ex</em> art. 7 del GDPR in qualunque momento senza pregiudicare la liceit&agrave; del trattamento basata sui consensi prestati prima della revoca.</p>
<p>&nbsp;</p>
<ol start="3">
<li><strong>Modalit&agrave; di trattamento dei dati</strong></li>
</ol>
<p>I Suoi Dati saranno trattati con modalit&agrave; manuali nonch&eacute; con l&rsquo;ausilio di strumenti elettronici e telematici, nel rispetto del Regolamento e dei Provvedimenti del Garante per la protezione dei Dati Personali applicabili, secondo logiche e procedure strettamente correlate alle finalit&agrave; sopra indicate. <span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<ol start="4">
<li><strong>Destinatari e trasferimento dei Dati Personali<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ol>
<p>I Suoi Dati Personali potranno essere condivisi, per le finalit&agrave; di cui sopra, con i soggetti indicati di seguito (&ldquo;<strong>Destinatari</strong>&rdquo;):</p>
<ul>
<li>soggetti che agiscono tipicamente in qualit&agrave; di responsabili del trattamento ai sensi dell&rsquo;art. 28 del Regolamento (tra cui il fornitore della piattaforma Cyberneid S.r.l.,);</li>
<li>soggetti che agiscono in qualit&agrave; di autonomi titolari del trattamento, incaricati dell&rsquo;esecuzione di specifiche attivit&agrave; di cui alla presente informativa e con i quali il Titolare abbia stipulato accordi commerciali (in particolare Paypal per l&rsquo;esecuzione dei servizi di pagamento).</li>
<li>soggetti, enti od autorit&agrave;, autonomi titolari, a cui sia obbligatorio comunicare i Suoi Dati Personali in forza di disposizioni di legge o di ordini delle autorit&agrave; (es. ASL, l&rsquo;Agenzia delle Entrate, Autorit&agrave; giudiziaria ecc.);</li>
<li>alla Fondazione e all&rsquo;Universit&agrave; Campus Bio-Medico di Roma, in qualiy&agrave; di Contitolari del trattamento, qualora Lei abbia prestato il consenso per la finalit&agrave; di comunicazione per finalit&agrave; di raccolta fondi dei contitolari, di cui alla lettera e);</li>
<li>persone autorizzate dal Titolare al trattamento di Dati Personali ai sensi degli artt. 29 del Regolamento e 2-quaterdecies del D. Lgs. 196/2003 (cd. &ldquo;Codice Privacy&rdquo;) che si siano impegnate alla riservatezza o abbiano un adeguato obbligo legale di riservatezza (es. dipendenti, collaboratori, ecc.);</li>
</ul>
<p>&nbsp;</p>
<ol start="5">
<li><strong>Trasferimento dei dati verso un paese terzo o un&rsquo;organizzazione internazionale<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ol>
<p>Il Titolare assicura che in caso di eventuale trasferimento dei Suoi Dati Personali verso Paesi Terzi extra Spazio Economico Europeo (SEE) o organizzazioni internazionali, il trattamento avverr&agrave; nel rispetto della normativa ovvero secondo una delle modalit&agrave; consentite dalla legge ai sensi degli artt. 44-49 del GDPR, quali ad esempio il consenso dell&rsquo;interessato, l&rsquo;adozione di Clausole Standard approvate dalla Commissione Europea, la selezione di soggetti aderenti a programmi internazionali per la libera circolazione dei dati,&nbsp;nel rispetto di quanto disposto dalle Raccomandazioni 01/2020 e 02/2020 adottate il 10 novembre 2020 &nbsp;dello&nbsp;European&nbsp;Data&nbsp;Protection&nbsp;Board.</p>
<p><span class="Apple-converted-space">&nbsp;</span></p>
<ol start="6">
<li><strong>Conservazione dei Dati Personali<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ol>
<p>I Dati Personali trattati per le finalit&agrave; di cui alla sezione 1(a-b-c) saranno conservati per il tempo strettamente necessario a raggiungere quelle stesse finalit&agrave;&nbsp;nel rispetto dei principi di minimizzazione e limitazione della conservazione ex artt. 5.1.c) ed e) del GDPR.<span class="Apple-converted-space">&nbsp; </span>Maggiori informazioni sono disponibili presso il Titolare o al DPO scrivendo agli recapiti suindicati.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>I Suoi diritti<span class="Apple-converted-space">&nbsp;</span></strong></li>
</ol>
<p>Lei ha il diritto di esercitare i diritti di cui agli artt. 15-22 del Regolamento e di revocare in ogni momento i consensi prestati senza pregiudizio alla liceit&agrave; del trattamento effettuato prima della revoca. In particolare, Lei potr&agrave; accedere ai suoi dati ai sensi dell&rsquo;art. 15 del Regolamento, chiederne la rettifica ex art. 15 del Regolamento, la cancellazione ex art. 17 del Regolamento, la limitazione del trattamento nei casi previsti dall'art. 18 del Regolamento e ottenerne la portabilit&agrave; nei casi previsti dall'art. 20 del Regolamento.</p>
<p>&nbsp;</p>
<p>Inoltre, Lei pu&ograve; formulare una richiesta di opposizione al trattamento dei Suoi Dati Personali ex art. 21 del Regolamento nella quale dare evidenza delle ragioni che giustifichino l&rsquo;opposizione: il Titolare si riserva di valutare la Sua istanza, che non verrebbe accettata in caso di esistenza di motivi legittimi cogenti per procedere al trattamento che prevalgano sui Suoi interessi, diritti e libert&agrave;.<span class="Apple-converted-space">&nbsp;</span></p>
<p>&nbsp;</p>
<p>La informiamo, inoltre, che ha diritto di opporsi in ogni momento, senza alcuna giustificazione al trattamento dei Suoi dati per finalit&agrave; di marketing diretto di cui alle lett. d) ed e) della presente informativa. Considerato che, con riguardo a tali finalit&agrave; di marketing, il Titolare raccoglie un unico consenso ai sensi del Provvedimento Generale del Garante per la Protezione dei Dati Personali "<em>Linee guida in materia di attivit&agrave; promozionale e contrasto allo spam</em>&rdquo; del 4 luglio 2013, &egrave; possibile esercitare il diritto di opposizione ex art. 21 del Regolamento o la revoca del consenso prestato ex art. 7 del Regolamento, anche in parte, ossia, opponendosi o revocando il consenso, ad esempio, al solo invio di comunicazioni effettuato tramite strumenti automatizzati.</p>
<p>&nbsp;</p>
<p>Le richieste relative all&rsquo;esercizio dei Suoi diritti vanno rivolte per iscritto al Titolare ovvero al DPO ai recapiti sopraindicati.</p>
<p>&nbsp;</p>
<p>La informiamo, infine, che Lei pu&ograve;, in ogni caso, proporre reclamo all'Autorit&agrave; di controllo competente ex art. 77 del Regolamento (Garante per la Protezione dei Dati Personali).</p>`,
          }}
        />
      </Container>
    </RootStyle>
  );
};

export default PrivacyPolicy;
