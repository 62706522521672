import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

const Backdrop = (theme: any) => {
  const variableLow = alpha(theme.palette.grey[900], 0.48);
  const variableHigh = alpha(theme.palette.grey[900], 1);

  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: [
            `rgb(22,28,36)`,
            `-moz-linear-gradient(75deg, ${variableLow} 0%, ${variableHigh} 100%)`,
            `-webkit-linear-gradient(75deg, ${variableLow} 0%, ${variableHigh} 100%)`,
            `linear-gradient(75deg, ${variableLow} 0%, ${variableHigh} 100%)`,
          ],
          "&.MuiBackdrop-invisible": {
            background: "transparent",
          },
        },
      },
    },
  };
};

export default Backdrop;
