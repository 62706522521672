import ListIcon from "@mui/icons-material/List";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import InfoIcon from "@mui/icons-material/Info";
import VaccinesIcon from "@mui/icons-material/Vaccines";

// ----------------------------------------------------------------------

const sidebarConfig = [
  {
    title: "utenti",
    path: "/users",
    icon: <PeopleAltIcon />,
  },
  {
    title: "anagrafica",
    path: "/me",
    icon: <PersonIcon />,
  },
  {
    title: "prestazioni",
    path: "/services",
    icon: <AutoStoriesIcon />,
  },
  {
    title: "prelievi",
    path: "/samples",
    icon: <VaccinesIcon />,
  },
  {
    title: "carrello",
    path: "/cart",
    icon: <ShoppingCartIcon />,
  },
  {
    title: "ordini",
    path: "/orders",
    icon: <ListIcon />,
  },
  {
    title: "richieste",
    path: "/requests",
    icon: <ListIcon />,
  },
  {
    title: "contatti",
    path: "/contacts",
    icon: <InfoIcon />,
  },
];

export default sidebarConfig;
