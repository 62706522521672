import React from "react";
import { useToggle } from "react-use";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Stack, Link, Container, Typography, Grid, Alert } from "@mui/material";
// layouts
import AuthLayout from "../../layouts/AuthLayout";
// components
import Page from "../../components/Page";
import { MHidden } from "../../components/@material_extend";
import { LoginForm } from "../../components/authentication/login";
import LoginImage from "../../assets/login.svg";
import RecaptchaWrapper from "../../components/RecaptchaWrapper";
import AdminPrivacyPolicyDialog from "../../components/AdminPrivacyPolicyDialog";
import DoctorPrivacyPolicyDialog from "../../components/DoctorPrivacyPolicyDialog";

// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Login: React.FC = () => {
  const { state } = useLocation();
  const [isAdminPrivacyPolicyVisible, toggleAdminPrivacyPolicyVisibility] =
    useToggle(false);

  const [isDoctorPrivacyPolicyVisible, toggleDoctorPrivacyPolicyVisibility] =
    useToggle(false);

  React.useEffect(() => {
    if (state) {
      const { accountActivated } = state;

      if (accountActivated) {
        if ([1, 2].includes(accountActivated.data.type.id)) {
          toggleAdminPrivacyPolicyVisibility();
        } else if ([3, 4].includes(accountActivated.data.type.id)) {
          toggleDoctorPrivacyPolicyVisibility();
        }
      }
    }
  }, [state]);

  return (
    <>
      <RecaptchaWrapper>
        <RootStyle title="Login" hidePageTitle>
          <AuthLayout>
            Non hai un account? &nbsp;
            <Link
              underline="none"
              variant="subtitle2"
              component={RouterLink}
              to="/auth/register"
            >
              Inizia da qui
            </Link>
          </AuthLayout>

          <Grid
            container
            component="main"
            sx={{ height: "100vh" }}
            justifyContent="space-between"
          >
            <MHidden width="mdDown">
              <Grid
                item
                xs={false}
                sm={4}
                md={5}
                sx={{
                  backgroundImage: `url(${LoginImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundColor: (t) =>
                    t.palette.mode === "light"
                      ? t.palette.grey[50]
                      : t.palette.grey[900],
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              />
            </MHidden>

            <Grid item xs={12} md={6} component={Container}>
              <ContentStyle>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant="h4" gutterBottom>
                    Esegui l'accesso ad Assistenza Domiciliare
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Inserisci i dettagli richiesti
                  </Typography>
                </Stack>
                {/* <AuthSocial /> */}

                <Stack spacing={4}>
                  {state?.hasRecoveredPassword && (
                    <Alert severity="success">
                      La tua password è stata resettata con successo!
                    </Alert>
                  )}

                  {state?.hasActivatedAccount && (
                    <Alert severity="success">
                      L'account è stato attivato correttamente, adesso puoi
                      eseguire il login
                    </Alert>
                  )}

                  <LoginForm />
                </Stack>

                <MHidden width="smUp">
                  <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                    Non hai un account?&nbsp;
                    <Link
                      variant="subtitle2"
                      component={RouterLink}
                      to="/auth/register"
                    >
                      Inizia da qui
                    </Link>
                  </Typography>
                </MHidden>
              </ContentStyle>
            </Grid>
          </Grid>
        </RootStyle>
      </RecaptchaWrapper>

      <AdminPrivacyPolicyDialog
        open={isAdminPrivacyPolicyVisible}
        onClose={toggleAdminPrivacyPolicyVisibility}
      />

      <DoctorPrivacyPolicyDialog
        open={isDoctorPrivacyPolicyVisible}
        onClose={toggleDoctorPrivacyPolicyVisibility}
      />
    </>
  );
};

export default Login;
