/* eslint-disable no-else-return */
/* eslint-disable sonarjs/prefer-single-boolean-return */
import AssignmentIcon from "@mui/icons-material/Assignment";
import { LoadingButton, PickersDay } from "@mui/lab";
import DateTimePicker from "@mui/lab/DateTimePicker";
import {
  Alert,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { DataGrid, GridColDef, itIT } from "@mui/x-data-grid";
import saveAs from "file-saver";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { useNavigate } from "react-router-dom";
import { useAsync } from "react-use";
import * as Yup from "yup";
// eslint-disable-next-line import/no-duplicates
import { getDate, isBefore, isSameDay, startOfMonth, subDays } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import MedicAutocomplete from "../MedicAutocomplete";

// material

import GenericError from "../GenericError";
import PageLoader from "../PageLoader";

import {
  OrdersService,
  RequestsService,
} from "../../api/assistenza-domiciliare";

import type {
  GetOrderStatusesResponse,
  GetRequestsAvailabilityResponse,
  Order,
  PatchOrderResponse,
} from "../../api/assistenza-domiciliare";
import { useAppSelector } from "../../app/hooks";
import { selectUser, selectUserType } from "../../features/user/userSlice";
import { b64toBlob } from "../../utils/b64toBlob";
import { cancelPaypalOrder } from "../../utils/cancelPaypalOrder";
import removeNullOrUndefined from "../../utils/removeNullOrUndefined";
import { Footer } from "../datagrid";
import { NoRowsOverlay } from "../datagrid/NoRowsOverlay";

// ----------------------------------------------------------------------

interface IOrderDetailsFormProperties {
  initialValues?: Order;
}

const handleDoctorHeaderName = (serviceType: string) => {
  switch (serviceType) {
    case "Infermieristico":
    case "Oss":
    case "Prelievo":
      return "Infermiere";

    case "Medica":
      return "Medico";

    default:
      return "Infermiere";
  }
};

const OrderDetailsForm: React.FC<IOrderDetailsFormProperties> = ({
  initialValues,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const userType = useAppSelector(selectUserType);

  const [cartAmount, setCartAmount] = React.useState<number>();

  const [pageSize, setPageSize] = React.useState<number>(10);

  const [isSuccessSnackbarShown, setSuccesSnackbarVisibility] =
    React.useState<boolean>(false);
  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);

  const [isUpdatingOrder, setIsUpdatingOrder] = React.useState<boolean>(false);

  const [orderStatuses, setOrderStatuses] =
    React.useState<GetOrderStatusesResponse>();
  const [isLoadingOrderStatuses, setIsLoadingOrderStatuses] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const [orderId, setOrderId] = React.useState<string>();
  const [createdOrder, setCreatedOrder] = React.useState<PatchOrderResponse>();
  const [, setErrorCreatingOrder] = React.useState<boolean>(false);

  const [isLoadingAvailability, setIsLoadingAvailability] =
    React.useState<boolean>(false);
  const [availability, setAvailability] =
    React.useState<GetRequestsAvailabilityResponse>();

  const schema = Yup.object().shape({
    id: Yup.number(),
    note: Yup.string().notRequired().default(""),
    status: Yup.object().shape({
      id: Yup.number().required(),
      status: Yup.string().required(),
    }),
    user: Yup.object(),
    transactionId: Yup.string().nullable(),
    requests: Yup.array(Yup.object()),
    totalPrice: Yup.number().nullable(),
  });

  const formik = useFormik({
    initialValues: initialValues
      ? { ...initialValues, status: JSON.stringify(initialValues.status) }
      : {
          note: "",
          status: "",
          requests: [],
          transactionId: null,
          totalPrice: 0,
        },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setIsUpdatingOrder(true);

        await OrdersService.patchOrder({
          id: initialValues?.id!,
          requestBody: {
            ...values,
            status: JSON.parse(values.status as unknown as string),
            user,
          },
        });

        setSuccesSnackbarVisibility(true);
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setIsUpdatingOrder(false);
        setSubmitting(false);
      }
    },
  });

  const columns: GridColDef[] = [
    {
      field: "Actions",
      headerName: "",
      align: "center",
      sortable: false,
      filterable: false,
      width: 150,
      renderCell: ({ row }) => {
        const serviceDescription =
          row.service.advancedDescription || row.service.description;
        const parsedDate = new Intl.DateTimeFormat("it", {
          // @ts-ignore
          dateStyle: "medium",
        })
          .format(new Date(row.bookedDate))
          .replace(" ", "-");

        return (
          <Tooltip enterDelay={750} title={"Scarica note medico"}>
            <span>
              <LoadingButton
                startIcon={<AssignmentIcon />}
                disabled={!row.medicalReportPdf}
                color="primary"
                onClick={async () => {
                  const blob = await b64toBlob(
                    row.medicalReportPdf,
                    "application/pdf"
                  );

                  saveAs(
                    blob,
                    `note-medico-${serviceDescription
                      .replace(" ", "-")
                      .toLowerCase()}-del-${parsedDate}.pdf`
                  );
                }}
              >
                Scarica note
              </LoadingButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Nome",
      type: "text",
      width: 320,
      sortable: false,
      renderCell: ({ row }) => (
        <Chip
          label={row.service.advancedDescription || row.service.description}
          color="info"
          variant="outlined"
          sx={{
            maxWidth: 310,
          }}
        />
      ),
    },
    {
      field: "type",
      headerName: "Tipologia",
      type: "text",
      width: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <Chip
          label={row.service.type.description}
          color="secondary"
          variant="outlined"
        />
      ),
    },
    {
      field: "dottore",
      headerName: handleDoctorHeaderName(
        initialValues?.requests[0].service.type?.description!
      ),
      width: 215,
      sortable: false,
      renderCell: ({ row }) => {
        // eslint-disable-next-line no-use-before-define
        const requestIndex = values.requests.findIndex(
          ({ id }) => id === row.id
        );
        const isMedic = !["Infermieristico", "Oss", "Prelievo"].includes(
          initialValues?.requests[0].service.type?.description!
        );

        return (
          <MedicAutocomplete
            label={handleDoctorHeaderName(
              initialValues?.requests[0].service.type?.description!
            )}
            onDoctorSelected={(doctor) => {
              // eslint-disable-next-line no-use-before-define
              setFieldValue(`requests[${requestIndex}].doctor`, doctor);
            }}
            value={initialValues?.requests[requestIndex].doctor}
            isMedic={isMedic}
            disabled={row.medicalReportPdf}
          />
        );
      },
      hide: userType !== "amministratore" && userType !== "amministrativo",
    },
    {
      field: "doctor_name",
      headerName: handleDoctorHeaderName(
        initialValues?.requests[0].service.type?.description!
      ),
      type: "text",
      width: 215,
      sortable: false,
      renderCell: ({ row }) =>
        row.doctor && (
          <Chip
            label={`${row.doctor?.firstName} ${row.doctor?.lastName}`}
            color="info"
            variant="outlined"
            sx={{
              maxWidth: 310,
            }}
          />
        ),
      hide: userType !== "paziente",
    },
    {
      field: "bookedDate",
      headerName: "Data di erogazione",
      width: 215,
      sortable: false,
      renderCell: ({ row }) => {
        // eslint-disable-next-line no-use-before-define
        const requestIndex = values.requests.findIndex(
          ({ id }) => id === row.id
        );
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [bookedDate, setBookedDate] = React.useState<Date>(
          row.bookedDate
        );

        return (
          <DateTimePicker
            disabled={
              // eslint-disable-next-line no-use-before-define
              !values.requests[requestIndex].doctor || row.medicalReportPdf
            }
            renderInput={(parameters) => (
              <TextField {...parameters} size="small" />
            )}
            value={bookedDate}
            onChange={(value) => {
              if (value) {
                setBookedDate(value);
              }

              // eslint-disable-next-line no-use-before-define
              setFieldValue(
                // eslint-disable-next-line no-use-before-define
                `requests[${values.requests.findIndex(
                  ({ id }) => id === row.id
                )}].bookedDate`,
                value
              );
            }}
            loading={isLoadingAvailability}
            renderLoading={() => <CircularProgress />}
            onOpen={() => {
              const date = startOfMonth(new Date());

              // eslint-disable-next-line no-use-before-define
              handleCalendarAvailability(
                date.getTime(),
                // eslint-disable-next-line no-use-before-define
                values.requests[requestIndex].doctor.id
              );
            }}
            onMonthChange={(date) => {
              // eslint-disable-next-line no-use-before-define
              handleCalendarAvailability(
                date.getTime(),
                // eslint-disable-next-line no-use-before-define
                values.requests[requestIndex].doctor.id
              );
            }}
            // eslint-disable-next-line sonarjs/no-identical-functions
            onYearChange={(date) => {
              // eslint-disable-next-line no-use-before-define
              handleCalendarAvailability(
                date.getTime(),
                // eslint-disable-next-line no-use-before-define
                values.requests[requestIndex].doctor.id
              );
            }}
            readOnly={
              userType !== "amministratore" && userType !== "amministrativo"
            }
            minDate={bookedDate ? new Date(bookedDate) : new Date()}
            renderDay={(day, _, pickerProperties) => {
              const doctorAvailability = availability?.data.find(({ date }) =>
                isSameDay(date, day.getTime())
              );

              return (
                <PickersDay
                  {...pickerProperties}
                  disabled={
                    doctorAvailability?.requests.length === 1000 ||
                    isBefore(day, subDays(new Date(), 1))
                  }
                >
                  <Stack direction="column" alignItems="center" spacing={2}>
                    {getDate(day)}

                    {!isBefore(day, subDays(new Date(), 1)) && (
                      <Box
                        bgcolor={
                          doctorAvailability?.requests &&
                          // eslint-disable-next-line no-nested-ternary
                          (doctorAvailability?.requests.length >= 2
                            ? "error.main"
                            : // eslint-disable-next-line unicorn/no-nested-ternary
                            doctorAvailability?.requests.length === 1
                            ? "warning.main"
                            : "primary.main")
                        }
                        width={6}
                        height={6}
                        borderRadius="50%"
                      />
                    )}
                  </Stack>
                </PickersDay>
              );
            }}
          />
        );
      },
    },
    {
      field: "service.price",
      headerName: "Prezzo",
      type: "number",
      width: 85,
      sortable: false,
      renderCell: ({ row }) => {
        const price = new Intl.NumberFormat("it", {
          style: "currency",
          currency: "EUR",
        }).format(row.servicePrice || row.service.price);

        return (
          <Chip
            label={price}
            variant="outlined"
            sx={{
              maxWidth: 85,
            }}
          />
        );
      },
    },
  ];

  const {
    errors,
    touched,
    handleSubmit,
    dirty,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
    resetForm,
  } = formik;

  const handleOrderError = async () => {
    setErrorSnackbarVisibility(true);
    if (orderId) {
      cancelPaypalOrder(orderId);
    }
  };

  const handleLoginErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSuccessfulUpdateClose = () => {
    setSuccesSnackbarVisibility((previous) => !previous);
  };

  const handleOrderCancellation: React.MouseEventHandler<HTMLButtonElement> =
    async () => {
      try {
        setIsUpdatingOrder(true);

        await OrdersService.patchOrder({
          id: initialValues?.id!,
          requestBody: {
            ...values,
            status: { id: 3, status: "" },
          },
        });

        navigate("/orders");
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setIsUpdatingOrder(false);
      }
    };

  const handleCalendarAvailability = async (date: number, doctorId: number) => {
    try {
      setIsLoadingAvailability(true);

      setAvailability(
        await RequestsService.getAvailability({
          date,
          doctorId,
        })
      );
    } finally {
      setIsLoadingAvailability(false);
    }
  };

  React.useEffect(() => {
    if (createdOrder) {
      resetForm({
        values: {
          ...createdOrder.data,
          status: JSON.stringify(createdOrder.data.status),
        },
      });

      navigate(`/orders/${createdOrder.data.id}`, {
        state: {
          payed: !!createdOrder.data.transactionId,
        },
      });
    }
  }, [createdOrder, navigate, resetForm]);

  React.useEffect(() => {
    if (initialValues?.totalPrice) {
      if (
        initialValues.totalPrice >= 77.47 &&
        values.requests.every((request) => request.service.type?.id !== 2)
      ) {
        setCartAmount(+values.totalPrice!.toFixed(3) + 2);
      } else {
        setCartAmount(+values.totalPrice!.toFixed(3));
      }
    }
  }, [initialValues]);

  useAsync(async () => {
    try {
      setError(false);
      setIsLoadingOrderStatuses(true);

      setOrderStatuses(await OrdersService.getOrdersStatuses({}));
    } catch {
      setError(true);
    } finally {
      setIsLoadingOrderStatuses(false);
    }
  }, []);

  if (isLoadingOrderStatuses) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericError />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {userType !== "paziente" && (
              <Stack spacing={3}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Nome"
                    disabled
                    value={initialValues?.user.firstName}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Cognome"
                    disabled
                    value={initialValues?.user.lastName}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Codice Fiscale"
                    disabled
                    value={initialValues?.user.codfis}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    autoComplete="address"
                    type="text"
                    label="Indirizzo"
                    disabled
                    value={initialValues?.user.address}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="CAP"
                    disabled
                    value={initialValues?.user.cap}
                  />
                </Stack>

                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Città"
                    disabled
                    value={initialValues?.user.city}
                  />

                  <TextField
                    fullWidth
                    type="text"
                    label="Provincia"
                    disabled
                    value={initialValues?.user.province}
                    inputProps={{
                      minLength: 2,
                      maxLength: 2,
                      style: {
                        textTransform: "uppercase",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            )}

            <TextField
              label="Informazioni aggiuntive"
              minRows={3}
              multiline
              {...getFieldProps("note")}
              error={Boolean(touched.note && errors.note)}
              helperText={touched.note && errors.note}
              // disabled={JSON.parse(values.status!)?.id === 5}
            />

            <FormControl fullWidth>
              <InputLabel id="order-status-label">Stato dell'ordine</InputLabel>
              <Select
                labelId="order-status-label"
                aria-describedby="order-status-label"
                label="Stato dell'ordine"
                {...getFieldProps("status")}
                error={Boolean(touched.status && errors.status)}
                disabled={
                  (userType !== "amministratore" &&
                    userType !== "amministrativo") ||
                  !values.status.includes("In attesa")
                }
              >
                {!values.status.includes("In attesa")
                  ? orderStatuses?.data?.map((order, index) => (
                      <MenuItem
                        value={JSON.stringify(order)}
                        key={`order-status-${order.id}-${index}`}
                        disabled={order.status === "Pagato"}
                      >
                        {order.status}
                      </MenuItem>
                    ))
                  : orderStatuses?.data
                      ?.filter((order) => order.status.includes("In attesa"))
                      .map((order, index) => (
                        <MenuItem
                          value={JSON.stringify(order)}
                          key={`order-status-${order.id}-${index}`}
                        >
                          {order.status}
                        </MenuItem>
                      ))}
              </Select>
              {touched.status && errors.status && (
                <FormHelperText id="order-status-label">
                  {errors.status}
                </FormHelperText>
              )}
            </FormControl>

            {initialValues?.requests && (
              <DataGrid
                rows={initialValues.requests}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 20, 30]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                density="comfortable"
                editMode="row"
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                components={{
                  Footer,
                  NoRowsOverlay,
                }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                style={{
                  minHeight: 500,
                }}
              />
            )}

            {userType !== "amministratore" &&
            userType !== "amministrativo" &&
            values?.transactionId === null &&
            values?.totalPrice !== null &&
            initialValues?.status.id === 1 ? (
              <Box
                sx={{
                  mx: "auto !important",
                  minWidth: "50vw",
                  maxWidth: "100vw",
                }}
              >
                <PayPalButton
                  createOrder={(data, actions) =>
                    actions.order.create({
                      purchase_units: [
                        {
                          amount: {
                            currency_code: "EUR",
                            value: cartAmount,
                          },
                          custom_id: initialValues?.id!,
                        },
                      ],
                      application_context: {
                        shipping_preference: "NO_SHIPPING", // default is "GET_FROM_FILE"
                      },
                    })
                  }
                  // amount={cartAmount}
                  // shippingPreference="NO_SHIPPING"
                  // eslint-disable-next-line camelcase
                  onSuccess={async ({ id, purchase_units }) => {
                    setOrderId(id);
                    try {
                      setErrorCreatingOrder(false);
                      setCreatedOrder(
                        await OrdersService.patchOrder({
                          id: initialValues?.id!,
                          requestBody: removeNullOrUndefined({
                            ...values,
                            status: JSON.parse(values.status),
                            transactionId:
                              // eslint-disable-next-line camelcase
                              purchase_units[0]?.payments?.captures[0]?.id,
                            user,
                          }),
                        })
                      );
                    } catch {
                      cancelPaypalOrder(id);
                      setErrorSnackbarVisibility(true);
                    }
                  }}
                  catchError={handleOrderError}
                  onError={handleOrderError}
                  options={{
                    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    currency: "EUR",
                    disableFunding: "mybank",
                  }}
                />
              </Box>
            ) : (
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={!dirty}
                  loading={isSubmitting || isUpdatingOrder}
                >
                  Aggiorna richiesta
                </LoadingButton>

                {(userType === "amministratore" ||
                  userType === "amministrativo") && (
                  <LoadingButton
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="warning"
                    loading={isSubmitting || isUpdatingOrder}
                    // disabled={JSON.parse(values.status)?.id === 5}
                    onClick={handleOrderCancellation}
                    disabled={
                      !initialValues?.isRefundable ||
                      [5, 7].includes(initialValues.status.id)
                    }
                  >
                    Annulla ordine
                  </LoadingButton>
                )}
              </Stack>
            )}
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore nell'aggiornamento dell'ordine
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSuccessfulUpdateClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSuccessfulUpdateClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Ordine aggiornato correttamente
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderDetailsForm;
