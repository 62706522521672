import * as Yup from "yup";
import React from "react";
import { useFormik, Form, FormikProvider } from "formik";
import DateTimePicker from "@mui/lab/DateTimePicker";

// material
import { Alert, Snackbar, Stack, TextField, Chip } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { GridColDef, DataGrid, itIT } from "@mui/x-data-grid";

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { useNavigate } from "react-router-dom";
import { RequestsService } from "../../api/assistenza-domiciliare";
import { NoRowsOverlay } from "../datagrid/NoRowsOverlay";
import { Footer } from "../datagrid";

import type { Request } from "../../api/assistenza-domiciliare";

// ----------------------------------------------------------------------

interface IRequestDetailsFormProperties {
  initialValues?: Request;
}

const RequestDetailsForm: React.FC<IRequestDetailsFormProperties> = ({
  initialValues,
}) => {
  const navigate = useNavigate();

  const [isSuccessSnackbarShown, setSuccesSnackbarVisibility] =
    React.useState<boolean>(false);
  const [isErrorSnackbarShown, setErrorSnackbarVisibility] =
    React.useState<boolean>(false);

  const [isUpdatingOrder, setIsUpdatingOrder] = React.useState<boolean>(false);

  const schema = Yup.object().shape({
    id: Yup.number(),
    medicalReport: Yup.string(),
  });

  const formik = useFormik({
    initialValues: initialValues || {
      medicalReport: "",
      status: undefined,
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setIsUpdatingOrder(true);
        await RequestsService.patchRequest({
          id: initialValues?.id!,
          requestBody: {
            ...values,
            // @ts-ignore
            status: {
              // @ts-ignore
              id: values?.status?.id!,
              // @ts-ignore
              status: values?.status?.description,
            },
          },
        });
        setSuccesSnackbarVisibility(true);
        navigate("/requests");
      } catch {
        setErrorSnackbarVisibility(true);
      } finally {
        setIsUpdatingOrder(false);
        setSubmitting(false);
      }
    },
  });

  const columns: GridColDef[] = [
    {
      field: "service",
      headerName: "Servizio da erogare",
      type: "text",
      flex: 1,
      sortable: false,
      renderCell: ({ value }) => (
        <Chip
          label={value.advancedDescription || value.description}
          color="info"
          variant="outlined"
        />
      ),
    },
    {
      field: "type",
      headerName: "Tipologia",
      type: "text",
      width: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <Chip
          label={row.service.type.description}
          color="secondary"
          variant="outlined"
        />
      ),
    },
    {
      field: "bookedDate",
      headerName: "Data di erogazione",
      width: 215,
      sortable: false,
      renderCell: ({ value }) => (
        <DateTimePicker
          renderInput={(parameters) => <TextField {...parameters} />}
          value={value}
          onChange={() => {}}
          readOnly
        />
      ),
    },
  ];

  const { errors, touched, handleSubmit, dirty, isSubmitting, getFieldProps } =
    formik;

  const handleLoginErrorClose = () => {
    setErrorSnackbarVisibility((previous) => !previous);
  };

  const handleSuccessfulUpdateClose = () => {
    setSuccesSnackbarVisibility((previous) => !previous);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {initialValues?.service && (
              <DataGrid
                rows={[
                  {
                    id: initialValues.service.id,
                    service: initialValues.service,
                    bookedDate: initialValues.bookedDate,
                    doctor: initialValues.doctor,
                  },
                ]}
                columns={columns}
                density="comfortable"
                autoHeight
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                hideFooterPagination
                hideFooter
                components={{
                  Footer,
                  NoRowsOverlay,
                }}
                localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                style={{
                  minHeight: "200px",
                }}
              />
            )}

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Nome"
                disabled
                value={initialValues?.order?.user.firstName}
              />

              <TextField
                fullWidth
                type="text"
                label="Cognome"
                disabled
                value={initialValues?.order?.user.lastName}
              />

              <TextField
                fullWidth
                type="text"
                label="Codice Fiscale"
                disabled
                value={initialValues?.order?.user.codfis}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="address"
                type="text"
                label="Indirizzo"
                disabled
                value={initialValues?.order?.user.address}
              />

              <TextField
                fullWidth
                type="text"
                label="CAP"
                disabled
                value={initialValues?.order?.user.cap}
              />
            </Stack>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                type="text"
                label="Città"
                disabled
                value={initialValues?.order?.user.city}
              />

              <TextField
                fullWidth
                type="text"
                label="Provincia"
                disabled
                value={initialValues?.order?.user.province}
                inputProps={{
                  minLength: 2,
                  maxLength: 2,
                  style: {
                    textTransform: "uppercase",
                  },
                }}
              />
            </Stack>

            <TextField
              label="Note"
              minRows={3}
              disabled={
                !!initialValues?.medicalReport ||
                initialValues?.order?.status.id === 1 ||
                initialValues?.order?.status.id === 9
              }
              multiline
              {...getFieldProps("medicalReport")}
              error={Boolean(touched.medicalReport && errors.medicalReport)}
              helperText={touched.medicalReport && errors.medicalReport}
            />

            {!initialValues?.medicalReport && (
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={
                    !dirty &&
                    (initialValues?.status?.id === 5 ||
                      initialValues?.order?.status.id === 1 ||
                      initialValues?.order?.status.id === 9)
                  }
                  loading={isSubmitting || isUpdatingOrder}
                  endIcon={<AssignmentTurnedInIcon />}
                >
                  Aggiungi note per questa prestazione
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        </Form>
      </FormikProvider>

      <Snackbar
        open={isErrorSnackbarShown}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore nell'aggiornamento dell'ordine
        </Alert>
      </Snackbar>

      <Snackbar
        open={isSuccessSnackbarShown}
        autoHideDuration={6000}
        onClose={handleSuccessfulUpdateClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleSuccessfulUpdateClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Hai correttamente aggiornato le informazioni
        </Alert>
      </Snackbar>
    </>
  );
};

export default RequestDetailsForm;
